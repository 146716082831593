import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";


import { IMG_URL } from "../../constants";
import { Button } from "react-bootstrap";
import axios from "axios";
import Dialog from "../ImageAnalytics/UI/Dialog";
import "../ImageAnalytics/Imgstyles.css"
// import Dialog from "../UI/Dialog";
interface Props {
    fileName: any;
    accuracy: any;
    isLoading: any;
    stepFn: any;
    notValidateFn: any;
    uploaded: Function;
    mode: boolean
}

const UploadImage: React.FC<Props> = ({ uploaded, mode, fileName, accuracy, isLoading, stepFn, notValidateFn }) => {
    const [fileInfo, setFileInfo] = useState<any>("");
    const [finalImage, setFinalImage] = useState<any>("");
    const [multipleFile, setMultipleFile] = useState<any>("");
    const [file, setFile] = useState<File | null>(null);
    const [excelDownload, setExcelDownload] = useState<boolean>(false);
    const [singleUploadedName, setSingleUploadedName] = useState<string>("");
    const [multipleUploadedName, setMultipleUploadedName] = useState<string>("");
    const [error, setError] = useState<{ code: number; title: string; message: string; count: number }>({
        code: 0,
        title: "",
        message: "",
        count: 0
    });

    useEffect(() => {
        async function getFinalResponse(fileInformation: any) {
            console.log(fileInformation);
            isLoading(true);
            const type = !mode ? 'imageConv' : 'multipleFiles'
            console.log(`The Type is - ${type}`);
            console.log(`The TypeFile is - ${multipleFile}`);

            const response = await fetch(`${IMG_URL}/api/${type}`, {
                method: "POST",
                body: JSON.stringify(fileInformation),
                headers: {
                    "Content-Type": "application/json",
                    "lntcs-Frontend": "4cbca3e312a241de9656b64260c2cd7a",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true"
                }
            });
            const result = await response.json();
            console.log(`The Result is - ${result}`);
            if (result.errorCode === 1) {
                console.log(result.errorMessage);
                setError({
                    code: 1,
                    title: result.errorTitle,
                    message: result.errorMessage,
                    count: 0
                });
                return;
            }

            if (type === 'multipleFiles') {
                setMultipleFile(result);
                isLoading(false);
                return
            }

            if (type === "imageConv") {
                setMultipleFile("");
                console.log(result);
                isLoading(false);
                getFinalImage(result["Final_Image"]);
                accuracy(result["Analysis"]);
                return
            }
        }
        if (fileInfo == "") {
            return;
        }
        getFinalResponse(fileInfo);
    }, [fileInfo]);

    function handleCloseTool() {
        setError({
            code: 0,
            title: "",
            message: "",
            count: 0
        });
    }

    function handleExcelDownload() {
        setExcelDownload(false);
    }

    async function handleUserFile(event: React.ChangeEvent<HTMLInputElement>) {
        if (!mode) {
            notValidateFn();
            setSingleUploadedName(event.target.files![0].name)
            let emailid = sessionStorage.getItem("email");

            const file = event.target.files![0];
            const FileType = event.target.files![0].type.split("/")[1];
            const base64: any = await base64Convertor(file);
            const finalBase64 = base64.split(",")[1];
            setFileInfo({
                Main_Image: finalBase64,
                File_Type: FileType,
                File_Name: fileName,
                mode: !mode ? 'single' : 'multiple',
                User_Name: emailid
            });
        }
        else {
            notValidateFn();
            const file = event.target.files![0];
            setMultipleUploadedName(event.target.files![0].name)
            const FileType = event.target.files![0].type.split("/")[1];
            const base64: any = await base64Convertor(file);
            const finalBase64 = base64.split(",")[1];
            setFileInfo({
                Main_File: finalBase64,
                File_Name: fileName.File_Name,
                mode: !mode ? 'single' : 'multiple',
                User_Name: fileName.User_Name
            });
        };
    }

    // const handleUpload = async () => {
    //     try {
    //         const response = await fetch(`${IMG_URL}/api/multipleFiles`, {
    //             body: fileInfo,
    //             headers: {
    //                 "Content-Type": "multipart/form-data",
    //                 "Access-Control-Allow-Origin": "http://localhost:3000",
    //                 "Access-Control-Allow-Credentials": "true"
    //             },
    //         });
    //         const result = await response.json();
    //         console.log(result);

    //         console.log('File uploaded successfully:');
    //         // Handle success or additional logic here
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //         // Handle error
    //     }
    // };

    function base64Convertor(file: any) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result as string);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    function getFinalImage(image: any) {
        stepFn(3);
        const stringWithoutSingleQuotesFinal = image?.replace(/'/g, "").replace("b/", "") || "";
        let finalImage = "/" + stringWithoutSingleQuotesFinal;
        setFinalImage('data:image/jpg;base64,' + finalImage);
        uploaded(true);
    }

    const handleDownload = async () => {
        try {
            const response = await fetch(`${IMG_URL}/api/downloadFile`, {
                method: "POST",
                body: JSON.stringify(fileInfo),
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "lntcs-Frontend": "4cbca3e312a241de9656b64260c2cd7a",
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.xlsx'); // Set the default file name
            document.body.appendChild(link);
            link.click();
            link.remove();
            setExcelDownload(true);
        } catch (error) {
            setExcelDownload(false);
            console.error('Error downloading file:', error);
        }
    };

    return (
        <>
            {!mode && <div className="mainHolder">
                <div className="d-flex align-items-center g-15">
                    <Form.Group controlId="formFile" className="">
                        <Form.Control onChange={handleUserFile} type="file" />
                        {singleUploadedName === "" && <p className="subText">Please, Upload the File</p>}
                        {singleUploadedName !== "" && <p className="subText">File Name - <span className="fileName">{singleUploadedName}</span></p>}
                    </Form.Group>
                </div>
                <div className={`h-100 d-flex justify-content-center align-items-center mt-5 ${finalImage ? "" : "uploadImage"}`} >
                    {finalImage && <img className="imageResolution" src={finalImage} alt="" />}
                    {!finalImage && <p style={{ marginTop: "7%" }}>Please, Upload the image...</p>}
                </div>
            </div>}

            {mode && <div className="d-flex flex-column g-15">
                <Form.Group controlId="formFile" className="px-2">
                    <Form.Control className="w-50" onChange={handleUserFile} type="file" name="file" accept=".zip" />
                    {/* <p> File Name - <span>{multipleUploadedName === "" ? "NULL"}</span></p> */}
                    {multipleUploadedName === "" && <p className="subText">Please, Upload the Zip File.</p>}
                    {multipleUploadedName !== "" && <p className="subText">File Name - <span className="fileName">{multipleUploadedName}</span></p>}
                </Form.Group>
                <div className={`h-100 d-flex justify-content-center align-items-center mt-5 ${finalImage ? "" : "uploadImage"}`} >
                    {multipleFile !== "" && <p style={{ marginTop: "7%" }} className="multipleFileText">Please, <span onClick={handleDownload} className="downloadLink"> click here </span> to download the excel file.</p>}
                    {multipleFile === "" && <p style={{ marginTop: "7%" }}>Please, Upload the Zip File contains multiple files.</p>}
                </div>
                {/* <Button onClick={handleUpload} variant="dark"> Submit </Button> */}
            </div>}

            {error.code === 1 && <Dialog open={error} closeTool={handleCloseTool}>
                <div className="errorHolder">
                    <h2>{error.title}</h2>
                    <p className="errorMessage">{error.message}</p>
                </div>
            </Dialog>}

            {excelDownload && <Dialog open={excelDownload} closeTool={handleExcelDownload}>
                <div className="errorHolder">
                    <h2>Success</h2>
                    <p className="errorMessage">Excel File is Downloaded Successfully. Please, check downloads folder in File Manager.</p>
                </div>
            </Dialog>}
        </>
    );
};

export default UploadImage;

// 88359216