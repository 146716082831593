import { useParams } from "react-router-dom";
import Header from "../elements/Header";
import { AlgorithmID_MAP } from "../utils/AlgorithmID_MAP";
import WebCam from "../elements/WebCam";
import Rtsp_Stream from "../elements/RSTP_demo";
import VideoUpload from "../elements/Video";
import ImgUpload from "../elements/Img";
import Layout from "../../pages/layout/Layout";
import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "./../cv_components.css";
import loading from "../../assets/loading.gif";
export default function DemoPage() {
    const { AppId } = useParams();
    const [Title] = AlgorithmID_MAP({ AppId });
    const [ActiveTab, setActiveTab] = useState(0);
    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);
    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabId: any) => {
        event.preventDefault();
        ToggleActive(tabId);
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        // Scroll to top on component mount
        scrollToTop();
    }, []);

    function ToggleActive(id: any) {
        setActiveTab(id);
    }
    let tabDetails = [];

    tabDetails = [
        {
            id: 0,
            name: "Web Camera",
            icon: {
                svg_viewbox: "0 0 20 20",
                path: "M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"
            }
        },
        {
            id: 1,
            name: "Image",
            icon: {
                svg_viewbox: "0 0 24 24",
                path: "M5 21h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2zm3-7l2.363 2.363L14 11l5 7H5l3-4z"
            }
        },
        {
            id: 2,
            name: "CCTV Stream",
            icon: {
                svg_viewbox: "0 0 22 20",
                path: "M10 10.414l4 4 5.707-5.707L22 11V5h-6l2.293 2.293L14 11.586l-4-4-7.707 7.707 1.414 1.414z"
            }
        },
        {
            id: 3,
            name: "Video",
            icon: {
                svg_viewbox: "0 0 512 512",
                path: "M464 384.39a32 32 0 01-13-2.77 15.77 15.77 0 01-2.71-1.54l-82.71-58.22A32 32 0 01352 295.7v-79.4a32 32 0 0113.58-26.16l82.71-58.22a15.77 15.77 0 012.71-1.54 32 32 0 0145 29.24v192.76a32 32 0 01-32 32zM268 400H84a68.07 68.07 0 01-68-68V180a68.07 68.07 0 0168-68h184.48A67.6 67.6 0 01336 179.52V332a68.07 68.07 0 01-68 68z"
            }
        }
    ];

    // const tabItems = tabDetails.map((tabs) =>
    //         <li className="me-2" key={tabs.id}>
    //         {(tabs.id===ActiveTab)?
    //             <a href="#" className="inline-flex text-blue-600 items-center justify-center p-4 border-b-2 border-blue-600 rounded-t-lg  group">
    //             <svg className="w-4 h-4 me-2 text-blue-600 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox={tabs.icon.svg_viewbox}>
    //             <path d={tabs.icon.path}/>
    //             </svg>
    //             {tabs.name}
    //         </a>
    //         :
    //             <a href="#" className="inline-flex  items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group" onClick={()=>ToggleActive(tabs.id)}>
    //                 <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox={tabs.icon.svg_viewbox}>
    //             <path d={tabs.icon.path}/>
    //             </svg>
    //             {tabs.name}
    //         </a>
    //         }

    //         </li>
    //         );
    const tabItems = tabDetails.map(tabs => (
        <li className="me-2" key={tabs.id} style={{ pointerEvents: tabs.id === 3 ? 'none' : 'auto', opacity: tabs.id === 3? 0.5 : 1 }}>
            {tabs.id === ActiveTab ? (
                <a href="#" className="inline-flex text-blue-600 items-center justify-center p-4 border-b-2 border-blue-600 rounded-t-lg group">
                    <svg
                        className="w-4 h-4 me-2 text-blue-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox={tabs.icon.svg_viewbox}
                    >
                        <path d={tabs.icon.path} />
                    </svg>
                    {tabs.name}
                </a>
            ) : (
                <a
                    href="#"
                    className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                    onClick={e => handleTabClick(e, tabs.id)}
                >
                    <svg
                        className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox={tabs.icon.svg_viewbox}
                    >
                        <path d={tabs.icon.path} />
                    </svg>
                    {tabs.name}
                </a>
            )}
        </li>
    ));

    return (
        <>
            <div>
                <Layout />
                <div className="mx-7">
                    <img
                        src={loading}
                        style={{ display: isCustomloading ? "block" : "none", width: "70px", position: "fixed", top: "50%", left: "50%", zIndex: "99999" }}
                    />
                    <h4 className="text-2xl font-bold my-2 text-gray-700 ">{Title}</h4>
                </div>
                <div className="bg-white rounded-lg shadow px-4 py-2 min-h-screen mx-7">
                    <div className="border-b border-gray-200 ">
                        <ul
                            className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 "
                            style={{ background: "linear-gradient(90deg, #f1f1f1, #fff)" }}
                        >
                            {tabItems}
                        </ul>
                    </div>
                    {ActiveTab === 0 ? (
                        <>
                            {/* <p>Web Camera</p> */}
                            <WebCam />
                        </>
                    ) : (
                        <></>
                    )}
                    {ActiveTab === 2 ? (
                        <>
                            <Rtsp_Stream />
                        </>
                    ) : (
                        <></>
                    )}
                    {ActiveTab === 3 ? (
                        <>
                            <VideoUpload />
                        </>
                    ) : (
                        <></>
                    )}
                    {ActiveTab === 1 ? (
                        <>
                            <ImgUpload />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
}
