import Layout from "../layout/Layout";
import IframeComponent from "../customIframe";

export const SCMAI = ({ children }: any) => {
    return (
        <>
            <Layout />
            <IframeComponent backUrl="" />
        </>
    );
};
export default SCMAI; 