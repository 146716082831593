// ID route
import { WC_VISION_AI_URL } from "../../constants";
export function getProjectSource(Data: any) {
    let Project: any = Data["Project"];
    // console.log(Project['PID'],"+++")=
    let PID = Data["PID"];
    let SID = Data["SID"];
    // console.log(PID)
    // console.log(SID)
    const parentKey = Object.keys(Project).find(key => {
        console.log(
            Project[key].some((project: any) => project.PID === PID),
            "key",
            PID
        );
        return Project[key].some((project: any) => project.PID === PID);
    });
    console.log(parentKey, "--");
    if (parentKey) {
        const projectArray = Project[parentKey];
        const project = projectArray.find((project: any) => project.PID === PID);

        if (project) {
            const source = project.Sources.find((source: any) => source.SID === SID);

            if (source) {
                console.log("source", source);
                return {
                    parentKey,
                    sourceName: source.name,
                    sourceURL: source.URL,
                    payload: source.payload,
                    type: source.Type,
                    sid: source.SID
                };
            }
        }
    }
    return null;
}

export function ProjectID_MAP({ AppId }: any) {
    console.log(AppId, "AppId");
    let Project: any;
    //if (AppId === "101") {
    Project = {
        Innovation_Campus: [
            {
                PID: "101",
                Sources: [
                    {
                        SID: "10",
                        name: "Project Office",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:449:/Streaming/Channels/101" }
                    },
                    {
                        SID: "11",
                        name: "Project Road  Access",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:554:/Streaming/Channels/101" }
                    },
                    {
                        SID: "12",
                        name: "Compound Wall",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:556:/Streaming/Channels/101" }
                    }
                ]
            }
        ],
        Raintree: [
            {
                PID: "102",
                Sources: [
                    {
                        SID: "10",
                        name: "Site 1",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:Rtb@2022@113.193.183.92:554:/Streaming/Channels/101" }
                    },
                    {
                        SID: "11",
                        name: "Site 2",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:Rtb@2022@113.193.183.92:557:/Streaming/Channels/101" }
                    },
                    {
                        SID: "12",
                        name: "Stores OutSide",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@113.193.183.92:555:/Streaming/Channels/101" }
                    }
                ]
            }
        ],
        Super_Speciality_Hospital_Warangal: [
            {
                PID: "103",
                Sources: [
                    {
                        SID: "10",
                        name: "Site 1",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@103.126.205.6:561:/Streaming/Channels/101" }
                    },
                    {
                        SID: "11",
                        name: "Site 2",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@103.126.205.6:558:/Streaming/Channels/101" }
                    },
                    {
                        SID: "12",
                        name: "Main Gate",
                        Type: "rtsp",
                        // URL: `ws://${WC_VISION_AI_URL}/video_feed_ws/${Date.now()}`,
                        URL: `wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=${Date.now()}`,
                        payload: { RTSP_SRC: "rtsp://admin:admin@123@103.126.205.6:557:/Streaming/Channels/101" }
                    }
                ]
            }
        ]
    };
    //}

    // if (AppId === "1002") {
    //     Project = {
    //         LE21M866: [
    //             {
    //                 PID: "101",
    //                 Sources: [
    //                     {
    //                         SID: "10",
    //                         name: "Project Office",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:449:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "11",
    //                         name: "Project Road  Access",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:554:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "12",
    //                         name: "Compound Wall",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:556:/Streaming/Channels/101" }
    //                     }
    //                 ]
    //             }
    //         ]
    //     };
    // }

    // if (AppId === "1003") {
    //     Project = {
    //         LE21M866: [
    //             {
    //                 PID: "101",
    //                 Sources: [
    //                     {
    //                         SID: "10",
    //                         name: "Project Office",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:449:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "11",
    //                         name: "Project Road  Access",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:554:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "12",
    //                         name: "Compound Wall",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:556:/Streaming/Channels/101" }
    //                     }
    //                 ]
    //             }
    //         ]
    //     };
    // }
    // if (AppId === "1004") {
    //     Project = {
    //         LE21M866: [
    //             {
    //                 PID: "101",
    //                 Sources: [
    //                     {
    //                         SID: "10",
    //                         name: "Project Office",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:449:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "11",
    //                         name: "Project Road  Access",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:554:/Streaming/Channels/101" }
    //                     },
    //                     {
    //                         SID: "12",
    //                         name: "Compound Wall",
    //                         Type: "rtsp",
    //                         URL: `ws://${WC_VISION_AI_URL}/video_feed_ws`,
    //                         payload: { RTSP_SRC: "rtsp://admin:admin@123@183.83.49.250:556:/Streaming/Channels/101" }
    //                     }
    //                 ]
    //             }
    //         ]
    //     };
    // }

    console.log(Project);
    return [Project];
}
