import { useState } from "react";
import Loader from "../ImageAnalytics/UI/Loader";
import StandardImages from "./StandardImages";
import UploadImage from "./UploadImage";
import FinalImage from "./FinalImage";
import { ReactEventHandler } from "react";
import { IMG_URL } from "../../constants";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


// import { Any } from "@react-spring/web";
import coeaaicon from "../ImageAnalytics/assets/CoEAA_Logo.png";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
// import { Display } from "@fluentui/react-components";

export default function MainPage(): JSX.Element {
    const [toolContainer, setToolContainer] = useState<boolean>(true);
    const [toolOpen, setToolOpen] = useState<boolean>(false);
    const [loaderTime, setLoaderTime] = useState<boolean>(false);
    const [accuracy, setAccuracy] = useState<any>([]);
    const [validate, setValidate] = useState<boolean>(false);
    const [stdUpload, setStdUpload] = useState<boolean>(false);
    const [testUpload, setTestUpload] = useState<boolean>(false);
    const [mode, setMode] = useState<boolean>(false);
    const [error, setError] = useState({
        code: 0,
        title: '',
        message: ''
    });
    const [STEPS, setSteps] = useState([
        {
            id: 1,
            title: "STEP 1",
            message: "Upload the standard image.",
            status: "None"
        },
        {
            id: 2,
            title: "STEP 2",
            message:
                "Open the Annotated Tool and crop the desired image.when you done cropping images, click OK button navigate to the Test image upload section.",
            status: "None"
        },
        {
            id: 3,
            title: "STEP 3",
            message: "Upload the testing image to get the final test image with bounding box based on cropped image.",
            status: "None"
        },
        {
            id: 4,
            title: "STEP 4",
            message: "Click validate to get the accuracy of the uploaded test image.",
            status: "None"
        }
    ]);
    const [fileName, setFileName] = useState<any>({});
    const [stdImage, setStdImage] = useState<any>("");

    function handleResetStdImage() {
        setStdImage("");
    }

    function stdUploaded(value: boolean) {
        setStdUpload(value);
    }

    function testUploaded(value: boolean) {
        setTestUpload(value);
    }

    function handleOpenTool() {
        setToolOpen(prev => !prev);
        setToolContainer(true);
    }

    function handleToolContainer() {
        setToolContainer(true);
        setAccuracy([]);
    }

    function handleCloseTool() {
        setError({
            code: 0,
            title: '',
            message: ''
        });
    }

    function handleToggle() {
        setMode(!mode);
    }

    function handleSteps(stepNo: any) {
        if (stepNo === 1) {
            setSteps([
                {
                    id: 1,
                    title: "STEP 1",
                    message: "Upload the standard image.",
                    status: "Completed"
                },
                {
                    id: 2,
                    title: "STEP 2",
                    message:
                        "Open the Annotated Tool and crop the desired image.when you done cropping images, click OK button navigate to the Test image upload section.",
                    status: "None"
                },
                {
                    id: 3,
                    title: "STEP 3",
                    message: "Upload the testing image to get the final test image with bounding box based on cropped image.",
                    status: "None"
                },
                {
                    id: 4,
                    title: "STEP 4",
                    message: "Click validate to get the accuracy of the uploaded test image.",
                    status: "None"
                }
            ]);
        }

        if (stepNo === 2) {
            setSteps([
                {
                    id: 1,
                    title: "STEP 1",
                    message: "Upload the standard image.",
                    status: "Completed"
                },
                {
                    id: 2,
                    title: "STEP 2",
                    message:
                        "Open the Annotated Tool and crop the desired image.when you done cropping images, click OK button navigate to the Test image upload section.",
                    status: "Completed"
                },
                {
                    id: 3,
                    title: "STEP 3",
                    message: "Upload the testing image to get the final test image with bounding box based on cropped image.",
                    status: "None"
                },
                {
                    id: 4,
                    title: "STEP 4",
                    message: "Click validate to get the accuracy of the uploaded test image.",
                    status: "None"
                }
            ]);
        }

        if (stepNo === 3) {
            setSteps([
                {
                    id: 1,
                    title: "STEP 1",
                    message: "Upload the standard image.",
                    status: "Completed"
                },
                {
                    id: 2,
                    title: "STEP 2",
                    message:
                        "Open the Annotated Tool and crop the desired image.when you done cropping images, click OK button navigate to the Test image upload section.",
                    status: "Completed"
                },
                {
                    id: 3,
                    title: "STEP 3",
                    message: "Upload the testing image to get the final test image with bounding box based on cropped image.",
                    status: "Completed"
                },
                {
                    id: 4,
                    title: "STEP 4",
                    message: "Click validate to get the accuracy of the uploaded test image.",
                    status: "None"
                }
            ]);
            // setSteps([
            //     ...STEPS,
            //     { id: stepNo, status: "Completed" }
            // ]);
        }

        if (stepNo === 4) {
            setSteps([
                {
                    id: 1,
                    title: "STEP 1",
                    message: "Upload the standard image.",
                    status: "Completed"
                },
                {
                    id: 2,
                    title: "STEP 2",
                    message:
                        "Open the Annotated Tool and crop the desired image.when you done cropping images, click OK button navigate to the Test image upload section.",
                    status: "Completed"
                },
                {
                    id: 3,
                    title: "STEP 3",
                    message: "Upload the testing image to get the final test image with bounding box based on cropped image.",
                    status: "Completed"
                },
                {
                    id: 4,
                    title: "STEP 4",
                    message: "Click validate to get the accuracy of the uploaded test image.",
                    status: "Completed"
                }
            ]);
        }
    }

    async function handleColSection() {
        setToolContainer(false);
        setLoaderTime(true);
        testUploaded(false);
        handleNotValidate();
        console.log(JSON.stringify(fileName));
        const response = await fetch(`${IMG_URL}/api/processCroppedImages`, {
            method: "POST",
            body: JSON.stringify(fileName),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "lntcs-Frontend": "4cbca3e312a241de9656b64260c2cd7a",
            }
        });
        setLoaderTime(false);
        const result = await response.json();
        if (result.errorCode === 1) {
            console.log(result);
            // setError({
            //     code: 1,
            //     title: result.errorTitle,
            //     message: result.errorMessage
            // })
            return;
        }

        handleSteps(2);
        console.log(result);
        getFinalImage(result["Final_Image"]);
    }

    function getFinalImage(image: any) {
        const stringWithoutSingleQuotesFinal = image?.replace(/'/g, "").replace("b/", "") || "";
        let finalImage = "/" + stringWithoutSingleQuotesFinal;
        setStdImage("data:image/jpg;base64," + finalImage);
        stdUploaded(true);
    }

    function getAccuracy(accuracyValue: any) {
        setAccuracy(accuracyValue);
    }

    function getFileName(fil_name: any) {
        setFileName(fil_name);
    }

    function loader(value: boolean): void {
        setLoaderTime(value);
    }

    function handleValidate(): void {
        handleSteps(4);
        setValidate(true);
    }


    function handleNotValidate() {
        setValidate(false);
    }

    const iframeloaded: ReactEventHandler<HTMLIFrameElement> = event => {
        // alert("iframeloaded");
    };

    // function handleNotValidate() {
    //     setValidate(false);
    // }

    return (
        <div>
            {loaderTime && <Loader />}

            <div className="mainContainer">
                <Row className="g-10">
                    <Col className={`bg_3 ${stdUpload ? 'hc-a' : 'hei-90'}`}>
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <img src={coeaaicon} style={{ width: "40px", height: "40px" }}></img>
                            <legend>Standard Image</legend>
                        </div>
                        <FinalImage file_path={getFileName} uploadedReset={stdUploaded} resetStdImage={handleResetStdImage} STEPS={STEPS} toolContainer={handleToolContainer} stdImage={stdImage} stepFn={handleSteps} tool={handleOpenTool} />
                    </Col>
                    {toolContainer && (
                        <Col xs={8} className={toolOpen ? "" : "d-flex justify-content-center align-items-center"}>
                            {!toolOpen && (
                                <div className="text-center">
                                    {/* <img src="src\assets\Icons\object-group.svg" /> */}
                                    <p className="">Image Annotation Tool</p>
                                </div>
                            )}

                            {toolOpen && (
                                <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h2>Image Annotation Tool</h2>
                                        <p onClick={handleOpenTool} className="close">
                                            &times;
                                        </p>
                                    </div>
                                    <iframe src="https://coe-computervision.azure-api.net/tool/dash" onLoad={iframeloaded}></iframe>
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={handleColSection} variant="success">
                                            Ok
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Col>
                    )}

                    {!toolContainer && (
                        <Col className={`bg_2 ${testUpload ? 'hc-a' : 'hei-90'}`}>
                            <div className="d-flex justify-content-between align-items-center">
                                <legend>Test Image</legend>
                                {/* <ButtonGroup onClick={handleToggle}>
                                    <Button variant="info">Single</Button>
                                    <Button variant="info">Multiple</Button>
                                </ButtonGroup> */}
                                <div className="d-flex btnGrpHolder">
                                    <button onClick={handleToggle} className={"Left " + (!mode ? 'active' : '')}>Single</button>
                                    <button onClick={handleToggle} className={"Right " + (mode ? 'active' : '')}>Multiple</button>
                                </div>
                                {/* <Button onClick={handleToggle} variant="info">{!mode ? 'Single' : 'Multiple'}</Button> */}
                            </div>
                            <UploadImage mode={mode} uploaded={testUploaded} accuracy={getAccuracy} isLoading={loader}
                                notValidateFn={handleNotValidate} stepFn={handleSteps} fileName={fileName} />
                        </Col>
                    )}
                    {!mode && !toolContainer ? (
                        <Col className="bg_1 scroll hei-90 col">
                            <legend>Validation Report</legend>
                            <div className="row hei-85">
                                <div className="col-3">
                                    <div className="py-10">
                                        <Button onClick={handleValidate} disabled={accuracy && accuracy.length <= 0} variant="dark">
                                            Validate
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <StandardImages stepFn={handleSteps} accuracy={accuracy} fileName={fileName} isValidate={validate} />
                                </div>
                            </div>
                        </Col>
                    ) : ""
                    }
                </Row>
            </div>
        </div>
    );
}
