import Layout from "../layout/Layout";
import IframeComponent from "../customIframe";

export const FinanceAI = ({ children }: any) => {
    return (
        <>
            <Layout />
            <IframeComponent  backUrl="" />
        </>
    );
};
export default FinanceAI; 