import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { ICamera, IListOptions, Project, TAction, User } from "../type";
import style from "./organization.module.css"
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import DropdownWithCheckboxes from "../../../design system/DropdownCheckbox";
import AddOrganization from ".";
import { ORGANIZATIONS_API_URL, ORGANIZATION_MAPPING_API_URL,USER_API_URL, getActiveUser } from "../vision.constant";


export const Organization = () => {
    const [editOrg, setEditOrg] = useState<any>(null);
    const [deleteOrg, setDeleteOrg] = useState<any>(null);
    const [org, setOrg] = useState<any>(null);
    const [orgs, setOrgs] = useState<any[]>([]);
    const [orglist, setOrglist] = useState<any[]>([]);
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [showOrgModal, setShowOrgModal] = useState<boolean>(false);
    const [modalOrgTitle, setModalOrgTitle] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [orgError, setOrgError] = useState("");
    const [adminError, setAdminError] = useState("");
    const [adminList, setAdminList] = useState<IListOptions[]>([]);
    const [organizationList, setOrganizationList] = useState<IListOptions[]>([]);
    const {
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        { id: 'id', label: 'Id' },
        { id: 'organization', label: 'Organization', group: true, type: 'object' },
        { id: 'admins', label: 'Admin users', group: true, type: 'object' },
        { id: 'action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                
                console.log('Yes:', { deleteOrg, editOrg, org });
                if (deleteOrg) {
                    deleteOrganization()
                } else if (editOrg || org) {
                    saveOrganization()
                }
            }, variant: 'contained', color: 'success'
        },
    ];
    const actions2: TAction[] = [
        {
            label: 'Close', onClick: () => {
                setShowOrgModal(false);
            }, variant: 'contained', color: 'error'
        }
    ];
    useEffect(() => {

        const loadInitial = async () => {
            await fetchUsers();
            await fetchOrganizationlist()
            await fetchOrganization();
        }
        loadInitial();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);
    const fetchOrganization = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any[]>(ORGANIZATION_MAPPING_API_URL);
            const convertedData = data?.response?.map((resp: any, index: number) => ({
                id: index + 1,
                organization: [{
                    id: resp?.org_id,
                    label: resp?.org_name
                }],
                admins: resp?.org_admin_user_ids?.map((user: any) => ({
                    id: user?.id,
                    label: user?.name
                })) || []
            }));
            console.log(convertedData, 'convertedData', { response: data.response, organizationList, adminList });

            setOrgs(convertedData);
        } catch (error) {
            showToast("Error fetching org list", "error");
            console.error('Error fetching orgs:', error);
        } finally {
            setLoader(false);
        }
    };
    const fetchOrganizationlist = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any>(ORGANIZATIONS_API_URL);
            setOrglist(data.response);
            const convertedOption = data?.response.map((org: any) => ({
                id: org.org_id,
                label: org.org_name
            }))
            setOrganizationList(convertedOption)
        } catch (error) {
            showToast("Error fetching org list", "error");
            console.error('Error fetching orgs:', error);
        } finally {
            setLoader(false);
        }
    };

    const fetchUsers = async () => {
        try {
            setLoader(true)
            const userList: any = await api.get<User[]>(USER_API_URL);
            const convertIntoOptions = userList?.response?.map((user: User) => ({
                id: user.id,
                label: `${user.name} - ${user.email}`
            })) || [];

            setAdminList(convertIntoOptions);
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users:', error);
        } finally {
            setLoader(false);
        }
    };
    const validateInputs = () => {
        let isValid = true;
        if (org?.organization?.length==0 ||!org?.organization) {
            setOrgError('select organization');
            isValid = false;
        }
        if (org?.admins?.length==0 || !org?.admins) {
            setAdminError('select admin');
            isValid = false;
        }
        return isValid;
    };
    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true)
        setModalTitle(`Are you sure you want to ${editOrg ? 'update' : 'add'} this Organization Admin?`)
    };
   
    const saveOrganization = async () => {
        
        console.log({ editOrg, org }, 'saveOrganization');
        try {
            setLoader(true);
            if (editOrg) {
                const payload = {
                    org_id: org.organization[0].id,
                    org_admin_user_ids: org.admins.map((admin: any) => admin.id),
                    updated_by: getActiveUser(),
                };
                await api.put<any>(`${ORGANIZATION_MAPPING_API_URL}/${editOrg?.organization[0].id}`, payload);
            } else {
                const payload = {
                    org_id: org.organization[0].id,
                    org_admin_user_ids: org.admins.map((admin: any) => admin.id),
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                };
                await api.post<any>(ORGANIZATION_MAPPING_API_URL, payload);
            }
            setEditOrg(null);
            setDeleteOrg(null)
            await fetchOrganization();
            updateBreadcrumbList(true, "")
            clearInputs();
            setSearchQuery("");
            showToast(`Organization Admin ${editOrg ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`Organization Admin ${editOrg ? 'updating' : 'adding'} failed`, 'error');
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = (org: any) => {
        console.log(org, "patchValue");
        clearInputs();
        setDeleteOrg(null)
        setEditOrg(org);
        setOrg(org);
        scrollToTop();
        setShowAddIcon(false);
        updateBreadcrumbList(false, "Edit Organization Admin")
    };

    const handleDelete = (org: any) => {
        setShowModal(true)
        setDeleteOrg(org?.organization[0].id);
        setModalTitle("Are you sure you want to delete this Organization admin?")
    }
    const updateBreadcrumbList = useCallback((rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    }, [setBreadcrumbs]);
    const deleteOrganization = async () => {
        console.log(deleteOrg, 'deleteOrg');
        setEditOrg(null);
        try {
            setLoader(true);
            await api.remove<any>(`${ORGANIZATION_MAPPING_API_URL}/${deleteOrg}`);
            fetchOrganization();
            clearInputs();
            showToast('Organization admin deleted successfully', 'success');
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast('Error deleting org admin', 'error');
            console.error('Error deleting orgs:', error);
        } finally {
            setLoader(false);
        }
    };
    const clearInputs = () => {
        setOrg({ organization: [], admins: [] });
        setOrgError('');
        setAdminError('');
    };
    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditOrg(null)
        setSearchQuery("");
        updateBreadcrumbList(true, "")
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };

    const showToast = useCallback((message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }, [setOpenToast, setToastMessage, setToastSeverity]);
    const handleClose = () => {
        setShowModal(false);
    };
    const handleAdd = () => {
        setShowAddIcon(false);
        setDeleteOrg(null);
        setEditOrg(null)
        setSearchQuery("");
        updateBreadcrumbList(false, "Add Organization Admin")
    }
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredOrgList = useMemo(() => {
        return filterByTableSearch(orgs, searchQuery);
    }, [orgs, searchQuery]);

    const handleDropdownChange = (value: any[], name: string) => {
        console.log(value, "value");

        setOrg((prev: any) => ({
            ...prev,
            [name]: value
        }));

        if(name==="organization"){
            setOrgError("")
        }else if(name==="admins"){
            setAdminError("")
        }
    };

    const handleOrgClose = () => {
        setShowOrgModal(false);
        fetchOrganizationlist();
        fetchOrganization();

        console.log('handleOrgClose');

    }
    const handleViewOrg = () => {
        setShowOrgModal(true);
        console.log('handleViewOrg');

    }
    return (
        <>
            <div className={style.org__container}>
                <strong>Organization Mapping</strong>
                <div className={style.org__iconContainer}>
                    <Tooltip title={"View Organizations"}>
                        <GridViewIcon onClick={handleViewOrg} className={style.view__icon} />
                    </Tooltip>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editOrg ? 'Update Organization Mapping' : "Save Organization Mapping"}>
                                <img width={24} height={20} className={`${style.org__curserPointer} ${style.org__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add Organization Mapping">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                            { }
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.org__inputWrapper}>

                    <Grid item xs={3}>
                        <DropdownWithCheckboxes
                            id="organization"
                            name="organization"
                            label="Select Organization"
                            options={organizationList as any}
                            value={org?.organization}
                            placeholder="Select organization"
                            helperText={orgError}
                            multiple={false}
                            disabled={editOrg}
                            onChange={(value: any) => handleDropdownChange(value, "organization")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DropdownWithCheckboxes
                            id="admins"
                            name="admins"
                            label="Admin users"
                            options={adminList as any}
                            helperText={adminError}
                            value={org?.admins}
                            placeholder="Select admin"
                            onChange={(value: any) => handleDropdownChange(value, "admins")}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredOrgList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredOrgList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
            <CustomDialog
                open={showOrgModal}
                onClose={handleOrgClose}
                title={modalOrgTitle}
                actions={actions2}
                className={"org-model"}
                content={<AddOrganization />}
            />
        </>
    );
};
export default Organization; 
