// @flow 
import * as React from 'react';
type Props = {

};
export const History = (props: Props) => {
    return (
        <div>
            History Component
        </div>
    );
};

export default History; 
