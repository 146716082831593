import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Layout from "../layout/Layout";
import Breadcrumbs from "../../design system/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { Users } from "../visionPlatform/user/users";
import { Grid, Tooltip, TextField } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveIcon from "../../assets/Table/save.svg";
import plusIcon from "../../assets/Table/Plus.svg";
import Input from "../../design system/Input";
import CustomTableComponent from "../../design system/Table";
import { LoaderComponent } from "../visionPlatform/loader/loader";
import CustomToast from "../../design system/Toast";
import CustomDialog from "../../design system/Dialog/Index";
import AutocompleteAtom from "../../design system/AutoComplete";
import { Column } from "../../design system/Table/type";
import ApiHelper from "../../api/ApiHelper";
import { scrollToTop } from "../../utils/scroll";
import { isValidEmail } from "../../utils/validation";
import DropdownWithCheckboxes from "../../design system/DropdownCheckbox";
import { filterByTableSearch } from "../../utils/tableFilter";
import { IListOptions, TAction, User } from "./type";
import { AdminContext } from "../../context/admin.context";
import { BreadcrumbItem } from "../../design system/Breadcrumbs/type";
import TableSkeleton from "../../design system/Skeleton/TableSkeleton";
import style from './TabAccess.module.css'
import { UpdateLogout } from "../../api";
import { RESOURCE_NAME, SAS_TOKEN, STORAGE_ACCOUNT, BACKEND_URI } from "../../constants";
import { useMsal } from "@azure/msal-react";
import { useErrorHandling } from "../../context/errorHandlingContext";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function TabAccess() {
    const [breadcrumbsData, setBreadcrumbsData] = useState<BreadcrumbItem[]>([
        { label: "Home", link: "/" },
        { label: "Tab Access", link: "" }
    ]);
    const navigate = useNavigate();
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [userCaseList, setUserCaseList] = useState<User[]>([]);
    const [userAccess, setUserAccess] = useState<User>({ emailId: "", useCase: [] });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState<boolean>(true);
    const [deleteUserAccess, setDeleteUserAccess] = useState<any>(null);
    const [editingUserAccess, setEditingUserAccess] = useState<any | null>(null);
    // const [groupNameError, setGroupNameError] = useState<string>("");
    const [groupEmailError, setGroupEmailError] = useState<string>("");
    const [groupListError, setGroupListError] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState("");
    const {
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle,
        toastMessage,
        openToast,
        toastSeverity,
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    let activeAccount: any;
    const { instance } = useMsal();
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    /**
     * Columns configuration for the user table.
     * @type {Column[]}
     */
    const columns: Column[] = [
        { id: "id", label: "Id" },
        // { id: "name", label: "Name" },
        { id: "emailId", label: "Email" },
        { id: "useCase", label: "Use Case", group: true },
        { id: "action", label: "Action", align: "center" }
    ];

    /**
     * Options available for user use cases.
       * @type {IListOptions[]}
       */

    const options: IListOptions[] = [
        { id: 1, label: "DatabaseQuery" },

        { id: 3, label: "HR" },
        { id: 4, label: "PIXELMATCHER" },
        { id: 5, label: "IT" },
        { id: 6, label: "VISIONAI" },
        { id: 7, label: "SCM" },
        { id: 8, label: "FINANCE" }
    ];

    /**
       * Actions to be displayed in the confirmation dialog.
       * @type {TAction[]}
       */

    const actions: TAction[] = [
        {
            label: "No",
            onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            },
            variant: "contained",
            color: "error"
        },
        {
            label: "Yes",
            onClick: () => {
                console.log(deleteUserAccess, "deleteUserAccess");

                if (deleteUserAccess) {
                    handleDelete();
                } else if (editingUserAccess || userAccess) {
                    saveUserAccess();
                }
            },
            variant: "contained",
            color: "success"
        }
    ];

    useEffect(() => {
        fetchUseCase();
    }, []);
    /**
    * Fetches the list of access list from the API and updates state.
    */
    const fetchUseCase = async () => {
        try {
            const userAccess: any[] = await api.get<any[]>("https://ltc-coe-genai01-appservice.azurewebsites.net/GetAccessList");
            const convert: any = userAccess?.map((grp: any, index) => ({
                id: grp.id ? grp.id : index + 1,
                // name: grp.name,
                emailId: grp.emailId,
                useCase: grp.useCase
            }));
            setUserCaseList(convert);
        } catch (error: any) {
            console.error("Error fetching user group:", error);
            showToast("Error fetching user access list", "error");
        } finally {
            setLoader(false);
        }
    };
    /**
     * Handles click on a breadcrumb item.
     * @param {BreadcrumbItem} item - The breadcrumb item clicked.
     */
    const breadcrumbsClick = (item: BreadcrumbItem) => {
        if (item.link === "no-route") return
        if (item?.link || item.link === "") {
            navigate(item.link);
        }
    };

    /**
     * Shows a toast message with specified severity.
     * @param {string} message - The message to display in the toast.
     * @param {"error" | "warning" | "info" | "success"} severity - The severity level of the toast.
     */
    const showToast = useCallback((message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }, [setOpenToast, setToastMessage, setToastSeverity]);


    /**
   * Handles the addition of a new user access.
   */
    const handleAdd = () => {
        clearInputs();
        setSearchQuery("");
        setShowAddIcon(false);
        setEditingUserAccess(null);
        setBreadcrumbsData([
            { label: "Home", link: "/" },
            { label: "Tab Access", link: "" },
            { label: "Add user access", link: "no-route" }
        ]);
    };
    /**
       * Handles cancelling the addition or editing of user access.
       */
    const handleRemove = () => {
        clearInputs();
        setSearchQuery("");
        setShowAddIcon(true);
        setEditingUserAccess(null);
        setBreadcrumbsData([
            { label: "Home", link: "/" },
            { label: "Tab Access", link: "" }
        ]);
    };

    /**
     * Clears user input fields.
     */
    const clearInputs = () => {
        setUserAccess({ emailId: "", useCase: [] });
        // setGroupNameError("");
        setGroupEmailError("");
        setGroupListError("");
    };

    /**
     * Handles pagination page change.
     * @param {unknown} event - The event that triggered the page change.
     * @param {number} newPage - The new page number.
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    /**
     * Handles change in number of rows per page.
     * @param {React.ChangeEvent<HTMLInputElement>} event - The event containing the new rows per page value.
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**
     * Handles input change in user access input fields.
     * @param {ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserAccess({
            ...userAccess,
            [name]: value
        });
        // if (name === "name") {
        //     setGroupNameError("");
        // }
        if (name === "useCase") {
            setGroupListError("");
        } else if (name === "emailId") {
            setGroupEmailError("");
        }
        console.log(userAccess, "handleInputChange");
    };

    /**
   * Validates user access input fields.
   * @returns {boolean} True if all inputs are valid, false otherwise.
   */
    const validateInputs = () => {
        let isValid = true;
        // if (!userAccess.name.trim()) {
        //     setGroupNameError("Name is required");
        //     isValid = false;
        // }
        if (!userAccess.emailId.trim()) {
            setGroupEmailError("Email is required");
            isValid = false;
        }
        if (!isValidEmail(userAccess.emailId)) {
            setGroupEmailError("Enter valid email");
            isValid = false;
        }
        if (userAccess.useCase.length === 0) {
            setGroupListError("Select use case");
            isValid = false;
        }
        return isValid;
    };
    /**
     * Handles submission of user access form.
     */
    const handleSubmit = async () => {
        setDeleteUserAccess(null);
        if (!validateInputs()) {
            return;
        }
        setModalTitle(`Do you want to ${editingUserAccess ? "update" : "add"} this user access?`);
        setShowModal(true);
    };

    /**
   * Handles saving user access changes.
   */
    const saveUserAccess = async () => {
        console.log(userAccess, "userAccess");
        try {
            setLoader(true);
            const payload = {
                emailId: userAccess.emailId,
                useCases: userAccess.useCase?.map(ug => ug.label) || []
            };
            if (editingUserAccess) {
                await api.post<User>(`https://ltc-coe-genai01-appservice.azurewebsites.net/InsertAccess`, payload);
            } else {
                await api.post<User>("https://ltc-coe-genai01-appservice.azurewebsites.net/InsertAccess", payload);
            }

            showToast(`User access ${editingUserAccess ? "updated" : "added"} successfully`, "success");
            fetchUseCase(); // Refresh the list after save/update
            setBreadcrumbsData([
                { label: "Home", link: "/" },
                { label: "Tab Access", link: "" }
            ]);
            setSearchQuery("");
        } catch (error: any) {
            showToast(`User access ${editingUserAccess ? "updating" : "adding"} failed`, "error");
            console.log(sessionStorage, 'sessionStorage before');
            console.log(error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };

    /**
   * Handles deletion of user access.
   */
    const deleteUseAccess = (user: User) => {
        console.log(user, "users");
        setDeleteUserAccess(user);
        setShowModal(true);
        setModalTitle("Do you want to delete this user access?");
    };

    /**
     * Handles deletion confirmation.
     * @param {any} user - The user data to delete.
     */
    const handleDelete = async () => {
        try {
            setLoader(true);
            const body = {
                emailId: deleteUserAccess.emailId,
                useCases: [deleteUserAccess.useCase]
            };
            console.log(body, "body");

            await api.delete<any>(`https://ltc-coe-genai01-appservice.azurewebsites.net/DeleteAccess`, body);
            fetchUseCase(); // Refresh the list after deletion
            showToast("User access deleted successfully", "success");
            setSearchQuery("");
        } catch (error) {
            showToast("Error deleting user access", "error");
            console.error("Error deleting user group:", error);
        } finally {
            setLoader(false);
        }
    };

    /**
     * Handles editing user access.
     * @param {any} user - The user data to edit.
     */
    const patchValue = async (user: User) => {
        try {
            console.log(user, "user****patchValue***");
            const convertedUseCase = options.find((option: any) => user.useCase === option.label) || {};
            const convertedUser = {
                emailId: user.emailId,
                useCase: [convertedUseCase]
            };
            clearInputs();
            setLoader(true);
            setDeleteUserAccess(null);
            setEditingUserAccess(user);
            setUserAccess(convertedUser);
            scrollToTop();
            setShowAddIcon(false);
            setBreadcrumbsData([
                { label: "Home", link: "/" },
                { label: "Tab Access", link: "" },
                { label: "Edit user access", link: "no-route" }
            ]);
        } catch (error) {
            console.log("Error while patching value");
        } finally {
            setLoader(false);
        }
    };
    /**
     * Handles closing the toast notification.
     */
    const handleCloseToast = () => {
        setOpenToast(false);
    };

    /**
     * Handles closing the modal dialog.
     */
    const handleClose = () => {
        setShowModal(false);
    };

    /**
     * Handles checkbox change in user use cases.
     * @param {any[]} value - The selected checkbox values.
     * @param {string} name - The name of the checkbox group.
     */
    const handleCheckboxChange = (value: any[], name: string) => {
        setUserAccess({
            ...userAccess,
            [name]: value
        });
        setGroupListError("");
        console.log(userAccess, "handleCheckboxChange");
    };
    /**
       * Handles change in the search input for filtering user cases.
       * @param {ChangeEvent<HTMLInputElement>} event - The input change event.
       */
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    /**
     * Memoized filtered user case list based on search query.
     */
    const filteredUserCaseList = useMemo(() => {
        return filterByTableSearch(userCaseList, searchQuery);
    }, [userCaseList, searchQuery]);

    return (
        <div>
            <Layout />

            <Breadcrumbs
                items={breadcrumbsData}
                onClick={item => breadcrumbsClick(item)}
                TypographyProps={{ variant: "body1" }}
                LinkProps={{ underline: "none" }}
                className="p-2 b-bottom pl-4"
            />

            <div className="p-4 pt-0">
                <div className={style.tabAccess__Container}>
                    <strong>User Access</strong>
                    <div className={style.tabAccess__iconContainer}>
                        {!showAddIcon && (
                            <>
                                <Tooltip title={"Save"}>
                                    <img width={24} height={20} className={`${style.circle__plus} ${style.tabAccess__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                                </Tooltip>
                                <Tooltip title="Close">
                                    <HighlightOffIcon className={style.circle__x} onClick={handleRemove} />
                                </Tooltip>
                            </>
                        )}
                        {showAddIcon && (
                            <Tooltip title="Add">
                                <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                                { }
                                {/* <img className={style.tabAccess__curserPointer} src={plusIcon} alt="plusIcon" onClick={handleAdd} /> */}
                            </Tooltip>
                        )}
                      
                    </div>
                </div>
                {!showAddIcon && (
                    <Grid container spacing={2} className={style.tabAccess__inputWrapper}>
                        <Grid item xs={4}>
                            <DropdownWithCheckboxes
                                id="useCase-dropdown"
                                name="useCase"
                                label="Select User Access"
                                options={options as any}
                                helperText={groupListError}
                                value={userAccess.useCase}
                                onChange={(value: any) => handleCheckboxChange(value, "useCase")}
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Input
                                id="username-input"
                                heading="User Name"
                                placeholder="Enter Name"
                                variant="standard"
                                name="name"
                                value={userAccess?.name}
                                helperText={groupNameError}
                                required
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        <Grid item xs={4}>
                            <Input
                                id="username-input"
                                heading="Email Id"
                                placeholder="Enter Email Id"
                                variant="standard"
                                name="emailId"
                                value={userAccess?.emailId}
                                helperText={groupEmailError}
                                required
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                )}
                <CustomTableComponent
                    rows={filteredUserCaseList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    totalCount={filteredUserCaseList.length}
                    handleEdit={patchValue}
                    handleRemove={deleteUseAccess}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    searchQuery={searchQuery}
                    handleSearchChange={handleSearchChange}
                    isEdit={false}
                />
                <LoaderComponent isLoading={loader} />
                <CustomToast open={openToast} message={toastMessage} severity={toastSeverity} onClose={handleCloseToast} />
                <CustomDialog open={showModal} onClose={handleClose} title={modalTitle} actions={actions} />
            </div>
        </div>
    );
}

export default TabAccess;