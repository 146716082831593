// @flow 
import * as React from 'react';
import CustomChart from '../Chart/chart';
export const Dashboard = () => {

    const data = [
        [
            "PPE - instances",
            "Site 1",
            "Site 2",
            "Site 3",
        ],
        ['1-Aug-2024', 37.8, 80.8, 41.8],
        ['2-Aug-2024', 30.9, 69.5, 32.4],
        ['3-Aug-2024', 25.4, 57, 25.7],
        ['4-Aug-2024', 11.7, 18.8, 10.5],
        ['5-Aug-2024', 11.9, 17.6, 10.4],
        ['6-Aug-2024', 8.8, 13.6, 7.7],
        ['7-Aug-2024', 7.6, 12.3, 9.6],
        ['8-Aug-2024', 12.3, 29.2, 10.6],
        ['9-Aug-2024', 16.9, 42.9, 14.8],
        ['10-Aug-2024', 12.8, 30.9, 11.6]
    ];

    const options = {
        chart: {
            title: "Dashboard",
            // subtitle: "",
        },
    };


    return (
        <>
            <CustomChart data={data} options={options} />
        </>
    );
};

export default Dashboard;