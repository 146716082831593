import * as React from 'react';
type Props = {
    fill?: string;
};
export const DeveloperIcon = (props: Props) => {
    const { fill = '#90a3b4' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g id="Group_22527" data-name="Group 22527" transform="translate(-2 -2)">
                    <path id="Path_39603" data-name="Path 39603" d="M2,47.429a1.143,1.143,0,0,0,1.143,1.143H16.3a2.827,2.827,0,0,1-.586-1.714,1.143,1.143,0,0,1,1.143-1.143h.766l-1.677-8.386A5.7,5.7,0,0,0,12.286,36H12.2A2.275,2.275,0,0,1,7.8,36H7.714A5.71,5.71,0,0,0,2,41.714Z" transform="translate(0 -14.571)" fill={fill} />
                    <path id="Path_39604" data-name="Path 39604" d="M16.286,34.063v-1.25a6.165,6.165,0,0,1-2.286,0v1.25a1.143,1.143,0,0,0,2.286,0Z" transform="translate(-5.143 -13.206)" fill={fill} />
                    <path id="Path_39605" data-name="Path 39605" d="M28,55a1.714,1.714,0,0,0,1.714,1.714H43.429A1.714,1.714,0,0,0,45.143,55Z" transform="translate(-11.143 -22.714)" fill={fill} />
                    <path id="Path_39606" data-name="Path 39606" d="M28.571,35a.571.571,0,0,0-.561.686l1.921,9.6h13.28l1.92-9.6a.571.571,0,0,0-.56-.686Zm5.546,6.453a.571.571,0,1,1-.808.808l-1.714-1.714a.571.571,0,0,1,0-.808l1.714-1.714a.571.571,0,1,1,.808.808l-1.31,1.31ZM37.7,38l-1.143,4.571A.571.571,0,0,1,36,43a.581.581,0,0,1-.139-.017.571.571,0,0,1-.416-.693l1.143-4.571A.571.571,0,0,1,37.7,38Zm3.85,2.551-1.714,1.714a.571.571,0,1,1-.808-.808l1.311-1.31-1.31-1.31a.571.571,0,1,1,.808-.808l1.714,1.714a.571.571,0,0,1,0,.808Z" transform="translate(-11.142 -14.143)" fill={fill} />
                    <path id="Path_39607" data-name="Path 39607" d="M4,15.786a1.143,1.143,0,0,0,1.143,1.143.571.571,0,0,1,.571.571A5.143,5.143,0,1,0,16,17.5a.571.571,0,0,1,.571-.571,1.143,1.143,0,0,0,1.143-1.143V14.071a.571.571,0,1,0-1.143,0,.571.571,0,0,1-1.143,0,1.286,1.286,0,0,0-1.1-1.273,6.629,6.629,0,0,1-3.473-1.3,6.629,6.629,0,0,1-3.473,1.3,1.286,1.286,0,0,0-1.1,1.271h0a.571.571,0,0,1-1.143,0h0a.571.571,0,1,0-1.143,0Zm4.229,2.971a.571.571,0,0,1,.8.114,2.286,2.286,0,0,0,3.657,0,.571.571,0,0,1,.914.686,3.429,3.429,0,0,1-5.486,0A.571.571,0,0,1,8.229,18.757Z" transform="translate(-0.857 -4.071)" fill={fill} />
                    <path id="Path_39608" data-name="Path 39608" d="M4,8.391a1.673,1.673,0,0,1,1.579.223A2.412,2.412,0,0,1,7.227,7.6a5.633,5.633,0,0,0,2.887-1.036,1.115,1.115,0,0,1,1.49,0A5.627,5.627,0,0,0,14.489,7.6a2.412,2.412,0,0,1,1.648,1.018,1.673,1.673,0,0,1,1.577-.223V7.143a2.861,2.861,0,0,0-2.857-2.857h-.571a.571.571,0,0,1-.4-.975l.9-.9A.238.238,0,0,0,14.614,2H9.143A5.149,5.149,0,0,0,4,7.143Z" transform="translate(-0.857)" fill={fill} />
                </g>
            </svg>
        </>
    );
};