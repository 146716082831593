import React, { ChangeEventHandler } from "react";
import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from "@mui/material";
import "./Checkbox.module.css";

type TlabelPlacement = 'top' | 'start' | 'bottom' | 'end';

type TCheckboxComponentProps = {
    label?: string;
    labelPlacement?: TlabelPlacement;
    onChange?: ChangeEventHandler<any>;
    checked?: boolean;
}

const CheckboxComponent: React.FC<CheckboxProps & TCheckboxComponentProps> = ({ label, checked, labelPlacement, onChange, ...props }) => {
    const handleChange: ChangeEventHandler<any> = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <FormControlLabel
            labelPlacement={labelPlacement}
            style={{ color: "rgba(0, 0, 0, 0.6)", display: "block" }}
            control={
                <Checkbox
                    checked={checked}
                    {...props}
                    onChange={handleChange}
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        '&.Mui-disabled': {
                            color: "rgba(0, 0, 0, 0.6) !important",
                            '& .MuiSvgIcon-root': {
                                color: "rgba(0, 0, 0, 0.6) !important"
                            }
                        }
                    }}
                />
            }
            label={label}
            sx={{
                '& .MuiTypography-root': {
                    color: "rgba(0, 0, 0, 0.6) !important"
                },
                '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: "rgba(0, 0, 0, 0.6) !important"
                }
            }}
        />
    );
};

export default CheckboxComponent;
