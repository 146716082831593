import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "../../assets/Table/Edit.svg";
import DeleteIcon from "../../assets/Table/Delete.svg";
import { Column, RowData, TableProps } from "./type";
import styles from "./Table.module.css"
import { Tooltip, TablePagination, TextField } from "@mui/material";
import Input from "../../design system/Input";
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3b71ca",
        color: theme.palette.common.white,
        padding: '10px 16px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '10px 16px'
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

export default function CustomTableComponent({
    rows,
    handleEdit,
    handleRemove,
    handleView,
    columns,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    rowsPerPageOptions,
    searchQuery,
    handleSearchChange,
    isEdit,
    hideAction = false
}: TableProps) {

    return (
        <>
            <div className={styles.searchBox}>
                <Input
                    id="search-box"
                    placeholder="Search"
                    variant="standard"
                    name="name"
                    className={styles.inputSearch}
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

            <TableContainer component={Paper} className={styles.tableContainer} sx={{ maxHeight: 336, boxShadow: '0px 4px 10px #bbb' }}>
                <Table sx={{ minWidth: 700 }} stickyHeader aria-label="customized table">
                    <TableHead>
                        <StyledTableRow>
                            {columns.map((column: Column) => (
                                <StyledTableCell key={column.id} align={column.align}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 ? rows.map((row: RowData) => (
                            <StyledTableRow key={row.id}>
                                {columns.map((column: Column) => {
                                    if (column.id === "action" || column.id === "view_action") {
                                        return (
                                            <StyledTableCell key={column.id} align="center">
                                                <span className={styles.deleteEditIcon}>
                                                    {(column.id === "view_action" && handleView) &&
                                                        <div className={styles.pointer}>
                                                            <Tooltip title="View">
                                                                <CenterFocusStrongOutlinedIcon style={{ color: "#198754" }} onClick={() => handleView(row)} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    <div className={styles.pointer}>
                                                        <Tooltip title="Delete">
                                                            <img src={DeleteIcon} alt="deleteIcon" onClick={() => handleRemove(row)} />
                                                        </Tooltip>
                                                    </div>
                                                    <div className={styles.pointer}>
                                                        {isEdit ? (
                                                            <Tooltip title="Edit">
                                                                <img src={EditIcon} alt="editIcon" onClick={() => handleEdit(row)} />
                                                            </Tooltip>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </span>
                                            </StyledTableCell>
                                        );
                                    }
                                    if (column?.group === true && column?.type !== "object") {
                                        return (
                                            <StyledTableCell key={column.id} sx={{ maxWidth: "200px" }}>
                                                {column?.group && Array.isArray(row[column.id])
                                                    ? row[column.id].join(", ")
                                                    : row[column.id]}
                                            </StyledTableCell>
                                        );
                                    }
                                    if (column?.group === true && column?.type == "object") {
                                        return (
                                            <StyledTableCell key={column.id} sx={{ maxWidth: "200px" }}>
                                                {column?.group && Array.isArray(row[column.id])
                                                    ? row[column.id]?.map(((list: any) => list.label)).join(", ")
                                                    : row[column.id]}
                                            </StyledTableCell>
                                        );
                                    }
                                    if (column.id === "superAdmin") {
                                        return (
                                            <StyledTableCell key={column.id} sx={{ maxWidth: "200px" }}>
                                                <Tooltip title={row[column.id] ? "Super Admin" : "Admin"}>
                                                    {row[column.id] ? <DoneSharpIcon style={{ cursor: 'pointer' }} color="success" /> : <CloseSharpIcon style={{ cursor: 'pointer' }} color="error" />}
                                                </Tooltip>
                                            </StyledTableCell>
                                        );
                                    }
                                    return <StyledTableCell key={column.id}>{row[column.id]}</StyledTableCell>;
                                })}
                            </StyledTableRow>
                        )) : <StyledTableRow>
                            <StyledTableCell colSpan={12} align="center">
                                No data found
                            </StyledTableCell>
                        </StyledTableRow>}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}