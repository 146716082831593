import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./customIframe.module.css"
import { useEffect, useState } from "react";
import { LoaderComponent } from "./visionPlatform/loader/loader";
import { FINANCE_IFRAME_URL, HR_IFRAME_URL, SCM_IFRAME_URL } from "../constants";

interface IProps {
    backUrl: string
}
export const IframeComponent = ({ backUrl = "" }: IProps) => {
    const [isLoading, setLoading] = useState(true);
    const [iframeUrl, setIframeUrl] = useState("");

    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;

        switch (pathname) {
            case "/scm":
                setIframeUrl(SCM_IFRAME_URL)
                break;
            case "/finance":
                setIframeUrl(FINANCE_IFRAME_URL)
                break;
            case "/HR":
                setIframeUrl(HR_IFRAME_URL);
                break;
        }
    }, []);

    const handleIframeLoad = () => {
        setLoading(false);
    };
    const handleIframeLoadStart = () => {
        setLoading(true);
    };
    return (
        <>
            <Link to={`/${backUrl}`} className={`btn btn-primary ${styles.backbtn}`} >Back</ Link>
            <iframe className={styles.iframeContainer}
                src={iframeUrl}
                title="Intractive Dashboard"
                onLoad={handleIframeLoad}
                onLoadStart={handleIframeLoadStart}
            />
            <LoaderComponent isLoading={isLoading} />
        </>
    );
};
export default IframeComponent; 