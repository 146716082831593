import React, { useState } from 'react';
import { AdminContext } from './admin.context';

const AdminContextProvider = ({ children }: any) => {
  const [isLoggedInUser, setIsLoggedIn] = useState(false);
  const [breadCrumb, setBreadcrumbs] = useState<any[]>([]);
  const [actions, setActions] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastSeverity, setToastSeverity] = useState<any>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [deleteId, setDeleteId] = useState<any>(null);
  const [stream, setStream] = useState("");

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  const value = {
    breadCrumb,
    setBreadcrumbs,
    actions,
    setActions,
    loader,
    setLoader,
    openToast,
    setOpenToast,
    toastMessage,
    setToastMessage,
    toastSeverity,
    setToastSeverity,
    showModal,
    setShowModal,
    modalTitle,
    setModalTitle,
    deleteId,
    setDeleteId,
    isLoggedInUser,
    login,
    logout,
    stream,
    setStream
  };

  return (
    <AdminContext.Provider value={value}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
