// EventBus.js
import { EventEmitter } from "events";

const eventBus = new EventEmitter();
eventBus.setMaxListeners(100);
export const emitEvent = (eventName, data) => {
    eventBus.emit(eventName, data);
};

export const subscribeToEvent = (eventName, callback) => {
    eventBus.on(eventName, callback);
};

export const unsubscribeFromEvent = (eventName, callback) => {
    eventBus.removeListener(eventName, callback);
};

export default eventBus;
