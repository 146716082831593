import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import style from "./userGroup.module.css"
import CustomDialog from "../../../design system/Dialog/Index";
import AutocompleteAtom from "../../../design system/AutoComplete";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { IListOptions, IUserGroup, TAction, User } from "../type";
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const SERVER_URL_USER_GROUP = `https://6695efd70312447373c079e7.mockapi.io/api/v1/users/userGroup`;
const SERVER_URL = `https://6695efd70312447373c079e7.mockapi.io/api/v1/users/users`;
export const UserGroup = () => {
    const [userGroupList, setUserGroupList] = useState<IUserGroup[]>([]);
    const [listOptions, setListOptions] = useState<IListOptions[]>([]);
    const [editingUser, setEditingUserGroup] = useState<IUserGroup | null>(null);
    const [deleteUserId, setDeleteUserId] = useState<any>(null);
    const [userGroup, setUserGroup] = useState<IUserGroup>({ groupName: "", groupList: [] });
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [groupNameError, setGroupNameError] = useState<string>('');
    const [groupListError, setGroupListError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState("");
    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        { id: 'id', label: 'Id' },
        { id: 'groupName', label: 'Name' },
        { id: 'groupList', label: 'Group List', group: true },
        { id: 'action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            },
            variant: 'contained',
            color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                if (deleteUserId) {
                    deleteUserGroup()
                } else if (editingUser || userGroup) {
                    saveUserGroup()
                }
            }, variant: 'contained', color: 'success'
        },
    ];
    useEffect(() => {
        fetchUsers();
        fetchUserGroup();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);
    const fetchUsers = async () => {
        try {
            setLoader(true)
            const data: User[] = await api.get<User[]>(SERVER_URL);
            const convertToOption = data.map((user: User) => {
                return {
                    id: user.id,
                    label: user?.name
                }
            });
            console.log(convertToOption, 'convertToOption');
            setListOptions(convertToOption)
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users & user group:', error);
        } finally {
            setLoader(false);
        }
    };
    const fetchUserGroup = async () => {
        try {
            setLoader(true)
            const userGroup: any[] = await api.get<User[]>(SERVER_URL_USER_GROUP);
            const convert = userGroup?.map((grp: any) => ({ id: grp.id, groupName: grp.groupName, groupList: grp.groupList.map((user: any) => user.label) }));
            setUserGroupList(convert)
        } catch (error) {
            showToast("Error fetching user group list", "error");
            console.error('Error fetching user group:', error);
        } finally {
            setLoader(false);
        }
    };
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        console.log({ name, value, userGroup });
        setUserGroup({
            ...userGroup,
            [name]: value
        });
        if (name === 'groupName') {
            setGroupNameError('');
        } else if (name === 'groupList') {
            setGroupListError('');
        }
    };
    const handleSubmit = async () => {
        let inValid = false
        if (userGroup.groupName === "") {
            setGroupNameError('user group name required')
            inValid = true
        }
        if (userGroup?.groupList.length === 0) {
            setGroupListError('please select users')
            inValid = true
        }
        if (inValid) return
        setShowModal(true)
        setModalTitle(`Are you wanted to ${editingUser ? 'update' : 'add'} this user group?`)
    };
    const saveUserGroup = async () => {
        try {
            setLoader(true);
            if (editingUser) {
                await api.put<User>(`${SERVER_URL_USER_GROUP}/${editingUser?.id}`, userGroup);
                setEditingUserGroup(null);
            } else {
                await api.post<User>(SERVER_URL_USER_GROUP, userGroup);
            }
            updateBreadcrumbList(true, "")
            await fetchUsers();
            await fetchUserGroup();
            clearInputs();
            setSearchQuery("");
            showToast(`User group ${editingUser ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`User group ${editingUser ? 'updating' : 'adding'} failed`, 'error');
            console.error('Error adding user:', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = async (user: User) => {
        try {
            clearInputs()
            setDeleteUserId(null)
            setLoader(true);
            const response: IUserGroup = await api.get(`${SERVER_URL_USER_GROUP}/${user?.id}`);
            console.log(response, 'response while edit');
            setEditingUserGroup(response);
            setUserGroup(response);
            scrollToTop();
            updateBreadcrumbList(false, "Edit User Group")
            setShowAddIcon(false);
        } catch (error) {
            console.log('Error while patching value');
        } finally {
            setLoader(false);
        }
    };
    const handleDelete = (user: User) => {
        console.log('delete user group', user);
        setDeleteUserId(user?.id)
        setModalTitle("Are you wanted to delete this user group?")
        setShowModal(true)
    }
    const deleteUserGroup = async () => {
        try {
            setLoader(true);
            await api.remove<User>(`${SERVER_URL_USER_GROUP}/${deleteUserId}`);
            fetchUsers();
            fetchUserGroup();
            clearInputs();
            showToast('User group deleted successfully', 'success');
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast('Error deleting user group', 'error');
            console.error('Error deleting user group:', error);
        } finally {
            setLoader(false);
        }
    };
    const updateBreadcrumbList = (rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    }
    const clearInputs = () => {
        setUserGroup({ groupName: '', groupList: [] });
        setGroupNameError('');
        setGroupListError('');
    };
    const handleRemove = () => {
        clearInputs();
        setShowAddIcon(true);
        setEditingUserGroup(null)
        updateBreadcrumbList(true, "")
        setSearchQuery("");
    };
    const handleAdd = () => {
        setShowAddIcon(false);
        setDeleteUserId(null)
        updateBreadcrumbList(false, "Add User Group")
        setSearchQuery("");
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };
    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }
    const handleClose = () => {
        setShowModal(false);
    };
    const handleChange = (event: React.SyntheticEvent, value: any[]) => {
        console.log(value, 'value');
        const convertIntoUserGroup = value?.map((userGrp: any) => userGrp?.label) || [];
        setUserGroup({
            ...userGroup,
            groupList: value
        });
        setGroupListError("")
        console.log('convertIntoUserGroup:', convertIntoUserGroup, userGroup);
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const filteredUserGroupList = useMemo(() => {
        return filterByTableSearch(userGroupList, searchQuery);
    }, [userGroupList, searchQuery]);
    return (
        <>
            <div className={style.userGroup__container}>
                <strong>User Group</strong>
                <div className={style.userGroup__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editingUser ? 'Update' : "Save"}>
                                <img width={24} height={20} className={`${style.userGroup__curserPointer} ${style.userGroup__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.userGroup__curserPointer} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add">
                            { }
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.user__curserPointer} />
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.userGroup__inputWrapper}>
                    <Grid item xs={4} >
                        <Input
                            id="username-input"
                            heading="User Name"
                            placeholder="Enter UserGroup Name"
                            variant="standard"
                            name="groupName"
                            value={userGroup.groupName}
                            helperText={groupNameError}
                            required
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutocompleteAtom
                            id="tags-standard"
                            className="custom_autoSelect"
                            options={listOptions}
                            multiple
                            defaultValue={userGroup.groupList}
                            helperText={groupListError}
                            placeholder="Select Users"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredUserGroupList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredUserGroupList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
        </>
    );
};
export default UserGroup; 