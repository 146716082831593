import React, { useContext, useEffect, useState } from "react";
import style from "./visionPlatform.module.css";
import { ListGroup } from "react-bootstrap";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout/Layout";
import { DashboardIcon } from "./assets/Dashboard";
import { SettingIcon } from "./assets/settings";
import { UserIcon } from "./assets/User";
import { HistoryIcon } from "./assets/history";
import { CameraIcon } from "./assets/Camera";
import { DeveloperIcon } from "./assets/Developer";
import { ProjectIcon } from "./assets/Project";
import Breadcrumbs from "../../design system/Breadcrumbs";
import { LoaderComponent } from "./loader/loader";
import CustomToast from "../../design system/Toast";
import { AdminContext } from "../../context/admin.context";
import { BreadcrumbItem } from "../../design system/Breadcrumbs/type";
import { MenuList } from "./type";
import { useErrorHandling } from "../../context/errorHandlingContext";
import ApiHelper from "../../api/ApiHelper";
import { ACCESS_API_URL, setUserRole } from "./vision.constant";

const MENU_LIST: MenuList[] = [
    {
        id: 1,
        MenuIcon: DashboardIcon,
        route: "",
        text: "Dashboard"
    },
    {
        id: 2,
        MenuIcon: SettingIcon,
        route: "live",
        text: "Live"
    },
    {
        id: 3,
        MenuIcon: UserIcon,
        route: "users",
        text: "Users"
    },
    {
        id: 4,
        MenuIcon: ProjectIcon,
        route: "organization",
        text: "Organization"
    },
    {
        id: 5,
        MenuIcon: ProjectIcon,
        route: "projects",
        text: "Sites"
    },
    {
        id: 6,
        MenuIcon: CameraIcon,
        route: "cctv",
        text: "Camera"
    },
    {
        id: 7,
        MenuIcon: DeveloperIcon,
        route: "developer",
        text: "Algorithm"
    }
];

/**
 * VisionPlatform component provides the main admin portal UI.
 */
export const VisionPlatform: React.FC = () => {
    const [selectedItem, setSelectedItem] = useState<number>(1);
    const [access, setAcess] = useState({ is_superAdmin: false, org_admin: [], project_admin: [] });
    const [mouseHoverId, SetMouseHoverId] = useState<number | null>(null);
    const [menuList, setMenuList] = useState<any | null>([]);
    const location = useLocation();
    const { breadCrumb, setBreadcrumbs, setOpenToast, setLoader, loader, openToast, toastMessage, setToastMessage, setToastSeverity, toastSeverity } =
        useContext(AdminContext); // Admin context for breadcrumbs and toast

    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const navigate = useNavigate();

    // List of menu items with icons and routes

    useEffect(() => {
        // Effect to set initial breadcrumb and selected menu item based on URL

        const intial = async () => {
            await fetchAccess();
            const { pathname } = location;
            const [initial = "", adminPath = "", menuPath = "", subMenuPath = ""] = pathname?.split("/") || [];
            const { route = "", id = 1, text = "Dashboard" } = menuList.find((menu: any) => menu.route === menuPath) || {};

            console.log(pathname, "pathname");
            setSelectedItem(id);
            setBreadcrumbs([
                { label: "Home", link: "/" },
                { label: "Admin portal", link: "" },
                { label: text, link: route }
            ]);
        };
        intial();
    }, []);

    const fetchAccess = async () => {
        const activeUser = sessionStorage.getItem("email") ?? "";
        try {
            setLoader(true);
            const data: any = await api.post<any>(`${ACCESS_API_URL}`, { email: activeUser });
            console.log(data?.response, "data?.response");
            setAcess(data?.response);
            const { is_superAdmin = false, org_admin = [], project_admin = [] } = data?.response || {};
            let type = "";
            if (is_superAdmin) {
                type = "super_admin";
            } else if (org_admin.length > 0) {
                type = "org_admin";
            } else if (project_admin.length > 0) {
                type = "site_admin";
            }
            setUserRole(type); // setting user role here..
            permissionBasedMenu(type);
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error("Error fetching users:", error);
        } finally {
            setLoader(false);
        }
    };

    const permissionBasedMenu = (access: string) => {
        switch (access) {
            case "super_admin":
                setMenuList(MENU_LIST);
                break;
            case "org_admin":
                const org_menu = MENU_LIST?.filter(menu => menu.text !== "Organization" && menu.text !== "Algorithm");
                setMenuList(org_menu);
                break;
            case "site_admin":
                const site_menu = MENU_LIST?.filter(
                    menu => menu.text !== "Users" && menu.text !== "Sites" && menu.text !== "Organization" && menu.text !== "Algorithm"
                );
                setMenuList(site_menu);
                break;

            default:
                setMenuList(MENU_LIST);
                break;
        }
    };
    const showToast = (message: string, severity: "error" | "warning" | "info" | "success") => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    };
    /**
     * @description Handles click on list item in the menu.
     * @param route - Route to navigate to.
     * @param id - ID of the menu item.
     * @param text - Text label of the menu item.
     */

    const handleListItemClick = (route: string, id: number, text: string) => {
        setSelectedItem(id);
        navigate(route);
        setBreadcrumbs([
            { label: "Home", link: "/" },
            { label: "Admin portal", link: "" },
            { label: text, link: route }
        ]);
    };

    /**
     * @description Handles click on breadcrumb items.
     * @param item - Breadcrumb item object.
     */

    const breadcrumbsClick = (item: BreadcrumbItem) => {
        // set active menu and navigate to initial state
        if (item?.label === "Admin portal") {
            handleListItemClick("", 1, "Dashboard");
        }
        //last link no need to navigate the route is same just disable the route
        if (item.link === "no-route") return;

        // if admin portal we have to navigate to intial dashboard
        if (item?.link || item.link === "") {
            navigate(item.link);
        }
    };

    /**
     * @description Handles mouse enter event on list item.
     * @param menuId - ID of the menu item.
     */

    const onMouseEnter = (menuId: number) => {
        SetMouseHoverId(menuId);
    };

    /**
     * @description Handles mouse leave event on list item.
     * @param menuId - ID of the menu item.
     */

    const onMouseLeave = (menuId: number) => {
        SetMouseHoverId(null);
    };

    /**
     * @description Closes the toast message.
     */
    const handleCloseToast = () => {
        setOpenToast(false);
    };

    return (
        <>
            <Layout />
            <div className="d-flex">
                <aside className={style.main__container__aside}>
                    <ListGroup>
                        {menuList.map(({ id, text, route, MenuIcon }: any) => {
                            return (
                                <ListGroup.Item
                                    onMouseEnter={() => onMouseEnter(id)}
                                    onMouseLeave={() => onMouseLeave(id)}
                                    onClick={() => handleListItemClick(route, id, text)}
                                    key={id}
                                    className={
                                        selectedItem === id || mouseHoverId === id
                                            ? style.main__container__ListGroup__selectedItem
                                            : style.main__container__ListGroup__item
                                    }
                                >
                                    <i className={style.header__icon}>
                                        <MenuIcon fill={selectedItem === id || mouseHoverId === id ? "#ffff" : "#90a3b4"} />
                                    </i>
                                    <span className={style.menu__text}>{text}</span>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </aside>
                <main className={style.main__container__content}>
                    <Breadcrumbs
                        items={breadCrumb}
                        onClick={item => breadcrumbsClick(item)}
                        TypographyProps={{ variant: "body1" }}
                        LinkProps={{ underline: "none" }}
                        className="p-2 b-bottom pl-4"
                    />

                    <LoaderComponent isLoading={loader} />
                    <CustomToast open={openToast} message={toastMessage} severity={toastSeverity} onClose={handleCloseToast} />
                    <div className={style.outletStyle}>
                        <Outlet />
                    </div>
                </main>
            </div>
        </>
    );
};

export default VisionPlatform;
