import React, { useMemo, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Chip, SelectChangeEvent, TextField } from "@mui/material";
import "./DropdownCheckbox.css";
import { DropdownWithCheckboxesProps } from "./type";
import { Options16Regular } from "@fluentui/react-icons";

const DropdownWithCheckboxes: React.FC<DropdownWithCheckboxesProps> = ({
    id,
    name,
    label,
    options = [],
    value = [],
    onChange,
    helperText,
    placeholder,
    disabled,
    multiple = true
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredOptions = useMemo(() => {
        return Array.isArray(options) ? options.filter(option => {
            const label = option.label || "";
            const normalizedLabel = label.normalize('NFKC').toLowerCase();
            const searchNormalized = searchTerm.normalize('NFKC').toLowerCase();
            // console.log({ normalizedLabel, searchNormalized });

            if (normalizedLabel.includes(searchNormalized)) {
                return true;
            }
            return false
        }) : []
    }, [options, searchTerm]);

    const handleChange = (event: SelectChangeEvent<any>) => {
        const { target: { value } } = event;
        const selectedValues = multiple ? value : [value];
        // console.log({ selectedValues, options, value }, 'selectedValues');
        let selectedOptions;
        if (multiple) {
            selectedOptions = options?.filter(option => selectedValues?.includes(option.id));
        } else {
            selectedOptions = options?.filter(option => (selectedValues == option.id));
        }

        onChange(selectedOptions);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { value } } = event;
        // console.log(value, 'value****', searchTerm);
        setSearchTerm(value);
    };

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel id={`${id}-label`} shrink>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                placeholder={placeholder}
                id={id}
                multiple={multiple}
                disabled={disabled}
                value={value.map(option => option.id)}
                onChange={handleChange}
                renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 4, maxWidth: 300, overflowX: "auto", maxHeight: 70 }}>
                        {(selected as number[]).map(id => (
                            <Chip key={id} label={options.find(option => option.id === id)?.label} />
                        ))}
                    </div>
                )}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 300,
                            width: 250,
                        },
                    },
                }}
            >
                {/* <div style={{ padding: 8 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Search..."
                        value={searchTerm}
                        onInput={handleSearchChange}
                    />
                </div> */}
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {multiple && <Checkbox checked={value.some(v => v.id === option.id)} />}
                        <ListItemText primary={option.label} sx={{ overflowX: "auto" }} />
                    </MenuItem>
                ))}
            </Select>
            <span className="helperTextDropdown">{helperText}</span>
        </FormControl>
    );
};

export default DropdownWithCheckboxes;