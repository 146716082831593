import React, { useRef, useEffect, useState, useContext } from "react";
import { ImageUpload_Axios } from "../utils/API";
import { useParams } from "react-router-dom";
import loading from "../../assets/loading.gif";
import image1 from "../../assets/Safety Image.jpg";
import image2 from "../../assets/cover-image.jpg";
import styles from "../../pages/layout/Layout.module.css";
import { VISION_AI_URL } from "../../constants";
import { Label } from "@fluentui/react";

export default function ImgUpload() {
    const { AppId } = useParams();
    // const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [displayImg, setdisplayImg] = useState("False");
    const [IsSampleImg, setIsSampleImg] = useState("False");
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [imageSrc, setImageSrc] = useState<any>("");
    const [jsonData, setJsonData] = useState<any>(null);
    const [selectedLabel, setSelectedLabel] = useState<any>(null);
    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    // const hardhatButtonRef = useRef<HTMLButtonElement>(null);
    // const handleUpload = async () => {
    //     setIsCustomloading(true);
    //     let { imageUrl, jsonData } = await ImageUpload_Axios(selectedFile, `${VISION_AI_URL}/demo/image_upload`, AppId);
    //     // alert(jsonData);

    //     setImageSrc(imageUrl);
    //     setJsonData(jsonData);
    //     setIsCustomloading(false);
    // };
    const handleSampleImageClick = async (imageVar: string) => {
        try {
            resetView();
            const response = await fetch(imageVar);
            if (!response.ok) {
                throw new Error("Failed to fetch image");
            }
            const blob = await response.blob();
            const file = new File([blob], "sample_image.jpg", { type: "image/jpeg" });
            setIsSampleImg("True");
            handleFileChange(file);

            // Run the algorithm and set the label
            const uploadResponse = await handleUpload(file);
            if (uploadResponse) {
                setJsonData(uploadResponse.jsonData);
                setImageSrc(uploadResponse.imageUrl);
            }
            await handleLabelClick("Hardhat");
            const uploadResponse1 = await handleUpload(file);
            if (uploadResponse1) {
                setJsonData(uploadResponse1.jsonData);
                setImageSrc(uploadResponse1.imageUrl);
            }

            // await setAndClickLabel("Hardhat");
        } catch (error) {
            console.error("Error fetching image:", error);
        }
    };
    // const setAndClickLabel = async(label: string) => {
    //     setSelectedLabel(label);
    // };
    // useEffect(() => {
    //     if (selectedLabel === "Hardhat" && hardhatButtonRef.current) {
    //         hardhatButtonRef.current.click();
    //     }
    // }, [selectedLabel]);

    const handleUpload = async (file: any) => {
        setIsCustomloading(true);
        try {
            const response = await ImageUpload_Axios(file, `${VISION_AI_URL}/demo/image_upload`, AppId);
            setImageSrc(response.imageUrl);
            setJsonData(response.jsonData);
            return response; // Return response to use immediately
        } catch (error) {
            console.error("Error uploading image:", error);
        } finally {
            setIsCustomloading(false);
        }
    };
    // const handleSampleImageClick = async (imageVar:string) => {
    //     try {
    //         resetView();
    //         const response = await fetch(imageVar);
    //         if (!response.ok) {
    //             throw new Error("Failed to fetch image");
    //         }
    //         const blob = await response.blob();
    //         const file = new File([blob], "sample_image.jpg", { type: "image/jpeg" });
    //         setIsSampleImg("True");
    //         handleFileChange(file);

    //         // Run the algorithm and set the label
    //         await handleUpload(file);
    //         await handleLabelClick("Hardhat");
    //     } catch (error) {
    //         console.error("Error fetching image:", error);
    //     }
    // };

    // const handleUpload = async (file:any) => {
    //     setIsCustomloading(true);
    //     try {
    //         let { imageUrl, jsonData } = await ImageUpload_Axios(file, `${VISION_AI_URL}/demo/image_upload`, AppId);
    //         setImageSrc(imageUrl);
    //         await setJsonData(jsonData);
    //     } catch (error) {
    //         console.error("Error uploading image:", error);
    //     } finally {
    //         setIsCustomloading(false);
    //     }
    // };

    const resetView = async () => {
        setdisplayImg("False");
        setImagePreview("");
        setImageSrc(" ");
        setImageSrc(null);
        setJsonData(null);
        setSelectedLabel(null);
    };

    const getdisplayleft = (calculatedleft: any) => {
        let ele: any = document.getElementById("outputimage");
        let naturalWidth = ele && ele.naturalWidth;
        let displayWidth = ele && ele.width;
        let calculateddisplayleft = (calculatedleft / naturalWidth) * displayWidth;
        return calculateddisplayleft;
    };

    const getdisplaytop = (calculatedtop: any) => {
        let ele: any = document.getElementById("outputimage");
        let naturalHeight = ele && ele.naturalHeight;
        let displayHeight = ele && ele.height;
        let calculateddisplaytop = (calculatedtop / naturalHeight) * displayHeight;
        return calculateddisplaytop;
    };

    const getdisplaywidth = (calculatedwidth: any) => {
        let ele: any = document.getElementById("outputimage");
        let naturalWidth = ele && ele.naturalWidth;
        let displayWidth = ele && ele.width;
        let calculateddisplaywidth = (calculatedwidth / naturalWidth) * displayWidth;
        return calculateddisplaywidth;
    };

    const getdisplayheight = (calculatedheight: any) => {
        let ele: any = document.getElementById("outputimage");
        let naturalHeight = ele && ele.naturalHeight;
        let displayHeight = ele && ele.height;
        let calculateddisplayheight = (calculatedheight / naturalHeight) * displayHeight;
        return calculateddisplayheight;
    };

    const handleLabelClick = async (label: any) => {
        setSelectedLabel(label);
    };
    // const handleSampleImageClick = async (imageSrc: string) => {
    //     try {
    //       const response = await fetch(imageSrc);
    //       if (!response.ok) {
    //         throw new Error('Failed to fetch image');
    //       }
    //       const blob = await response.blob();
    //       const file = new File([blob], 'sample_image.jpg', { type: 'image/jpeg' });
    //       handleFileChange(file);
    //     } catch (error) {
    //       console.error('Error fetching image:', error);
    //     }
    //   };

    // const handleFileChange = (e:any) => {

    //     setSelectedFile(e.target.files[0]);
    //     const file = e.target.files[0];
    //     if (file) {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setImagePreview(reader.result);
    //       };
    //       reader.readAsDataURL(file);
    //       setdisplayImg("True")
    //     }

    //   };

    // const handleSampleImageClick = async (imageVar: string) => {
    //     try {
    //         resetView();
    //         const response = await fetch(imageVar);
    //         if (!response.ok) {
    //             throw new Error("Failed to fetch image");
    //         }
    //         const blob = await response.blob();
    //         const file = new File([blob], "sample_image.jpg", { type: "image/jpeg" });
    //         setIsSampleImg("True");
    //         handleFileChange(file);

    //     } catch (error) {
    //         console.error("Error fetching image:", error);
    //     }
    // };

    // const handleSampleImageClick = async (imageVar:string) => {
    //     try {
    //         resetView();
    //         const response = await fetch(imageVar);
    //         if (!response.ok) {
    //             throw new Error("Failed to fetch image");
    //         }
    //         const blob = await response.blob();
    //         const file = new File([blob], "sample_image.jpg", { type: "image/jpeg" });
    //         setIsSampleImg("True");
    //         handleFileChange(file);

    //         await Promise.all([handleUpload(file), setSelectedLabel("Hardhat")]);
    //     } catch (error) {
    //         console.error("Error fetching image:", error);
    //     }
    // };
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setWindowWidth(window.innerWidth);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleFileChange = (file?: File) => {
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setdisplayImg("True");
        }
    };
    //API
    //URL --> API.jsx
    if (displayImg == "False") {
        return (
            <>
                <h5 className="text-xl font-bold my-2">Sample Images</h5>
                <div style={{ display: "inline-flex", paddingLeft: "13px" }}>
                    <img
                        src={image1}
                        alt="Sample 1"
                        style={{ width: "8%", marginRight: "10px", cursor: "pointer" }}
                        onClick={() => handleSampleImageClick(image1)}
                    />
                    <img
                        src={image2}
                        alt="Sample 2"
                        style={{ width: "8%", marginRight: "10px", cursor: "pointer" }}
                        onClick={() => handleSampleImageClick(image2)}
                    />
                </div>
                <img
                    src={loading}
                    style={{ display: isCustomloading ? "block" : "none", width: "70px", position: "fixed", top: "50%", left: "50%", zIndex: "99999" }}
                />
                <h5 className="text-xl font-bold my-2" >Upload Images</h5>
                <div className="flex items-center justify-center w-full">
                    <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 "
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 ">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs text-gray-500">PNG, JPG Format</p>
                        </div>
                        <input
                            id="dropzone-file"
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            onChange={e => handleFileChange(e.target.files?.[0])}
                            // onChange={handleFileChange}
                        />
                    </label>
                    {/* <button onClick={handleUpload}>Upload</button> */}
                </div>
            </>
        );
    } else {
        return (
            <>
                <h5 className="text-xl font-bold my-2">Sample Images</h5>
                <div style={{ display: "inline-flex" }}>
                    <div style={{ display: "inline-flex", maxHeight: "58px", width: "50%", paddingLeft: "13px" }}>
                        <img
                            src={image1}
                            alt="Sample 1"
                            style={{ width: "16%", marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleSampleImageClick(image1)}
                        />
                        <img src={image2} alt="Sample 2" style={{ width: "16%", cursor: "pointer" }} onClick={() => handleSampleImageClick(image2)} />
                    </div>
                    <div>
                       

                        {/* {Object.keys(jsonData).includes("Hardhat") && (
                <button
                    onClick={() => handleLabelClick("Hardhat")}
                    ref={hardhatButtonRef}
                    className="text-gray-900 bg-gray-100 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                >
                    Hardhat
                </button>
                
            )} */}
 <h6 className="text-xl font-bold my-2" style={{display : jsonData?"block":"none"}}>Predictions</h6>
                        {jsonData &&
                            Object.keys(jsonData).map((label, index) => (
                                
                                <button
                                    key={index}
                                    onClick={() => handleLabelClick(label)}
                                    // className="text-gray-900 bg-gray-100 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 me-2 mb-2 "
                                    // ref={label === 'Hardhat' ? hardhatButtonRef : null}
                                    className={`btn btn-outline-primary ${styles.imgbutton}  ${selectedLabel === label ? 'active' : ''}`}
                                >
                                    {label}
                                </button>
                            ))}
                    </div>
                </div>
                <img
                    src={loading}
                    style={{ display: isCustomloading ? "block" : "none", width: "70px", position: "fixed", top: "50%", left: "50%", zIndex: "99999" }}
                />
                <h5 className="text-xl font-bold my-2">Upload Images</h5>
                <div className="flex">
                    <div className="w-1/2 m-2">
                        <img src={imagePreview} alt="Preview" />
                        <button
                            // onClick={handleUpload}
                            onClick={() => handleUpload(selectedFile)}
                            className="mx-2 my-2 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 "
                        >
                            Run Algorithm
                        </button>
                        <button
                            onClick={resetView}
                            type="button"
                            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        >
                            Reset
                        </button>
                    </div>
                    <div className="w-1/2 m-2">
                        {imageSrc && jsonData ? (
                            <>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <img src={imageSrc} id="outputimage" alt="Your Image" style={{ maxWidth: "100%" }} />
                                    {/* Render buttons for each label */}
                                    {/* <h5 className="text-lg font-bold my-2">Classes</h5> */}
                                    {/* <div style={{ marginTop: "10px" }}>
                                        {Object.keys(jsonData).map((label, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleLabelClick(label)}
                                                ref={label === "Hardhat" ? hardhatButtonRef : null}
                                                className="text-gray-900 bg-gray-100 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 "
                                            >
                                                {label}
                                            </button>
                                        ))}
                                    </div> */}
                                    {/* {selectedLabel && jsonData[selectedLabel].map((box: any, idx: any) => (
                <div
                    key={selectedLabel + '_' + idx}
                    style={{
                        position: 'absolute',
                        left: box[0] * (windowWidth / 1920), // Adjust based on the initial width of 1920px
                        top: box[1] * (windowWidth / 1920),
                        width: (box[2] - box[0]) * (windowWidth / 1920),
                        height: (box[3] - box[1]) * (windowWidth / 1920),
                        border: '2px solid #39FF14',
                        color: 'red',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                > */}
                                    {/* {selectedLabel &&
                                        jsonData[selectedLabel].map((box: any, idx: any) => (
                                            <div
                                                key={selectedLabel + "_" + idx}
                                                style={{
                                                    position: "absolute",
                                                    left: getdisplayleft(box[0]),
                                                    top: getdisplaytop(box[1]),
                                                    width: getdisplaywidth(box[2] - box[0]),
                                                    height: getdisplayheight(box[3] - box[1]),
                                                    border: "2px solid #39FF14",
                                                    color: "red",
                                                    fontSize: "16px",
                                                    fontWeight: "bold"
                                                }}
                                            ></div>
                                        ))} */}
                                    {selectedLabel != null &&
                                        imageSrc &&
                                        jsonData[selectedLabel]?.map((box: any, idx: any) => (
                                            <div
                                                key={`${selectedLabel}_${idx}`}
                                                style={{
                                                    position: "absolute",
                                                    left: getdisplayleft(box[0]),
                                                    top: getdisplaytop(box[1]),
                                                    width: getdisplaywidth(box[2] - box[0]),
                                                    height: getdisplayheight(box[3] - box[1]),
                                                    border: "2px solid #39FF14",
                                                    color: "red",
                                                    fontSize: "16px",
                                                    fontWeight: "bold"
                                                }}
                                            ></div>
                                        ))}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </>
        );
    }
}
