import React, { useState, useEffect } from "react";
import loading from "../../assets/loading.gif";
import BoxSkeleton from "../../design system/Skeleton/cameraSkeleton";
import { LoaderComponent } from "../../pages/visionPlatform/loader/loader";

function VideoStream({ rtspUrl, apiUrl, appID, sID }: any) {

    console.log({ rtspUrl, apiUrl, appID, sID }, "check here**********");

    const [videoStream, setVideoStream] = useState("");
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const additionalData = { appID: appID, SID: sID };

    useEffect(() => {
        setVideoStream('')
        const cleanupWebSocket = () => {
            if (socket) {
                setIsCustomloading(true);
                console.log("Closing existing WebSocket connection");
                socket.close();
                setSocket(null);
            }
        };

        // Function to connect WebSocket and handle events
        const connectWebSocket = () => {
            const ws = new WebSocket(apiUrl);

            ws.onopen = () => {
                // setIsLoad(true)
                setIsCustomloading(true);
                const message = JSON.stringify({ rtspUrl, additionalData });
                console.log("WebSocket connected", message);
                ws.send(message);
            };

            ws.onmessage = event => {
                console.log(event, 'onmessage socket event:');

                const target = event.currentTarget as WebSocket;
                setIsCustomloading(false);
                setVideoStream(`data:image/jpeg;base64,${event.data}`);
            };

            ws.onclose = () => {
                console.log("WebSocket connection closed");
            };

            return ws;
        };

        // Close existing WebSocket connection if rtspUrl changes
        cleanupWebSocket();

        // Connect to new WebSocket
        const newSocket = connectWebSocket();
        setSocket(newSocket);

        // Cleanup function when component unmounts or rtspUrl changes
        return () => {
            cleanupWebSocket();
        };
    }, [rtspUrl, apiUrl, appID, sID]);

    return (
        <div>
            {/* <LoaderComponent isLoading={isCustomloading} /> */}
            {videoStream ? <img src={videoStream} alt="Video Stream" /> : <BoxSkeleton />}
        </div>
    );
}

export default VideoStream;

 