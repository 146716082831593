// import React from "react";
// import ReactDOM from "react-dom/client";
// import { createHashRouter, RouterProvider } from "react-router-dom";
// import { initializeIcons } from "@fluentui/react";
// import { MsalProvider } from '@azure/msal-react';
// import { PublicClientApplication, EventType, AccountInfo } from '@azure/msal-browser';
// import { msalConfig, useLogin } from './authConfig';

// import "./index.css";

// import Layout from "./pages/layout/Layout";
// import Chat from "./pages/chat/Chat";

// var layout;
// if (useLogin) {
//     var msalInstance = new PublicClientApplication(msalConfig);

//     // Default to using the first account if no account is active on page load
//     if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//         // Account selection logic is app dependent. Adjust as needed for different use cases.
//         msalInstance.setActiveAccount(msalInstance.getActiveAccount());
//     }

//     // Listen for sign-in event and set active account
//     msalInstance.addEventCallback((event) => {
//         if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//             const account = event.payload as AccountInfo;
//             msalInstance.setActiveAccount(account);
//         }
//     });

//     layout = (
//         <MsalProvider instance={msalInstance}>
//             <Layout />
//         </MsalProvider>
//     )
// } else {
//     layout = <Layout />
// }

// initializeIcons();

// const router = createHashRouter([
//     {
//         path: "/",
//         element: layout,
//         children: [
//             {
//                 index: true,
//                 element: <Chat />
//             },
//             {
//                 path: "qa",
//                 lazy: () => import("./pages/oneshot/OneShot")
//             },
//             {
//                 path: "*",
//                 lazy: () => import("./pages/NoPage")
//             }
//         ]
//     }
// ]);

// ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
//     <React.StrictMode>
//         <RouterProvider router={router} />
//     </React.StrictMode>
// );


import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType, AccountInfo, } from '@azure/msal-browser';
import { msalConfig } from './authConfiguration';
import App from './App'
import "./index.css";
// import dotenv from 'dotenv';
// dotenv.config();



import Chat from "./pages/chat/Chat";





/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const firstAccount = msalInstance.getAllAccounts()[0];
   
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AccountInfo)) {
        const account = (event.payload as AccountInfo)
        msalInstance.setActiveAccount(account);
    }
});

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App instance={msalInstance} />);

 ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
//      <App instance={msalInstance} />
//  );

   <MsalProvider instance={msalInstance}>
    
        <React.StrictMode>
        <App instance={msalInstance} />
        </React.StrictMode>
     
    </MsalProvider>
  );
