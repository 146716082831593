import * as React from 'react';
type Props = {
    fill?: string;
};
export const SettingIcon = (props: Props) => {
    const { fill = '#e2eaf2' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <path id="Path_39593" data-name="Path 39593" d="M27.059,12.868a9.265,9.265,0,0,0-2.912-.75l-.632-1.544a10.169,10.169,0,0,0,1.529-2.588.754.754,0,0,0-.176-.794L22.809,5.132a.754.754,0,0,0-.794-.176,10.169,10.169,0,0,0-2.588,1.529l-1.544-.632a9.134,9.134,0,0,0-.75-2.912.743.743,0,0,0-.676-.441H13.544a.743.743,0,0,0-.676.441,9.134,9.134,0,0,0-.75,2.912l-1.544.632A10.169,10.169,0,0,0,7.985,4.956a.76.76,0,0,0-.794.176L5.132,7.191a.754.754,0,0,0-.176.794,10.169,10.169,0,0,0,1.529,2.588l-.632,1.544a9.265,9.265,0,0,0-2.912.75.743.743,0,0,0-.441.676v2.912a.743.743,0,0,0,.441.676,9.265,9.265,0,0,0,2.912.75l.632,1.544a10.169,10.169,0,0,0-1.529,2.588.754.754,0,0,0,.176.794l2.059,2.059a.76.76,0,0,0,.794.176,10.169,10.169,0,0,0,2.588-1.529l1.544.632a9.134,9.134,0,0,0,.75,2.912.743.743,0,0,0,.676.441h2.912a.743.743,0,0,0,.676-.441,9.134,9.134,0,0,0,.75-2.912l1.544-.632a10.169,10.169,0,0,0,2.588,1.529.754.754,0,0,0,.794-.176l2.059-2.059a.754.754,0,0,0,.176-.794,10.169,10.169,0,0,0-1.529-2.588l.632-1.544a9.265,9.265,0,0,0,2.912-.75.743.743,0,0,0,.441-.676V13.544a.743.743,0,0,0-.441-.676ZM15,20.687A5.687,5.687,0,1,1,20.687,15,5.687,5.687,0,0,1,15,20.687Z" transform="translate(-2.5 -2.5)" fill={fill} />
            </svg>


        </>
    );
};