import { Grid, Button, Typography } from "@mui/material";
import { useState, useCallback, useEffect, useContext } from "react";
// import { ALGO_LIST } from "../../../constants";
import CheckboxComponent from "../../../design system/Checkbox";
import VideoStream from "../../../cv_components/elements/rstp"; // Ensure VideoStream is correctly imported
import React from "react";
import BoxSkeleton from "../../../design system/Skeleton/cameraSkeleton";
import { useErrorHandling } from "../../../context/errorHandlingContext";
import ApiHelper from "../../../api/ApiHelper";
import { AdminContext } from "../../../context/admin.context";
import { ALGORITHM_API_URL, CAMERA_ALGO_MAPPING_API_URL, getActiveUser } from "../vision.constant";

type TViewCamera = {
    rtspUrl: string;
    apiUrl: string;
    appID: string;
    sID: string
    disabled?: boolean
    camera_id?: any,
    updateOnChange?: boolean
    handleSave?: (selectedAlgo: any) => void
    defaultSelected?: number[];
    height?: string
}


export default function ViewCamera({ rtspUrl, apiUrl, appID, sID, disabled = false, handleSave, defaultSelected = [], height = "50vh", updateOnChange = false, camera_id }: TViewCamera) {
    const [algoList, setAlgoList] = useState<any[]>([]);
    const [selectedAlgorithms, setSelectedAlgorithms] = useState<number[]>(defaultSelected);
    console.log(defaultSelected, 'defaultSelected********');

    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);

    const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>, algoId: number) => {
        const { target: { checked } } = event;
        const checkedList = checked ? [...selectedAlgorithms, algoId] : selectedAlgorithms.filter(id => id !== algoId);
        setSelectedAlgorithms(prev =>
            checked ? [...prev, algoId] : prev.filter(id => id !== algoId)
        );
        if (updateOnChange) {
            console.log(checkedList, 'checkedList************');
            if (camera_id) {
                saveCameraAlgo(checkedList)
            }
        }

    }

    const saveCameraAlgo = async (checkedList: any) => {
        try {
            setLoader(true);
            const payload = {
                camera_id: camera_id,
                algorithm_id: checkedList,
                created_by: getActiveUser(),
                updated_by: getActiveUser(),
            };
            console.log(payload, 'payload*************');
            await api.post<any>(CAMERA_ALGO_MAPPING_API_URL, payload);
            // showToast(`Algorithm updated  successfully`, 'success');
        } catch (error) {
            // showToast(`Algorithm updated failed`, 'error');
            console.error('Error adding user:', error);
        } finally {
            setLoader(false);
        }
    };
    useEffect(() => {
        if (handleSave) {
            handleSave(selectedAlgorithms);
        }
    }, [selectedAlgorithms]);
    useEffect(() => {
        fetchAlogorithm()
    }, []);
    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }
    const fetchAlogorithm = async () => {
        try {
            setLoader(true);
            const data: any = await api.get<any[]>(ALGORITHM_API_URL);
            setAlgoList(data.response);
        } catch (error) {
            showToast("Error fetching algorithm list", "error");
            console.error('Error fetching algorithm:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} style={{ maxHeight: height, overflowY: "scroll" }}>
                {(false) ? <BoxSkeleton isBox={false} /> : <>
                    {algoList.length > 0 ? algoList.map((algo) => (
                        <CheckboxComponent
                            className="live-screen"
                            key={algo.algorithm_id}
                            checked={selectedAlgorithms.includes(algo.algorithm_id)}
                            onChange={(e) => handleCheckBox(e, algo.algorithm_id)}
                            label={algo.algorithm_name}
                            labelPlacement="end"
                        />
                    )) : (
                        <Typography>No Algorithm Found...</Typography>
                    )}
                </>}

            </Grid>
            <Grid item xs={8}>
                <VideoStream rtspUrl={rtspUrl} apiUrl={apiUrl} appID={appID} sID={sID} />
            </Grid>

        </Grid >
    );
}
