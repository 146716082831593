import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { IListOptions, TAction } from "../type";
import style from "./organization.module.css"
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getActiveUser, ORGANIZATIONS_API_URL } from "../vision.constant";

export const AddOrganization = () => {
    const [editOrg, setEditOrg] = useState<any>(null);
    const [deleteOrg, setDeleteOrg] = useState<any>(null);
    const [org, setOrg] = useState<any>({ org_name: "" });
    const [orglist, setOrglist] = useState<any[]>([]);
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [nameError, setNameError] = useState("");
    const {
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        // { id: 'org_id', label: 'Id' },
        { id: 'org_name', label: 'Organization Name' },
        { id: 'action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                console.log('Yes:', { deleteOrg, editOrg, org });
                if (deleteOrg) {
                    deleteOrganization()
                } else if (editOrg || org) {
                    saveOrganization()
                }
            }, variant: 'contained', color: 'success'
        },
    ];

    useEffect(() => {

        const loadInitial = async () => {
            await fetchOrganizationlist()
        }
        loadInitial();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);

    const fetchOrganizationlist = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any>(ORGANIZATIONS_API_URL);
            setOrglist(data.response);
        } catch (error) {
            showToast("Error fetching org list", "error");
            console.error('Error fetching orgs:', error);
        } finally {
            setLoader(false);
        }
    };

    const validateInputs = () => {
        let isValid = true;
        if (!org.org_name.trim() ||!org.org_name ) {
            setNameError('Name is required');
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true)
        setModalTitle(`Are you sure you want to ${editOrg ? 'update' : 'add'} this Organization?`)
    };

    const saveOrganization = async () => {
        console.log({ editOrg, org }, 'saveOrganization');
        if(org?.org_name?.trim().length===0) {
            setNameError("Name Required");
            return
        }
        try {
            setLoader(true);
            if (editOrg) {
                const payload = {
                    ...org,
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                }
                await api.put<any>(`${ORGANIZATIONS_API_URL}/${editOrg?.org_id}`, payload);

            } else {
                const payload = {
                    ...org,
                    created_by: getActiveUser(),
                    updated_by: getActiveUser()
                }
                await api.post<any>(ORGANIZATIONS_API_URL, payload);
            }
            setEditOrg(null);
            setDeleteOrg(null)
            await fetchOrganizationlist();
            updateBreadcrumbList(true, "")
            clearInputs();
            setSearchQuery("");
            showToast(`Organization  ${editOrg ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`Organization  ${editOrg ? 'updating' : 'adding'} failed`, 'error');
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = (org: any) => {
        console.log(org, "patchValue");

        setDeleteOrg(null)
        setEditOrg(org);
        setOrg(org);
        scrollToTop();
        setShowAddIcon(false);
        updateBreadcrumbList(false, "Edit Organization")
    };

    const handleDelete = (org: any) => {
        setEditOrg(null);
        setShowModal(true)
        setDeleteOrg(org?.org_id);
        setModalTitle("Are you sure you want to delete this Organization?")
    }

    const updateBreadcrumbList = useCallback((rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    }, [setBreadcrumbs]);

    const deleteOrganization = async () => {
        console.log(deleteOrg, 'deleteOrg');

        try {
            setLoader(true);
            await api.remove<any>(`${ORGANIZATIONS_API_URL}/${deleteOrg}`);
            clearInputs();
            showToast('Organization  deleted successfully', 'success');
            fetchOrganizationlist()
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast('Error deleting organization', 'error');
            console.error('Error deleting orgs:', error);
        } finally {
            setLoader(false);
        }
    };
    const clearInputs = () => {
        setOrg({ org_name: "" });
        setNameError("")
    };
    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditOrg(null)
        setSearchQuery("");
        updateBreadcrumbList(true, "")
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };

    const showToast = useCallback((message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }, [setOpenToast, setToastMessage, setToastSeverity]);
    const handleClose = () => {
        setShowModal(false);
    };
    const handleAdd = () => {
        setShowAddIcon(false);
        setDeleteOrg(null);
        setEditOrg(null)
        setSearchQuery("");
        updateBreadcrumbList(false, "Add Organization")
    }
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredOrgList = useMemo(() => {
        return filterByTableSearch(orglist, searchQuery);
    }, [orglist, searchQuery]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setOrg((prev: any) => ({
            ...prev,
            [name]: value
        }));
        if (name === 'org_name') {
            setNameError('');
        }
    };

    return (
        <>
            <div className={style.org__container}>
                <strong>{editOrg ? 'Update Organization ' : "Add Organization "}</strong>
                <div className={style.org__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editOrg ? 'Update Organization ' : "Save Organization "}>
                                <img width={24} height={20} className={`${style.org__curserPointer} ${style.org__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add Organization ">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                            { }
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.org__inputWrapper}>
                    <Grid item xs={3} >
                        <Input
                            id="username-input"
                            heading="Organization Name"
                            placeholder="Enter Name"
                            variant="standard"
                            name="org_name"
                            required
                            value={org?.org_name}
                            helperText={nameError}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredOrgList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredOrgList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
        </>
    );
};
export default AddOrganization; 
