import React, { useEffect, useState } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import "./AutoComplete.css"
import { AutocompleteAtomProps, IListOptions } from './type';

const AutocompleteAtom: React.FC<AutocompleteAtomProps> = ({
    id,
    options,
    defaultValue,
    placeholder = 'Select',
    multiple = false,
    onChange,
    renderInput,
    getOptionLabel = (option) => option?.label,
    helperText,
    className
}) => {
    const [value, setValue] = useState<any[]>([]);

    const initialvalue: IListOptions[] = defaultValue || [];

    useEffect(() => {
        setValue(initialvalue)
    }, [initialvalue])

    const handleChange = (event: React.SyntheticEvent, newValue: any[]) => {
        setValue(newValue);
        if (onChange) {
            onChange(event, newValue);
        }
    };

    return (
        <Autocomplete
            multiple={multiple}
            className={className}
            id={id}
            options={options}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={handleChange}
            renderInput={(params) => renderInput ? renderInput(params) : (
                <TextField
                    {...params}
                    variant="standard"
                    label={placeholder}
                    placeholder={placeholder}
                    InputLabelProps={{ shrink: true }}
                    helperText={helperText}
                />
            )}
            disableClearable
            classes={{ listbox: 'autocomplete-container' }}
        />
    );
};

export default AutocompleteAtom;
