import { createContext } from 'react';
import { BreadcrumbItem } from '../design system/Breadcrumbs/type';

export interface AdminContextType {
  breadCrumb: BreadcrumbItem[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<BreadcrumbItem[]>>;
  actions: any[];
  setActions: React.Dispatch<React.SetStateAction<any[]>>;
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  openToast: boolean;
  setOpenToast: React.Dispatch<React.SetStateAction<boolean>>;
  toastMessage: string;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  toastSeverity: 'error' | 'warning' | 'info' | 'success';
  setToastSeverity: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
  deleteId: any
  setDeleteId: React.Dispatch<React.SetStateAction<any>>;
  isLoggedInUser: boolean;
  login: () => void;
  logout: () => void;
  stream: string,
  setStream: React.Dispatch<React.SetStateAction<string>>;
}

export const AdminContext = createContext<AdminContextType>({
  breadCrumb: [],
  setBreadcrumbs: () => { },
  actions: [],
  setActions: () => { },
  loader: false,
  setLoader: () => { },
  openToast: false,
  setOpenToast: () => { },
  toastMessage: '',
  setToastMessage: () => { },
  toastSeverity: "success",
  setToastSeverity: () => { },
  showModal: false,
  setShowModal: () => { },
  modalTitle: '',
  setModalTitle: () => { },
  deleteId: null,
  setDeleteId: () => { },
  isLoggedInUser: false,
  login: () => { },
  logout: () => { },
  stream: "",
  setStream: () => { }
});
