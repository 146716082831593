import React from "react";
import { Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

type TProps = {
    isBox?: boolean;
    count?: number
}
const BoxSkeleton = ({ isBox = true, count = 5 }: TProps) => {
    return (
        <Grid container spacing={2} >
            {isBox ? (<Grid item xs={12} >
                <Skeleton variant="rectangular" width="100%" height={200} style={{ borderRadius: '5px' }} />
            </Grid>) :
                (<Grid item xs={12} >
                    {[...Array(count)].map((_, index) => (
                        <Grid container display="flex" justifyContent="space-around" className="mb-2">
                            <Grid xs={1} alignContent="center" justifyContent="center">
                                <Skeleton variant="rectangular" height={30} style={{ borderRadius: '5px' }} />
                            </Grid>
                            <Grid xs={10} >
                                <Skeleton variant="rectangular" height={30} style={{ borderRadius: '5px' }} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>)}
        </Grid>
    );
};

export default BoxSkeleton;
