// import React, { Fragment } from 'react';
// import ReactDOM from 'react-dom';
// import styles from './Dialog.module.css';

// interface DialogProps {
//     children: React.ReactNode;
//     closeTool: () => void;
//     open: any;
// }

// const Dialog: React.FC<DialogProps> = ({ children, closeTool, open }) => {
//     function handleCloseTool() {
//         closeTool();
//     }

//     if (!open) {
//         return null;
//     }

//     return ReactDOM.createPortal(
//         <Fragment>
//             <div className={styles.overlay}></div>
//             <div className={styles.modal_container}>
//                 <div onClick={handleCloseTool} className={styles.modal_btn} >&times;</div>
//                 {children}
//             </div>
//         </Fragment>,
//         document.getElementById('modal') as HTMLElement
//     );
// };

// export default Dialog;
import styles from './Dialog.module.css'

export default function Dialog({ children, closeTool, open }: any) {
    function handleCloseTool() {
        closeTool();
    }

    if (!open) {
        return null;
    }

    return <div>
        <div className={styles.overlay}></div>
        <div className={styles.modal_container}>
            <div onClick={handleCloseTool} className={styles.modal_btn}
            // variant='danger'
            >&times;</div>
            {children}
        </div>
    </div>
}
