import * as React from 'react';
type Props = {
    fill?: string;
};
export const ProjectIcon = (props: Props) => {
    const { fill = '#90a3b4' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="27.2" height="27.2" viewBox="0 0 27.2 27.2">
                <path id="Path_39609" data-name="Path 39609" d="M23.8,14.733H17a3.4,3.4,0,0,0-3.4,3.4V27.2H27.2V18.133A3.4,3.4,0,0,0,23.8,14.733ZM22.667,20.4H18.133V18.133h4.533ZM14.54,2.267A3.407,3.407,0,0,0,11.333,0H9.067A3.407,3.407,0,0,0,5.861,2.267H0V23.8a3.4,3.4,0,0,0,3.4,3.4h7.933V18.133A5.667,5.667,0,0,1,17,12.467h3.4V2.267ZM4.447,7.566,5.854,8.917,8.724,6.106l1.586,1.62L7.183,10.788a1.868,1.868,0,0,1-1.324.545,1.887,1.887,0,0,1-1.338-.553L2.878,9.2,4.448,7.567Zm0,6.927,1.406,1.351,2.871-2.811,1.586,1.62L7.183,17.715a1.868,1.868,0,0,1-1.324.545,1.887,1.887,0,0,1-1.338-.553L2.878,16.129l1.571-1.634ZM17,10.2H10.767l2.314-2.267H17Z" fill={fill} />
            </svg>
        </>
    );
};