import React from 'react';
import { Typography, Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';

const RootContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
});


const NotFoundPage = () => {
    return (
        <>
            <Layout />
            <RootContainer>
                <Typography variant="h4">
                    Oops! Page not found.
                </Typography>
                <Typography variant="body1">
                    The page you are looking for might have been removed
                    or is temporarily unavailable.
                </Typography>
                <Button
                    component={Link}
                    to="/"
                    variant="contained"
                    sx={{ marginTop: (theme) => theme.spacing(2) }}
                    style={{ backgroundColor: "#344452" }}
                >
                    Go to Home
                </Button>

            </RootContainer >
        </>
    );
};

export default NotFoundPage;
