import React from 'react'
import Chart from 'react-google-charts';


export default function CustomChart({ data, options }: any) {
    return (
        <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}
