import * as React from 'react';
type Props = {
    fill?: string;
};
export const CameraIcon = (props: Props) => {
    const { fill = '#90a3b4' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.405" height="24.966" viewBox="0 0 28.405 24.966">
                <g id="Group_22523" data-name="Group 22523" transform="translate(0 -31)">
                    <path id="Path_39598" data-name="Path 39598" d="M463.942,82H462v6.1h1.942a.832.832,0,0,0,.832-.832V82.832A.832.832,0,0,0,463.942,82Z" transform="translate(-436.369 -48.171)" fill={fill} />
                    <path id="Path_39599" data-name="Path 39599" d="M31,33.5v6.658a2.5,2.5,0,0,0,2.5,2.5H47.644V31H33.5A2.5,2.5,0,0,0,31,33.5Zm4.161.888a.832.832,0,1,1-.832.832A.832.832,0,0,1,35.161,34.384Z" transform="translate(-29.28 0)" fill={fill} />
                    <path id="Path_39600" data-name="Path 39600" d="M364.107,31H361V42.651h3.107a.832.832,0,0,0,.832-.832V31.832A.832.832,0,0,0,364.107,31Z" transform="translate(-340.972 0)" fill={fill} />
                    <path id="Path_39601" data-name="Path 39601" d="M195,276.825a2.5,2.5,0,0,0,4.993,0V271H195Zm2.5-.832a.832.832,0,1,1-.832.832A.833.833,0,0,1,197.5,275.993Z" transform="translate(-184.182 -226.685)" fill={fill} />
                    <path id="Path_39602" data-name="Path 39602" d="M9.154,276.825v-2.5H4.438v-1.664A1.664,1.664,0,0,0,2.774,271H.832a.832.832,0,0,0-.832.832v9.986a.832.832,0,0,0,.832.832H2.774a1.664,1.664,0,0,0,1.664-1.664v-1.664h5.55A4.139,4.139,0,0,1,9.154,276.825Z" transform="translate(0 -226.685)" fill={fill} />
                </g>
            </svg>


        </>
    );
};