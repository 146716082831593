import axios from 'axios';

export async function VideoUpload_Axios(videoFile:any,api_url:any,AppId:any){
  const formData = new FormData();
  formData.append('video', videoFile);
  formData.append('AppId',AppId);
  let imageUrl:any;
  let JsonData:any;
  console.log(AppId,"***********")
  try {
      const response = await axios.post(api_url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log(response.data);
    //---------------------
    const boundary = response.headers['content-type'].split('boundary=')[1];
    const parts = response.data.split(`--${boundary}`);
    let imageBase64 = null;
    let json = null;
    parts.forEach((part:any) => {
                if (part.includes('Content-Type: video/mp4')) {
                  imageBase64 = part.split('\r\n\r\n')[1].split('\r\n--')[0].trim();
                }
                
                if (part.includes('Content-Type: application/json')) {
                  const jsonData = part.split('\r\n\r\n')[1].split('\r\n--')[0];
                  console.log("jsonData:", jsonData); // Add this line for logging
                  json = jsonData
                }
              });
    if (imageBase64) {
        imageUrl = `data:video/mp4;base64,${imageBase64}`;
      //   setSrc(imageUrl);
        }
    if (json) {
      //   setJsonData(json);
          JsonData =json
      }
      // fetchImageAndJson();          
    // ---------------------------
    } catch (error) {
    console.error('Error uploading image:', error);
    }
    return {"imageUrl":imageUrl,"JsonData":JsonData};
};


// // Image
// // AppID / URL
export async function ImageUpload_Axios(selectedFile:any,api_url:any,AppId:any){

    let imageUrl = null;
    let jsonData = null;
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('AppId',AppId);
    console.log(formData,"formData");
    try {
        const response = await axios.post(api_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      //---------------------
      const boundary = response.headers['content-type'].split('boundary=')[1];
      const parts = response.data.split(`--${boundary}`);
      let imageBase64 = null;
      let json = null;
      parts.forEach((part:any) => {
                  if (part.includes('Content-Type: image/jpeg')) {
                    imageBase64 = part.split('\r\n\r\n')[1].split('\r\n--')[0].trim();
                  }
         
                  if (part.includes('Content-Type: application/json')) {
                    const jsonData = part.split('\r\n\r\n')[1].split('\r\n--')[0];
                    json = JSON.parse(jsonData);
                  }
                });
      if (imageBase64) {
          imageUrl = `data:image/jpeg;base64,${imageBase64}`;
        //   setImageSrc(imageUrl);
        // imageUrl
          }
      if (json) {
        //   setJsonData(json);
            jsonData = json
        }
        // fetchImageAndJson();          
      // ---------------------------
      } catch (error) {
      console.error('Error uploading image:', error);
      }
    console.log("jsonData",jsonData)
    return {"imageUrl":imageUrl,"jsonData":jsonData}
};



// Webcam
// AppID / URL
export async function WebCam_Axios(webCam_Data:any,api_url:any,AppId:any){
    const payLoad = { image: webCam_Data,AppId:AppId }
    console.log("Payload",payLoad)
    const response = await axios.post(api_url, payLoad);
    return response
}

// Project Based 
