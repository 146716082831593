import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { CustomToastProps } from './type';

const CustomToast: React.FC<CustomToastProps> = ({ open, message, severity, onClose, vertical = "top", horizontal = "right", autoHideDuration = 3000, variant = "filled" }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <MuiAlert
                elevation={6}
                variant={variant}
                onClose={handleClose}
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default CustomToast;
