import * as React from 'react';
type Props = {
    fill?: string;
};
export const DashboardIcon = (props: Props) => {
    const { fill = '#90a3b4' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24.781" height="24.781" viewBox="0 0 24.781 24.781">
                <g id="layer13" transform="translate(-0.528 -0.528)">
                    <path id="path1317" d="M4.114.528A3.609,3.609,0,0,0,.528,4.114V21.724A3.609,3.609,0,0,0,4.114,25.31H21.724a3.609,3.609,0,0,0,3.585-3.585V4.114A3.609,3.609,0,0,0,21.724.528Zm0,2.48H21.724A1.09,1.09,0,0,1,22.83,4.114V7.963H3.006V4.114A1.092,1.092,0,0,1,4.114,3.008ZM17.043,4.252a1.239,1.239,0,0,0,0,2.477h3.31a1.239,1.239,0,0,0,0-2.477ZM3.006,10.44H22.83V21.724a1.092,1.092,0,0,1-1.106,1.108H4.114a1.094,1.094,0,0,1-1.108-1.108ZM12.918,12.1a1.239,1.239,0,0,0-1.244,1.236v5.777a1.239,1.239,0,1,0,2.477,0V13.332A1.239,1.239,0,0,0,12.918,12.1Zm-4.955,2.48a1.239,1.239,0,0,0-1.234,1.234v3.3a1.239,1.239,0,1,0,2.477,0v-3.3a1.239,1.239,0,0,0-1.244-1.234Zm9.912,0a1.239,1.239,0,0,0-1.244,1.234v3.3a1.239,1.239,0,1,0,2.477,0v-3.3A1.239,1.239,0,0,0,17.875,14.575Z" transform="translate(0 0)" fill={fill} />
                </g>
            </svg>



        </>
    );
};