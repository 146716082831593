import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { ICamera, IListOptions, Project, TAction, User } from "../type";
import style from "./project.module.css"
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DropdownWithCheckboxes from "../../../design system/DropdownCheckbox";
import { PROJECT_TO_ADMIN_API_URL,PROJECTS_API_URL,USER_API_URL,ACCESS_API_URL,ORGANIZATIONS_API_URL, getUserRole, getActiveUser } from "../vision.constant";

export const Projects = () => {
    const [projects, setProjects] = useState<any>(null);
    const [editingProject, setEditingProject] = useState<any>(null);
    const [deleteProjectId, setDeleteProjectId] = useState<any>(null);
    const [project, setProject] = useState<any>(null);
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [nameError, setNameError] = useState<string>('');
    const [organizationError, setOrganizationError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState("");
    const [siteAdminError, setSiteAdminError] = useState<string>('');
    const [siteOptions, setSiteOptions] = useState<IListOptions[]>([]);
    const [organizationList, setOrganizationList] = useState<IListOptions[]>([]);
    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle,
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        { id: 'id', label: 'Id' },
        { id: 'project_name', label: 'Site Name' },
        { id: 'organization', label: 'Organization', group: true, type: 'object' },
        { id: 'siteAdmin', label: 'Site Admin', group: true, type: 'object' },
        { id: 'action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                
                console.log('Yes:', { deleteProjectId, editingProject, project });
                if (deleteProjectId) {
                    deleteProject()
                } else if (editingProject || project) {
                    saveProject()
                }
            }, variant: 'contained', color: 'success'
        },
    ];
    useEffect(() => {
        fetchProjects();
        fetchUsers();
        fetchOrgList();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);

    const fetchProjects = async () => {
        setLoader(true);

        try {
            const data: any = await api.get<Project[]>(PROJECTS_API_URL);
            if (getUserRole() === "super_admin") {
                setProjects(data?.response);
            } else if (getUserRole() === "org_admin") {
                const getOrgIds = await fetchAccess()
                const updatedList = getFilteredProjects(data?.response, getOrgIds);
                setProjects(updatedList)
            }

        } catch (error) {
            showToast("Error fetching site list", "error");
            console.error('Error fetching projects:', error);
        } finally {
            setLoader(false);
        }
    };
    function getFilteredProjects(data: any, orgIds: any) {

        if (!data || !Array.isArray(data)) {
            return [];
        }

        return data.filter(obj =>
            obj?.organization?.some((org: any) => orgIds.includes(org.id))
        );
    }
    const fetchAccess = async () => {
        try {
            setLoader(true)
            const data: any = await api.post<any>(`${ACCESS_API_URL}`, { email: getActiveUser() });

            const resp = data?.response;

            const org_ids = getOrgIds(resp);

            const getUnique_org_Ids = Array.from(new Set(org_ids));

            return getUnique_org_Ids
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users:', error);
            return []
        } finally {
            setLoader(false);
        }
    };
    function getOrgIds(data: any) {
        if (data && Array.isArray(data.org_admin)) {
            return data.org_admin.map((admin: any) => admin.org_id);
        }
        return [];
    }
    const fetchOrgList = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any>(ORGANIZATIONS_API_URL);
            if (getUserRole() === "org_admin") {
                const getOrgIds = await fetchAccess();
                const updatedList = data?.response?.filter((item: any) => getOrgIds.includes(item.org_id)) || [];
                const convertedOption = updatedList?.map((org: any) => ({
                    id: org.org_id,
                    label: org.org_name
                }))
                setOrganizationList(convertedOption);
            } else if (getUserRole() === "super_admin") {
                const convertedOption = data?.response.map((org: any) => ({
                    id: org?.org_id,
                    label: org?.org_name
                }))
                setOrganizationList(convertedOption)
            }
        } catch (error) {
            showToast("Error fetching org list", "error");
        } finally {
            setLoader(false);
        }
    };
    const fetchUsers = async () => {
        try {
            setLoader(true)
            const userList: any = await api.get<User[]>(USER_API_URL);
            const convertIntoOptions = userList?.response?.map((user: User) => ({
                id: user.id,
                label: `${user?.name}-${user?.email}`
                // label: user.name
            })) || [];
            setSiteOptions(convertIntoOptions);
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users:', error);
        } finally {
            setLoader(false);
        }
    };
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setProject((proj: any) => ({
            ...proj,
            [name]: value
        }));
        if (name === 'project_name') {
            setNameError('');
        } 
    };
    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true)
        setModalTitle(`Are you sure you want to ${editingProject ? 'update' : 'add'} this site?`)
    };
    const validateInputs = () => {
        let isValid = true;
        if (!project?.project_name?.trim()) {
            setNameError('Site Name is required');
            isValid = false;
        }
        if (project?.organization?.length === 0 || !project?.organization) {
            setOrganizationError('Select the organization');
            isValid = false;
        }
        if (project?.siteAdmin?.length === 0 || !project?.siteAdmin) {
            setSiteAdminError('Select the admin');
            isValid = false;
        }
        return isValid;
    };
    const saveProject = async () => {
        try {
            setLoader(true);
            if (editingProject) {
                const editId = editingProject?.id;
                const payload = {
                    project_name: project?.project_name,
                    org_id: project?.organization[0].id,
                    updated_by: getActiveUser(),
                }
                const resp: any = await api.put<Project>(`${PROJECTS_API_URL}/${editId}`, payload);
                const pa_payload = {
                    project_id: editId,
                    project_admin_user_id: project.siteAdmin.map((admin: any) => admin?.id),
                    updated_by: getActiveUser()
                }
                const paResp = await api.put<Project>(`${PROJECT_TO_ADMIN_API_URL}/${editId}`, pa_payload);

            } else {
                const payload = {
                    project_name: project?.project_name,
                    org_id: project?.organization[0].id,
                    updated_by: getActiveUser(),
                    created_by: getActiveUser(),
                }
                const resp: any = await api.post<Project>(PROJECTS_API_URL, payload);

                const pa_payload = {
                    project_id: resp?.response[0]['project_id'] ?? null,
                    project_admin_user_id: project.siteAdmin.map((admin: any) => admin?.id),
                    updated_by: getActiveUser(),
                    created_by: getActiveUser(),
                }
                const paResp = await api.post<Project>(PROJECT_TO_ADMIN_API_URL, pa_payload);

            }
            setEditingProject(null)
            setDeleteProjectId(null)
            await fetchProjects();
            updateBreadcrumbList(true, "")
            clearInputs();
            setSearchQuery("");
            showToast(`Site ${editingProject ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`Site ${editingProject ? 'updating' : 'adding'} failed`, 'error');
            console.error('Error adding project:', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = (project: Project) => {
        console.log('patch project', project);
        clearInputs();
        setDeleteProjectId(null)
        setEditingProject(project);
        setProject(project);
        scrollToTop();
        setShowAddIcon(false);
        updateBreadcrumbList(false, "Edit Site")
    };
    const handleDelete = (project: Project) => {
        console.log('delete project', project);
        setShowModal(true)
        setEditingProject(null)
        setDeleteProjectId(project?.id);
        setModalTitle("Are you sure you want to delete this site?")
    }
    const updateBreadcrumbList = (rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    }
    const deleteProject = async () => {
        try {
            setLoader(true);
            await api.remove<Project>(`${PROJECTS_API_URL}/${deleteProjectId}`);
            fetchProjects();
            clearInputs();
            showToast('Site deleted successfully', 'success');
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast('Error deleting site', 'error');
            console.error('Error deleting project:', error);
        } finally {
            setLoader(false);
        }
    };
    const clearInputs = () => {
        setProject({ project_name: "", organization: [], siteAdmin: [] });
        setNameError('');
        setOrganizationError('');
        setSiteAdminError("")
    };
    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditingProject(null)
        setSearchQuery("");
        updateBreadcrumbList(true, "")
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };
    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }
    const handleClose = () => {
        setShowModal(false);
    };
    const handleAdd = () => {
        setShowAddIcon(false);
        setDeleteProjectId(null);
        setEditingProject(null)
        setSearchQuery("");
        updateBreadcrumbList(false, "Add Site")
    }
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const filteredProjectList = useMemo(() => {
        return filterByTableSearch(projects, searchQuery);
    }, [projects, searchQuery]);
    const handleDropdownChange = (value: any[], name: string) => {
        console.log(value, "value");
        setProject((prev: Project) => ({
            ...prev,
            [name]: value
        }));

        if(name==="organization"){
            setOrganizationError("")
        }
        if(name==="siteAdmin"){
          setSiteAdminError("")
        }
    };
    return (
        <>
            <div className={style.project__container}>
                <strong>Sites </strong>
                <div className={style.project__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editingProject ? 'Update' : "Save"}>
                                <img width={24} height={20} className={`${style.project__curserPointer} ${style.project__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                            { }
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.project__inputWrapper}>
                    <Grid item xs={3} >
                        <Input
                            id="project_name-input"
                            heading="Site Name"
                            placeholder="Enter Site Name"
                            variant="standard"
                            name="project_name"
                            value={project?.project_name || ''}
                            helperText={nameError}
                            required
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DropdownWithCheckboxes
                            id="organization"
                            name="organization"
                            label="Organization"
                            options={organizationList as any}
                            value={project?.organization || []}
                            placeholder="Select organization"
                            helperText={organizationError}
                            multiple={false}
                            onChange={(value: any) => handleDropdownChange(value, "organization")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DropdownWithCheckboxes
                            id="siteAdmin"
                            name="siteAdmin"
                            label="Site Admin"
                            options={siteOptions as any}
                            helperText={siteAdminError}
                            value={project?.siteAdmin || []}
                            placeholder="Select Sites"
                            onChange={(value: any) => handleDropdownChange(value, "siteAdmin")}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredProjectList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredProjectList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
        </>
    );
};
export default Projects; 
