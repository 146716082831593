import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import { IMG_URL } from "../../constants";
interface StandardImage {
    image: string;
    title: string;
    accuracy: number;
}

interface Props {
    accuracy: number[];
    fileName: any;
    isValidate: boolean;
    stepFn: Function;
}

const StandardImages: React.FC<Props> = ({ accuracy, fileName, isValidate }) => {
    const [stdImageValue, setStdImageValue] = useState<any>([]);

    useEffect(() => {
        console.log("Standard Image is rendered for the first time and it re-renders again whenever the accuracy props change.");
        console.log(fileName);
        console.log(accuracy);
        // if (fileName === "") {
        //     return;
        // }
        if (Object.keys(fileName).length == 0) {
            return;
        }
        // need to check
        async function getStdImages() {
            const response = await fetch(`${IMG_URL}/api/getStdImages`, {
                method: "POST",
                body: JSON.stringify(fileName),
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "lntcs-Frontend": "4cbca3e312a241de9656b64260c2cd7a",
                }
            });
            const result = await response.json();
            let obj = [];
            for (let i = 0; i < result.length; i++) {
                const stringWithoutSingleQuotesFinal = result[i]["image"]?.replace(/'/g, "").replace("b/", "") || "";
                let finalImage = "/" + stringWithoutSingleQuotesFinal;
                let newObj = {
                    image: "data:image/jpg;base64," + finalImage,
                    title: result[i]["title"],
                    accuracy: accuracy && accuracy[i]
                };
                obj.push(newObj);
            }
            setStdImageValue(obj);
            console.log(accuracy);
        }
        getStdImages();
    }, [fileName, accuracy]);

    return (
        <div className="mainHolder stdOverflow">
            <div className="h-100">
                {!isValidate && (
                    <div className="h-100 mt70 d-flex justify-content-start align-items-center">
                        {/* <p className="noImage">Select the Standard Image</p> */}
                    </div>
                )}
                {isValidate && (
                    <div className="d-flex">
                        <div className="d-flex flex-column g-15 w-100 mb-4">
                            {stdImageValue !== undefined &&
                                stdImageValue.map((std: any) => (
                                    <div key={std.title} className="d-flex g-15">
                                        <img key={std.title} width={100} height={120} src={std.image} alt="" />
                                        <div className="d-flex flex-column justify-content-center w-55">
                                            <div className="d-flex justify-content-between px-10 align-items-center g-20">
                                                <p className="accuracyLabel">{std.title}</p>
                                                <p className="accuracy ">{std.accuracy ?? "0"}%</p>
                                            </div>
                                            <ProgressBar
                                                variant={
                                                    std.accuracy > 80
                                                        ? "success"
                                                        : std.accuracy > 30 || std.accuracy > 80
                                                            ? "warning"
                                                            : std.accuracy < 30
                                                                ? "danger"
                                                                : "info"
                                                }
                                                now={std.accuracy ?? 0}
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StandardImages;
