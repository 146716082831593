export const filterByTableSearch = (list: any[], search: string) => {
    if (!Array.isArray(list) || list?.length === 0) return [];
    const searchNormalized = search.normalize('NFKC').toLowerCase();
    return list.filter(Boolean).filter(obj => {
        for (let key in obj) {
            const stringKey = String(key);
            if (stringKey !== "createdAt" && stringKey) { 
                const value = (obj as any)[stringKey];
                if (value) {
                    const valueNormalized = value.toString().normalize('NFKC').toLowerCase();
                    if (valueNormalized.includes(searchNormalized)) {
                        return true;
                    }
                }
            }
        }
        return false;
    });
};