import { LogLevel } from "@azure/msal-browser";
import { BACKEND_URI } from "./constants";
const h = window.location.origin;
export const msalConfig = {
    auth: {
        clientId: "62d8d5e5-1be3-4e59-ab1e-3b4304b954c3", // This is the ONLY mandatory field that you need to supply.
        // authority: "https://login.microsoftonline.com/264b9899-fe1b-430b-9509-2154878d5774"
        redirectUri: BACKEND_URI,
        postLogoutRedirectUri: BACKEND_URI,
        navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};

export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};
