import React from 'react';
import TextField from '@mui/material/TextField';
import { ChangeEventHandler } from 'react';
import { InputFieldProps } from './type';
import "./Input.css"

const Input: React.FC<InputFieldProps> = ({
    id,
    className,
    heading,
    required,
    value,
    placeholder,
    onChange,
    onBlur,
    name,
    helperText,
    variant
}) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <TextField
            id={id}
            name={name}
            fullWidth={true}
            variant={variant}
            className={className}
            label={heading}
            required={required}
            value={value || ''}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            helperText={helperText || ""}
        />
    );
};

export default Input;
