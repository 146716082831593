// ID route

export function AlgorithmID_MAP({ AppId }: any) {
    console.log(AppId);
    let Title;
    if (AppId === "1001") {
        Title = "Identity & Safety";
    }
    if (AppId === "1002") {
        Title = "Person Detection";
    }
    if (AppId === "1003") {
        Title = "Vehicle Detection";
    }
    if (AppId === "1004") {
        Title = "Fall Detection";
    }
    if (AppId === "1005") {
        Title = "Face Recognition";
    }

    return [Title];
}
