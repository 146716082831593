import React, { useRef, useEffect, useState, useContext, ChangeEvent } from "react";
import ReactPlayer from "react-player";
import { VideoUpload_Axios } from "../utils/API";
import { useParams } from "react-router-dom";
import { VISION_AI_URL } from "../../constants";
function Video_LocalPreview({ videoSrc }: any) {
    // const playerRef = useRef(null);
    // const playerRef = useRef<any>(null);
    const playerRef = useRef<any>(null);
    const handleClick = (time: number) => {
        if (playerRef.current) {
            playerRef.current.seekTo(time, "seconds");
        }
    };

    return (
        <>
            <p>Video_LocalPreview</p>
            {/* <video loop autoplay="" muted>
                <source src={MediaUrl} type="video/mp4" />
        </video> */}
            <ReactPlayer ref={playerRef} url={URL.createObjectURL(videoSrc)} controls />
            {/* <div className='player-wrapper'>
            <ReactPlayer />
            </div> */}
            {/* <ReactPlayer  ref={playerRef} url={videoSrc} controls /> */}

            <button
                onClick={() => handleClick(20)}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
                Jump to 20 seconds
            </button>
            <button
                onClick={() => handleClick(10)}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
                Jump to 10 seconds
            </button>
        </>
    );
}

const VideoAPI = async ({ selectedFile }: any) => {};

function Video_APIPreview({ videoPreview }: any) {
    return (
        <>
            <p>Video_APIPreview</p>
            <ReactPlayer url={videoPreview} controls />
        </>
    );
}

export default function VideoUpload() {
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const { AppId } = useParams();
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files, "---");

        // Upload
        if (e.target.files) {
            const file = e.target.files[0];
            setSelectedFile(file);

            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // setVideoPreview(reader.result); // Browser preview
                };
                reader.readAsDataURL(file);
                //   setVideoPreview(reader);
            }
        }
    };

    async function handleAPI() {
        let api_url = `${VISION_AI_URL}/demo/video_upload`;
        const { imageUrl, JsonData } = await VideoUpload_Axios(selectedFile, api_url, AppId);
        alert(":");
        setVideoPreview(imageUrl);
    }

    return (
        <>
            <input
                id="dropzone-file"
                type="file"
                accept="video/mp4"
                onChange={handleFileChange}
                className="form-control"
                style={{ padding: "5px", margin: "20px", width: "300px", display: "inline-block" }}
            />
            {selectedFile ? (
                <>
                    <Video_LocalPreview videoSrc={selectedFile} />
                </>
            ) : (
                <></>
            )}
            <button onClick={() => handleAPI()} className="btn btn-success">
                Upload
            </button>

            {videoPreview ? (
                <>
                    <Video_APIPreview videoPreview={videoPreview} />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
