import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
// Adjust the import based on your file structure
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import "./Dialog.css"
import { CustomDialogProps } from './type';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog: React.FC<CustomDialogProps> = ({
    open,
    onClose,
    title,
    content,
    actions,
    variant = "contained",
    color,
    keepMounted = false,
    customAction,
    className,
}) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={keepMounted}
            onClose={onClose}
            aria-describedby="dialog-description"
            className={className}
        >
            <DialogTitle sx={{ fontSize: '1rem' }}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {customAction ? customAction.map((action, index) => (
                    <Button
                        key={index}
                        variant={action?.variant}
                        color={action?.color}
                        onClick={() => {
                            action.onClick();
                            onClose();
                        }}

                    >
                        <span style={{ textTransform: 'none' }}>  {action.label}</span>
                    </Button>
                )) : actions?.map((action, index) => (
                    <Button
                        key={index}
                        variant={action?.variant}
                        color={action?.color}
                        onClick={() => {
                            action.onClick();
                            onClose();
                        }}

                    >
                        <span style={{ textTransform: 'none' }}>  {action.label}</span>
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
