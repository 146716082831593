//new application
// const BACKEND_URI = "https://app-backend-bz2cjnpmgga7e.azurewebsites.net/";

//running in local

//Old application
// const BACKEND_URI = "https://lntcs.lntecc.com";
import { AskRequest, ChatAppResponse, ChatAppResponseOrError, ChatRequest } from "./models";
import { useLogin } from "../authConfig";
import { Any } from "@react-spring/web";
import axios from "axios";
import { BACKEND_URI, RESOURCE_NAME, RUN_MODE } from "../constants";

function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }
    return headers;
}

export async function askApi(options: AskRequest): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(options.idToken),
        body: JSON.stringify({
            question: options.question,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                use_oid_security_filter: options.overrides?.useOidSecurityFilter,
                use_groups_security_filter: options.overrides?.useGroupsSecurityFilter
            }
        })
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(options: ChatRequest): Promise<Response> {
    const url = options.shouldStream ? "chat_stream" : "chat";
    return await fetch(`${BACKEND_URI}/${url}`, {
        method: "POST",
        headers: getHeaders(options.idToken),
        body: JSON.stringify({
            history: options.history,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                use_oid_security_filter: options.overrides?.useOidSecurityFilter,
                use_groups_security_filter: options.overrides?.useGroupsSecurityFilter
            }
        })
    });
}

export async function uploadFileApi(type: string, suffix: string, subFolder: string, formData: any, accessToken: string): Promise<Response> {
    //  return await fetch(`https://ltc-coe-genai-appservice.azurewebsites.net/Upload/UploadFiles?type=${type}&suffix=${suffix}`, {
    // return await fetch(`${RESOURCE_NAME}/Upload/UploadFiles?type=${type}&suffix=${suffix}&subFolder=${subFolder}`, {
    if (RUN_MODE == "deploy") {
        return await fetch(`${RESOURCE_NAME}/Upload/UploadFiles?type=${type}&suffix=${suffix}&subFolder=${subFolder}`, {
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}` },
            body: formData
        });
    } else if (RUN_MODE == "local") {
        return await fetch(`https://localhost:7023/Upload/UploadFiles?type=${type}&suffix=${suffix}&subFolder=${subFolder}`, {
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}` },
            body: formData
        });
    } else {
        return await fetch(`${RESOURCE_NAME}/Upload/UploadFiles?type=${type}&suffix=${suffix}&subFolder=${subFolder}`, {
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}` },
            body: formData
        });
    }
}

export async function convert_to_base64(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loaddocument`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function convert_to_base64_mydoc(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loadmydocument`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function convert_to_base64_comdoc(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loadcommondocument`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function convert_to_base64_knowledgedoc(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loadknowledgedocument`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function convert_to_base64_PnMdoc(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loadpnmdocument`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function delete_file(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/deletefile`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function delete_index(IndexName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/deleteindex`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: IndexName
    });
}

export async function delete_user_file(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/deleteuserfile`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function delete_user_index(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/deleteuserindex`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function delete_compare_files(blobName: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/deletecomparefiles`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export async function listFilesInBlob(folder_path: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listblob`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function editClause(tagName: string, editedTagDetails: any, accessToken: string): Promise<Response> {
    const url = `https://ltc-coe-genai01-appservice.azurewebsites.net/EditTag/${tagName}`;
    // const url=`https://localhost:7023/EditTag/${tagName}`

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(editedTagDetails)
    });

    return response;
}

export async function deleteClause(tagName: string, accessToken: string): Promise<Response> {
    const url = `https://ltc-coe-genai01-appservice.azurewebsites.net/DeleteTag/?tagName=${tagName}`;
    // const url=`https://localhost:7023/DeleteTag/?tagName=${tagName}`

    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    return response;
}
export async function listFilesInMyBlob(folder_path: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listmyblob`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function userFileDeletionCheck(file_name: string, accessToken: string, blobName: string): Promise<Response> {
    // return await fetch(`https://localhost/Upload/CheckFileUpload?fileName=${file_name}&blobFolder=${blobName}`, {
    //     method: "GET",
    //     headers: { Authorization: `Bearer ${accessToken}` },
    try {
        const response = await fetch(
            `https://ltc-coe-genai01-appservice.azurewebsites.net/Upload/CheckFileUpload?fileName=${file_name}&blobFolder=${blobName}`,
            {
                method: "GET",
                headers: { Authorization: `Bearer ${accessToken}` }
            }
        );

        // Check if the response is successful
        if (!response.ok) {
            // If response is not successful, create a custom error response object
            const errorResponse: Response = new Response(
                JSON.stringify({
                    error: `API request failed: ${response.statusText}`,
                    status: response.status
                }),
                {
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers
                }
            );
            // Return the error response object
            return errorResponse;
        }

        // If the response is successful, return the response object
        return response;
    } catch (error) {
        // Handle the error
        console.error("Error occurred during API request:", error);
        // Create a custom error response object
        const errorResponse: Response = new Response(
            JSON.stringify({
                error: `Error occurred during API request: ${error}`,
                status: 500 // Internal Server Error
            }),
            {
                status: 500, // Internal Server Error
                statusText: "Internal Server Error",
                headers: { "Content-Type": "application/json" }
            }
        );
        // Return the error response object
        return errorResponse;
    }
}
// });
// }

export async function userFileDeletionUpdate(file_name: string, email_ID: string, blobName: string): Promise<Response> {
    return await fetch(
        `https://ltc-coe-genai01-appservice.azurewebsites.net/Upload/MarkAsDeleted?email=${email_ID}&filename=${file_name}&blobFolder=${blobName}`,
        {
            method: "POST"
        }
    );
}
export async function listFilesInItBlob(folder_path: string, accessToken: string,conatainerName:string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listitfiles?containerName=${conatainerName}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function convert_to_base64_cumdoc(blobName: string, accessToken: string,tabcontainer: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/loadcumdocument?conatainerName=${tabcontainer}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: blobName
    });
}
export const convert_to_cum_base64word = async (blobName: string, accessToken: string,tabcontainer: string) => {
    try {
        const response = await axios.post(`${BACKEND_URI}/loadcumWorddocument?conatainerName=${tabcontainer}`, blobName, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
 
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
export async function listFilesInCommanBlob(folder_path: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listfiles`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function listFilesInknowledgeBlob(folder_path: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listknowledgefiles`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function listFilesInPnMBlob(folder_path: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/listpnmfiles`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: folder_path
    });
}
export async function fetchUsers(IC: string, BU: string): Promise<Response> {
    const requestBody = {
        ic: IC,
        bu: BU
    };
    return await fetch(`${BACKEND_URI}/listusers`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
    });
}
export async function fetchApprovalList(accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/fetchrecord`, {
        method: "GET",
        headers: {
            //  Authorization: `Bearer ${accessToken}`,
        }
    });
}
export async function authorizeUser(jwtToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/userStatus`, {
        method: "POST",
        // headers: { Authorization: `Bearer ${accessToken}`},
        body: jwtToken
    });
}
export const convert_to_base64word = async (blobName: string, accessToken: string) => {
    try {
        const response = await axios.post(`${BACKEND_URI}/loadWorddocument`, blobName, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
export async function UpdateLogout(accessToken: string): Promise<Response> {
    // return await fetch(`https://ltc-coe-genai-appservice.azurewebsites.net/LogLogout`, {
    return await fetch(`${RESOURCE_NAME}/LogLogout`, {
        // return await fetch(`https://localhost:7023/LogLogout`, {

        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        body: JSON.stringify("")
    });
}
export const convert_to_knowledge_base64word = async (blobName: string, accessToken: string) => {
    try {
        const response = await axios.post(`${BACKEND_URI}/loadknowledgeWorddocument`, blobName, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
export const convert_to_PnM_base64word = async (blobName: string, accessToken: string) => {
    try {
        const response = await axios.post(`${BACKEND_URI}/loadpnmWorddocument`, blobName, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
export async function listUserICnBU(jwtToken: string): Promise<Response> {
    // const response= await fetch(`${BACKEND_URI}/checkAccess`, {
    return await fetch(`${BACKEND_URI}/checkAccess`, {
        method: "POST",
        // headers: { Authorization: `Bearer ${accessToken}`},
        body: jwtToken
    });
    // const data = await response.json();
    // return data;
}
export async function AuditTrail(LoginDetails: any, accessToken: string): Promise<Response> {
    // return await fetch(`https://ltc-coe-genai-appservice.azurewebsites.net/LogLogin`, {
    return await fetch(`${RESOURCE_NAME}/LogLogin`, {
        // return await fetch(`https://localhost:7023/LogLogin`, {

        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        body: JSON.stringify(LoginDetails)
    });
}

export async function generalChatAPi(prompt: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/openchat`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: prompt
    });
}
export async function notebookChatAPi(prompt: string, accessToken: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/notebook`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: prompt
    });
}

export async function OnlogoutAPI(): Promise<Response> {
    return await fetch(`${BACKEND_URI}/logout`, {
        method: "GET"
    });
}

export async function GetAccessList(): Promise<Response> {
    if (RUN_MODE == "local") {
        return await fetch(`https://localhost:7023/AccessList`, {
            method: "GET"
        });
    } else {
        return await fetch(`${RESOURCE_NAME}/AccessList`, {
            method: "GET"
        });
    }
}
export async function askDbQuery(userquery: string, accessToken: string, isPnm: string,newSession:boolean,uuid:string): Promise<Response> {
    const requestBody = {
        question: userquery,
       
    };

    return await fetch(`${BACKEND_URI}/api/askQuery?isPnm=${isPnm}&newSession=${newSession}&uuid=${uuid}`, {
        // return await fetch(`https://lntcs.lntecc.com/api/askQuery`, {

        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
    });
}
export async function instanthomesummary(
    document_type: string,
    email_id: string,
    formData: any,
    template_type: string,
    accessToken: string,
    indexname: string,
    filename1: string,
    filename2: string,
): Promise<Response> {
    return await fetch(
        `${BACKEND_URI}/api/cmp/Summary?document_type=${document_type}&email_id=${email_id}&template_type=${template_type}&index_name=${indexname}&filename1=${filename1}&filename2=${filename2}`,
        {
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}` },
            body: formData
        }
    );
}
export async function newclause(
    document_type: string,
    full_email_id: string,
    email_id: string,
    heading: any,
    question: string,
    template_type: string,
    accessToken: string,
    indexname: string,
    filename: string,
    filename1: string,
   
): Promise<Response> {
    const requestBody = {
        email_id: email_id,
        document_type: document_type,
        full_email_id: full_email_id,
        template_type: template_type,
        heading: heading,
        question: question,
        accessToken: accessToken,
        index_name: indexname,
        filename: filename,
        filename1: filename1
    };
 
    return await fetch(`${BACKEND_URI}/api/Summary/new_Heading`, {
        // return await fetch(`https://localhost:7023/Upload/UploadFiles?type=${type}&suffix=${suffix}`, {
 
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function getTemplatesList(emailId: string, document_type: string, full_email_id: string, accessToken: string): Promise<Response> {
    const requestBody = {
        email_id: emailId,
        document_type: document_type,
        full_email_id: full_email_id
    };

    return await fetch(`${BACKEND_URI}/api/Summary/list_template`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
    });
}

export async function instantsummary(
    document_type: string,
    email_id: string,
    formData: any,
    template_type: string,
    accessToken: string,
    indexname: string,
    filename: string
): Promise<Response> {
    return await fetch(
        `${BACKEND_URI}/api/Summary?document_type=${document_type}&email_id=${email_id}&template_type=${template_type}&index_name=${indexname}&filename=${filename}`,
        {
            method: "POST",
            headers: { Authorization: `Bearer ${accessToken}` },
            body: formData
        }
    );
}
export async function summarizeFile(document_type: string, indexname: string, filename: string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/Summary?document_type=${document_type}&index_name=${indexname}&file_name=${filename}`, {
        method: "POST"
    });
}

export async function BlobFolderList(blobFolder: string, accessToken: string): Promise<Response> {
    let url = "";
    if (RUN_MODE == "local") {
        url = `https://localhost:7023/Upload/GetUserBlobsByFolder/?blobFolder=${blobFolder}`;
    } else {
        url = `${RESOURCE_NAME}/Upload/GetUserBlobsByFolder/?blobFolder=${blobFolder}`;
    }
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    return response;
}
export async function customClause(customClauseDetails: any, accessToken: string): Promise<Response> {
    // return await fetch(`https://ltc-coe-genai-appservice.azurewebsites.net/LogLogin`, {
    // return await fetch(`${RESOURCE_NAME}/CreateTag`, {
    return await fetch(`${RESOURCE_NAME}/CreateTag`, {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        body: JSON.stringify(customClauseDetails)
    });
}
export async function getClauseList(accessToken: string): Promise<Response> {
    return await fetch(`${RESOURCE_NAME}/clauseList`, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` }
    });
}
export async function headingsummary(
    document_type: string,
    full_email_id: string,
    email_id: string,
    heading: any,
    question: string,
    template_type: string,
    accessToken: string,
    indexname: string,
    filename: string
): Promise<Response> {
    const requestBody = {
        email_id: email_id,
        document_type: document_type,
        full_email_id: full_email_id,
        template_type: template_type,
        heading: heading,
        question: question,
        accessToken: accessToken,
        index_name: indexname,
        filename: filename
    };

    return await fetch(`${BACKEND_URI}/api/Summary/Sum_Heading`, {
        // return await fetch(`https://localhost:7023/Upload/UploadFiles?type=${type}&suffix=${suffix}`, {

        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
    });
}

export async function getChatByUid(useCase: string, accessToken: string): Promise<Response> {
    return await fetch(`${RESOURCE_NAME}/Chat/groupedChat?useCase=${useCase}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
       
    });
}
