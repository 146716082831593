import {BACKEND_URI} from '../../constants';

export const USER_API_URL = `${BACKEND_URI}/api/vision/user`;
export const LIVE_API_URL = `${BACKEND_URI}/api/vision/live`;
export const CAMERA_API_URL = `${BACKEND_URI}/api/vision/camera`;
export const ACCESS_API_URL = `${BACKEND_URI}/api/vision/getaccess`;
export const PROJECTS_API_URL = `${BACKEND_URI}/api/vision/project`;
export const ALGORITHM_API_URL = `${BACKEND_URI}/api/vision/algorithm`;
export const PERMISSIONS_API_URL = `${BACKEND_URI}/api/vision/permission`;
export const CAMERA_LIST_API_URL = `${BACKEND_URI}/api/vision/getCameraList`;
export const ORGANIZATIONS_API_URL = `${BACKEND_URI}/api/vision/organization`;
export const ALL_PROJECTS_API_URL = `${BACKEND_URI}/api/vision/getAllProjects`;
export const PROJECT_TO_ADMIN_API_URL = `${BACKEND_URI}/api/vision/projectToAdmin`;
export const ORGANIZATION_MAPPING_API_URL = `${BACKEND_URI}/api/vision/orgMapping`;
export const PROJECT_TO_CAMERA_API_URL = `${BACKEND_URI}/api/vision/projectToCamera`;
export const CAMERA_ALGO_MAPPING_API_URL = `${BACKEND_URI}/api/vision/cameraAlgoMapping`;


export const getActiveUser = () => {
    return sessionStorage.getItem("email") ?? ""
};

export const getUserRole = () => {
    return localStorage.getItem("role") || "";
};
export const setUserRole = (role: string) => {
    localStorage.setItem("role", role)
};
