import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./Breadcrumbs.css"
import { BreadcrumbItem, BreadcrumbsProps } from './type';


const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    items,
    separator = <NavigateNextIcon fontSize="small" />,
    className,
    onClick,
    TypographyProps,
    LinkProps,
}) => {
    const handleClick = (item: BreadcrumbItem) => {
        if (onClick) {
            onClick(item);
        }
        if (item.link) {
            console.log('handleClick: ', item);
        }
    };

    return (
        <MuiBreadcrumbs separator={separator} className={className} >
            {items.map((item, index) => (
                <Link
                    key={index}
                    color="inherit"
                    onClick={() => handleClick(item)}
                    underline="hover"
                    className='pointer custom-breadcrumbs'
                    {...LinkProps}
                >
                    <Typography {...TypographyProps}>{item.label}</Typography>
                </Link>
            ))}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
