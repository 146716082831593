import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { VISION_AI_URL, WC_VISION_AI_URL } from "../../constants";
interface WebSocketEvent extends MessageEvent {
    data: any;
}

const Rtsp_Stream: React.FC = () => {
    const [videoStream, setVideoStream] = useState<any>("");
    const [rtspUrl, setRtspUrl] = useState<any>("");
    const [socket, setSocket] = useState<WebSocket | null>(null);

    const { AppId } = useParams<{ AppId: any }>();
    let additionalData: any = AppId;

    useEffect(() => {
        const connectWebSocket = (): WebSocket => {
            const ws = new WebSocket(`ws://${WC_VISION_AI_URL}/demo/rtsp`);

            ws.onopen = () => {
                console.log("WebSocket connected");
                // Send RTSP URL and additional data to the server when the connection is established
                const message = JSON.stringify({ rtspUrl, additionalData });
                ws.send(message);
            };

            ws.onmessage = (event: WebSocketEvent) => {
                setVideoStream(`data:image/jpeg;base64,${event.data}`);
            };

            ws.onclose = () => {
                console.log("WebSocket connection closed");
                setSocket(null);
            };

            return ws;
        };

        const ws: WebSocket = connectWebSocket();
        setSocket(ws);

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [rtspUrl, additionalData]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRtspUrl(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Reconnect WebSocket with the new RTSP URL and additional data
        if (socket) {
            socket.close();
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ padding: "15px" }}>
                <input
                    type="text"
                    value={rtspUrl}
                    onChange={handleInputChange}
                    placeholder="Enter RTSP URL"
                    className="form-control"
                    style={{ width: "250px", display: "inline-block", marginRight: "10px" }}
                />
                <button type="submit" className="btn btn-success">
                    Connect
                </button>
            </form>
            {videoStream && <img src={videoStream} alt="Video Stream" />}
            {!videoStream && <p></p>}
        </div>
    );
};

export default Rtsp_Stream;

// import React, { useState, useEffect } from 'react';
// import { useParams } from "react-router-dom";

// function Rtsp_Stream() {
//   const [videoStream, setVideoStream] = useState('');
//   const [rtspUrl, setRtspUrl] = useState('');
//   const [socket, setSocket] = useState(null);

//   const {AppId} = useParams();
//   let additionalData = AppId;
//   useEffect(() => {
//     const connectWebSocket = () => {
//       const ws = new WebSocket('ws://172.31.10.96:5200/demo/rtsp');

//       ws.onopen = () => {
//         console.log('WebSocket connected');
//         // Send RTSP URL and additional data to the server when the connection is established
//         const message = JSON.stringify({ rtspUrl, additionalData });
//         ws.send(message);
//       };

//       ws.onmessage = (event) => {
//         setVideoStream(`data:image/jpeg;base64,${event.data}`);
//       };

//       ws.onclose = () => {
//         console.log('WebSocket connection closed');
//         setSocket(null);
//       };

//       return ws;
//     };

//     const ws:any = connectWebSocket();
//     setSocket(ws);

//     return () => {
//       if (ws) {
//         ws.close();
//       }
//     };
//   }, [rtspUrl]);

//   const handleInputChange = (event) => {
//     setRtspUrl(event.target.value);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Reconnect WebSocket with the new RTSP URL and additional data
//     if (socket) {
//       socket.close();
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <input type="text" value={rtspUrl} onChange={handleInputChange} placeholder="Enter RTSP URL" />
//         <button type="submit">Connect</button>
//       </form>
//       {videoStream && <img src={videoStream} alt="Video Stream" />}
//       {!videoStream && <p>Loading...</p>}
//     </div>
//   );
// }

// export default Rtsp_Stream;
