import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dialog from "./UI/Dialog";
// import { BACKEND_URI } from "../../constants";
import { AnyMxRecord } from "dns";
import { IMG_URL } from "../../constants";
import ToolTip from "./UI/Tooltip";
interface Props {
    tool: any;
    file_path: any;
    stdImage: string;
    STEPS: any;
    stepFn: Function;
    toolContainer: any;
    resetStdImage: Function;
    uploadedReset: Function;
}

const FinalImage: React.FC<Props> = ({ tool, file_path, stdImage, STEPS, stepFn, toolContainer,resetStdImage,uploadedReset }) => {
    const [fileInfo, setFileInfo] = useState<any>("");
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [error, setError] = useState<{ code: number; title: string; message: string }>({
        code: 0,
        title: "",
        message: ""
    });

    useEffect(() => {
        async function getFinalResponse(userData: string) {
            if (userData === "") {
                return null;
            }
            const response = await fetch(`${IMG_URL}/api/stdAnnotatedImage`, {
                method: "POST",
                body: JSON.stringify(userData),
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "lntcs-Frontend": "4cbca3e312a241de9656b64260c2cd7a",
                }
            });
            const result = await response.json();
            console.log(result);
            if (result.errorCode === 1) {
                console.log(result.errorMessage);
                setError({
                    code: 1,
                    title: result.errorTitle,
                    message: result.errorMessage
                });
                return;
            }
        }
        getFinalResponse(fileInfo);
    }, [fileInfo]);

    function handleOpenToggle() {
        tool();
    }

    async function handleUploadImage(event: React.ChangeEvent<HTMLInputElement>) {
        toolContainer();
        resetStdImage();
        uploadedReset(false);
        setUploaded(true);
        stepFn(1);
        const file = event.target.files![0];
        const fileName = (event.target.files![0].name).split(".")[0];
        const fileType = event.target.files![0].type.split("/")[1];
        const base64 = await base64Convertor(file);
        const finalBase64: any = base64.split(",")[1];
        let emailid = sessionStorage.getItem("email");

        const random = Math.floor(Math.random() * 9000 + 1000);
        const uniqueFileName = fileName + "-" + random;

        setFileInfo({
            Main_Image: finalBase64,
            File_Type: fileType,
            File_Name: uniqueFileName,
            // User_Name: "pushparaj.k@lntecc.com",
            User_Name:emailid
        });
        file_path({
            File_Name: uniqueFileName,
            User_Name:emailid
            // User_Name: "pushparaj.k@lntecc.com"
        });
        event.target.value='';
    }

    function base64Convertor(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    function handleCloseTool() {
        setError({
            code: 0,
            title: "",
            message: ""
        });
    }

    return (
        <div className="mainHolder d-flex flex-column h-95 flex">
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="row" >
                    <div className="col-8">
                        <Form.Group onChange={handleUploadImage} controlId="formFile" className="w-70">
                            <Form.Control type="file" />
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <Button style={{ marginLeft: "10px" }} onClick={handleOpenToggle} disabled={!uploaded} variant="success">
                            Open Tool
                        </Button>
                    </div>
                </div>
            </div>

            <div className={`align-center grow-2 px-20 ${stdImage ? "" : "uploadImage"}`}>
                {stdImage && <img className="imageResolution" src={stdImage} alt="" />}
                {stdImage === "" && <p>Please, Upload the image...</p>}
            </div>
            <div className="d-flex flex-column g-10">
                <p className="stepsHeading">Steps to be followed</p>
                <div className="d-flex justify-content-between flex-wrap">
                    {STEPS.map((STEP: any) => {
                        return (
                            <div key={STEP.id} className={`d-flex g-10 ${STEP.status === "Completed" ? "stepsCompleted" : "steps"}`}>
                                {STEP.status === "None" && <span>{STEP.id}.</span>}
                                {STEP.status === "Completed" && <span>&#10004;</span>}
                                <ToolTip className="stepTitle" message={STEP.message} title={STEP.title} />
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* {error.code === 1 && <Dialog open={error} closeTool={handleCloseTool}>
                <div className="errorHolder">
                    <h2>{error.title}</h2>
                    <p className="errorMessage">{error.message}</p>
                </div>
            </Dialog>} */}

            {/* {error.code === 1 && <Dialog open={true} closeTool={handleCloseTool}>
                <div className="errorHolder">
                    <h2>{error.title}</h2>
                    <p className="errorMessage">{error.message}</p>
                </div>
            </Dialog>} */}
            {/* {error.code === 1 && <Dialog open={true} closeTool={handleCloseTool}>
                <div className="errorHolder">
                    <h2>{error.title}</h2>
                    <p className="errorMessage">{error.message}</p>
                </div>
            </Dialog>} */}
        </div>
    );
};

export default FinalImage;
