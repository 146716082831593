import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { WebCam_Axios } from "../utils/API";
import { useParams } from "react-router-dom";
import { VISION_AI_URL } from "../../constants";
const VideoUpload = () => {
    const { AppId } = useParams();
    const [detectedObjects, setDetectedObjects] = useState<any>([]);
    const webcamRef = React.useRef<any>(null);
    const canvasRef = React.useRef<any>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            captureFrame();
        }, 1000); // Capture frame every second
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        drawBoundingBoxes();
    }, [detectedObjects]);

    const captureFrame = async () => {
        const imageSrc = webcamRef.current?.getScreenshot(); // Add optional chaining
        if (imageSrc) {
            const imageData = imageSrc.split(",")[1]; // Extract base64 encoded image data
            try {
                // const response = await axios.post('http://localhost:5000/detect_objects', { image: imageData });
                const response = await WebCam_Axios(imageData, `${VISION_AI_URL}/demo/web_camera`, AppId);
                console.log(response);
                setDetectedObjects(response.data);
            } catch (error) {
                console.error("Error detecting objects:", error);
            }
        }
    };

    const colorPalette = ["#FF5733", "#33FF57", "#5733FF", "#33A4FF", "#A433FF"]; // Predefined color palette

    const drawBoundingBoxes = () => {
        const ctx = canvasRef.current.getContext("2d");
        const canvasWidth = webcamRef.current.video.videoWidth;
        const canvasHeight = webcamRef.current.video.videoHeight;
        console.log(webcamRef, "webcamRef");
        console.log(canvasWidth, "canvasWidth");
        console.log(canvasHeight, "canvasHeight");
        const webcamRect = webcamRef.current.video.getBoundingClientRect();
        const { left, top } = webcamRect;
        canvasRef.current.width = canvasWidth;
        canvasRef.current.height = canvasHeight;
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        Object.keys(detectedObjects).forEach((label: any, index: any) => {
            const color = colorPalette[index % colorPalette.length]; // Select color from the palette

            detectedObjects[label].forEach((bbox: any) => {
                const [x, y, width, height] = bbox;
                ctx.beginPath();
                ctx.lineWidth = "2";
                ctx.strokeStyle = color;
                ctx.rect(x, y, width, height);
                ctx.stroke();
                ctx.font = "20px Arial";
                ctx.fillStyle = color;
                ctx.fillText(label, x, y - 5);
            });
        });

        // Set position of canvas
        canvasRef.current.style.position = "absolute";
        canvasRef.current.style.left = `${left}px`;
        canvasRef.current.style.top = `${top}px`;
    };

    return (
        <>
            {" "}
            <h5 className="text-xl font-bold my-2">On Device Camera</h5>
            <div className="App flex justify-around">
                <Webcam
                    audio={false}
                    // width={1280}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                />
                <canvas ref={canvasRef} />
            </div>
        </>
    );
};

export default VideoUpload;
