import React, { useState, useEffect, useContext } from "react";
import { Grid } from '@mui/material';
import ViewCamera from '../camera/viewCamera';
import "./live.css";
import BoxSkeleton from '../../../design system/Skeleton/cameraSkeleton';
import { TreeView } from '@mui/x-tree-view';
import { styled, alpha } from '@mui/material/styles';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Breadcrumbs from '../../../design system/Breadcrumbs';
import { BreadcrumbItem } from '../../../design system/Breadcrumbs/type';
import { useErrorHandling } from "../../../context/errorHandlingContext";
import ApiHelper from "../../../api/ApiHelper";
import { AdminContext } from "../../../context/admin.context";
import { LIVE_API_URL, getActiveUser, getUserRole, ACCESS_API_URL, PERMISSIONS_API_URL } from "../vision.constant";

interface Camera {
  id: number;
  name: string;
  url: string;
}

interface Project {
  id: number;
  name: string;
  cameras: Camera[];
}

interface Organization {
  id: number;
  name: string;
  projects: Project[];
}

const CONFIG = {
  rtspUrl: "",
  apiUrl: "wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=1723463339030",
  appID: "101",
  sID: "10"
};

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
  [`div.MuiTreeItem-content:has(div.MuiTreeItem-iconContainer:not(:empty))`]: {
    backgroundColor: 'white !important',
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.25) : theme.palette.primary.dark,
    color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const createTreeItems = (data: Organization[], onClick: (instance: any) => void) => {
  return data.map((org) => (
    <CustomTreeItem
      key={`org-${org.id}`}
      itemId={`org-${org.id}`}
      label={org.name}
      onClick={() => onClick({ org })}
    >
      {org.projects.length > 0 ? (
        org.projects.map((proj) => (
          <CustomTreeItem
            key={`pro-${org.id}-${proj.id}`}
            itemId={`pro-${org.id}-${proj.id}`}
            label={proj.name}
            onClick={() => onClick({ org, proj })}
          >
            {proj.cameras.length > 0 ? (
              proj.cameras.map((cam) => (
                <CustomTreeItem
                  key={`cam-${org.id}-${proj.id}-${cam.id}`}
                  itemId={`cam-${org.id}-${proj.id}-${cam.id}`}
                  label={cam.name}
                  onClick={() => onClick({ org, proj, cam })}
                />
              ))
            ) : (
              <CustomTreeItem
                key={`empty-cam-${org.id}-${proj.id}`}
                itemId={`empty-cam-${org.id}-${proj.id}`}
                label="No Cameras"
                disabled
              />
            )}
          </CustomTreeItem>
        ))
      ) : (
        <CustomTreeItem
          key={`empty-pro-${org.id}`}
          itemId={`empty-pro-${org.id}`}
          label="No Projects"
          disabled
        />
      )}
    </CustomTreeItem>
  ));
};


const BreadcrumbsSection = ({ breadcrumbsData }: { breadcrumbsData: BreadcrumbItem[] }) => (
  <Breadcrumbs
    items={breadcrumbsData}
    TypographyProps={{ variant: 'body1' }}
    LinkProps={{ underline: 'none' }}
    className='p-2 custom_breadcrumb'
  />
);

const ContentSection = ({ showCameraView }: { showCameraView: boolean }) => (
  <div style={{ display: 'flex' }}>
    <BoxSkeleton isBox={false} />
    <BoxSkeleton />
  </div>
);

export const Live = () => {
  const [data, setData] = useState<Organization[]>([]);
  const [breadcrumbsData, setBreadcrumbsData] = useState<BreadcrumbItem[]>([]);
  const [showCameraView, setShowCameraView] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState<number[]>([]);
  const [camera_id, setCamera_Id] = useState(null);
  const [config, setConfig] = useState(CONFIG)
  const { showToastMessage, handleLogout } = useErrorHandling();
  const api = new ApiHelper(showToastMessage, handleLogout);
  const {
    setLoader,
    setOpenToast,
    setToastMessage,
    setToastSeverity
  } = useContext(AdminContext);

  useEffect(() => {
    fetchLive();
  }, []);

  const fetchLive = async () => {
    console.log(getUserRole(), 'getUserRole()');
    console.log(getActiveUser(), 'getActiveUser()');

    try {
      setLoader(true);
      const response: any = await api.get(LIVE_API_URL);
      if (getUserRole() === "super_admin") {
        console.log('API response:', response?.response);
        if (Array.isArray(response?.response)) {
          setData(response?.response);
        } else {
          showToast("Unexpected data format", "error");
          setData([]);
        }
      } else if (getUserRole() === "org_admin") {
        const org_ids: number[] = await fetchAccess(getUserRole());
        console.log(org_ids, "org-ids**********");
        const filteredList = response?.response.filter((org: any) => org_ids.includes(org?.id)) || [];
        setData(filteredList);
      } else if (getUserRole() === "site_admin") {
        const projectids = await fetchAccess(getUserRole());
        const filteredOrganizations = findProjectsByIds(response?.response, projectids);
        setData(filteredOrganizations);
      }
    } catch (error) {
      showToast("Error fetching live data", "error");
      console.error('Error fetching live data:', error);
    } finally {
      setLoader(false);
    }
  };
  const fetchAccess = async (role: string) => {
    try {
      const data: any = await api.post<any>(`${ACCESS_API_URL}`, { email: getActiveUser() });
      const resp = data?.response;
      if (role === "org_admin") {
        const org_ids = getOrgIds(resp);
        const getUnique_org_Ids = Array.from(new Set(org_ids));
        console.log(getUnique_org_Ids, 'getUnique_org_Ids');
        return getUnique_org_Ids
      } else if (role === "site_admin") {
        return resp?.project_admin?.map((project: any) => project?.project_id) || []
      }
    } catch (error) {
      showToast("Error fetching user list", "error");
      console.error('Error fetching users:', error);
      return []
    } 
  };

  function getOrgIds(data: any) {
    if (data && Array.isArray(data.org_admin)) {
      return data.org_admin.map((admin: any) => admin.org_id);
    }
    return [];
  }

  const findProjectsByIds = (data: any[], projectIds: number[]) => {
    if (!Array.isArray(projectIds)) {
      return [];
    }

    const filteredList = data?.map((org: any) => {
      const filteredProjects = org?.projects?.filter((project: any) =>
        projectIds.includes(project?.id)
      );

      if (filteredProjects?.length > 0) {
        return {
          ...org,
          projects: filteredProjects
        };
      }
    }).filter((org: any) => org !== undefined);

    return filteredList;
  };

  const handleTreeItemClick = (instance: any) => {
    const { org, proj, cam } = instance;
    const lengthOfInstance = Object.keys(instance).length;
    if (lengthOfInstance === 1) {
      setShowCameraView(false);
      setBreadcrumbsData([{ label: org?.name, link: '' }]);
    } else if (lengthOfInstance === 2) {
      setBreadcrumbsData([{ label: org?.name, link: '' }, { label: proj?.name, link: '' }]);
      setShowCameraView(false);
    } else {
      const selectedAlgo = cam?.algorithms?.map((algo: any) => algo.id) || [];
      setDefaultSelected(selectedAlgo);
      console.log(cam, 'cam');
      setConfig((previous: any) => ({
        ...previous,
        rtspUrl: cam?.rtspUrl
      }))
      console.log(selectedAlgo, 'selectedAlgo');
      setCamera_Id(cam?.id)
      setBreadcrumbsData([{ label: org?.name, link: '' }, { label: proj?.name, link: '' }, { label: cam?.name, link: '' }]);
      fetchLive()
      setTimeout(() => {
        setShowCameraView(true);
      }, 100);
    }
  };

  const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setToastMessage(message);
    setToastSeverity(severity);
    setOpenToast(true);
  };

  return (
    <Grid container spacing={2} style={{ maxHeight: "85vh", overflowY: 'scroll' }}>
      <Grid item md={4} style={{ maxHeight: "85vh", overflowY: 'scroll' }}>
        <TreeView>
          {createTreeItems(data, handleTreeItemClick)}
        </TreeView>
      </Grid>
      <Grid item md={8} width={"100%"}>
        <BreadcrumbsSection breadcrumbsData={breadcrumbsData} />
        {showCameraView && (
          <ViewCamera
            updateOnChange={true}
            camera_id={camera_id}
            rtspUrl={config.rtspUrl}
            apiUrl={config.apiUrl}
            appID={config.appID}
            sID={config.sID}
            disabled={true}
            defaultSelected={defaultSelected}
            height={"85vh"}
            key={defaultSelected.join('-')}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Live;
