import * as React from 'react';
type Props = {
    fill?: string;
};
export const UserIcon = (props: Props) => {
    const { fill = '#90a3b4' } = props;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.933" height="27" viewBox="0 0 26.933 27">
                <g id="Group_22522" data-name="Group 22522" transform="translate(-2.618 -2.5)">
                    <circle id="Ellipse_393" data-name="Ellipse 393" cx="4.04" cy="4.04" r="4.04" transform="translate(12.045 7.887)" fill={fill} />
                    <path id="Path_39594" data-name="Path 39594" d="M39.127,54.62H33.741a2.7,2.7,0,0,0-2.693,2.693v7.272a1.178,1.178,0,0,0,.808,1.212,10.882,10.882,0,0,0,9.157,0,1.294,1.294,0,0,0,.808-1.212V57.313a2.7,2.7,0,0,0-2.693-2.693Z" transform="translate(-20.349 -37.307)" fill={fill} />
                    <circle id="Ellipse_394" data-name="Ellipse 394" cx="4.04" cy="4.04" r="4.04" transform="translate(20.125 2.5)" fill={fill} />
                    <circle id="Ellipse_395" data-name="Ellipse 395" cx="4.04" cy="4.04" r="4.04" transform="translate(3.965 2.5)" fill={fill} />
                    <path id="Path_39595" data-name="Path 39595" d="M75.017,35.667h-4.04A6.773,6.773,0,0,1,69.9,39.3a5.2,5.2,0,0,1,2.424,4.444v4.04a11.163,11.163,0,0,0,4.579-.943,1.294,1.294,0,0,0,.808-1.212V38.36a2.7,2.7,0,0,0-2.693-2.693Z" transform="translate(-48.16 -23.741)" fill={fill} />
                    <path id="Path_39596" data-name="Path 39596" d="M9.352,35.667H5.312A2.7,2.7,0,0,0,2.618,38.36v7.272a1.178,1.178,0,0,0,.808,1.212A10.737,10.737,0,0,0,8,47.787v-4.04A5.2,5.2,0,0,1,10.429,39.3a6.773,6.773,0,0,1-1.077-3.636Z" transform="translate(0 -23.741)" fill={fill} />
                </g>
            </svg>

        </>
    );
};