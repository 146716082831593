import { useParams } from "react-router-dom";
import Header from "../elements/Header";
import React, { useState, useEffect } from "react";

import Layout from "../../pages/layout/Layout";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import cctvoicon from "../../assets/LntCSImages/cctvicon.png";



import VideoStream from "../elements/rstp";
import { AlgorithmID_MAP } from "../utils/AlgorithmID_MAP";
import { ProjectID_MAP, getProjectSource } from "../utils/ProjectID_MAP";
import loading from "../../assets/loading.gif";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./ProjectPage.css";

export default function ProjectPage() {
    const { AppId } = useParams();

    const location = useLocation();
    const { state } = location;
    const [projectID, setprojectID] = useState("");
    const [sourceID, setsourceID] = useState("");
    const [dataPck, setdataPck] = useState<any>(null);
    const [Title] = AlgorithmID_MAP({ AppId });
    const [Project] = ProjectID_MAP({ AppId });
    const title = state?.column || "";
    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);
   
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
    const navigate = useNavigate();
 
    console.log("---");
    // console.log(Project)
    // const [ProjectName,SourceName] =
    //onclick select State
    function UpdateSoucre(PID: any, SID: any) {
        setprojectID(PID);
        setsourceID(SID);
        const Sources = getProjectSource({ Project, PID, SID });
        // localStorage.setItem("currentstreamurl", Sources?.sourceURL);
        console.log(Sources, "Sources", "Sources");
        setdataPck(Sources);
    }
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        // Scroll to top on component mount
        scrollToTop();
    }, []);

    const handleGoBack = async () => {
        setdataPck(null)
          navigate("/");
      };

    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabId: any, ID: any) => {};
    const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
        event.preventDefault();
        if(selectedItemId==itemId)
            {
                return
            }
        else if(itemId && itemId.split("-").length == 2) {
            setSelectedItemId(itemId)
            let tabId = itemId.split("-")[0];
            let id = itemId.split("-")[1];
 
            UpdateSoucre(tabId, id);
         
        }
        function cctvIcon() {
            return (
                <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }}>
                    {/* tslint:disable-next-line: max-line-length */}
                    <path id="primary" d="M19,8H5A1,1,0,0,0,4,9v3a8,8,0,0,0,16,0V9A1,1,0,0,0,19,8Z"></path>
                    <path
                        id="secondary"
                        d="M22,5V7a2,2,0,0,1-2,2H4A2,2,0,0,1,2,7V5A1,1,0,0,1,3,4H21A1,1,0,0,1,22,5ZM12,13.5A1.5,1.5,0,1,0,13.5,15,1.5,1.5,0,0,0,12,13.5Z"
                    ></path>
                </SvgIcon>
            );
        }
    };

    //update Page
    useEffect(() => {}, [sourceID]);

    return (
        <>
            <div>
                <Layout />
                {/* <p>Project {AppId}</p>   */}
                <div className="mx-7">
                    <img
                        src={loading}
                        style={{ display: isCustomloading ? "block" : "none", width: "70px", position: "fixed", top: "50%", left: "50%", zIndex: "99999" }}
                    />

                    {/* <div className="font-bold my-2 text-gray-700 crump">Home / {title}</div> */}
                    <button className="btn btn-primary headermenuitem" onClick={handleGoBack}> <FontAwesomeIcon icon={faArrowLeft} style={{ marginLeft: "5px", marginRight: "5px" }} />Back</button>
                </div>
                <div className="bg-white shadow px-4 py-2 min-h-screen mx-7" style={{ marginTop: "-5px" }}>
                    <div className="row" style={{ paddingTop: "0px" }}>
                        <div className="col-3" style={{ minHeight: "100vh", borderRight: "1px solid #eee", boxShadow: "5px 0px 5px #eee", paddingLeft: "0px" }}>
                            <div>
                                <SimpleTreeView
                                    onItemSelectionToggle={handleItemSelectionToggle}
                                    //  slots={{
                                    //     endIcon: cctvIcon(),
                                    //   }}
                                >
                                    <TreeItem itemId="grid" label="Innovation Campus">
                                        <TreeItem
                                            itemId="101-10"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Project Office
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="101-11"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Project Road Access
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="101-12"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Compound Wall
                                                </span>
                                            }
                                        />
                                    </TreeItem>
                                    <TreeItem itemId="pickers" label="Raintree">
                                        <TreeItem
                                            itemId="102-10"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Site 1
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="102-11"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Site 2
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="102-12"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Stores OutSide
                                                </span>
                                            }
                                        />
                                    </TreeItem>
                                    <TreeItem itemId="charts" label="Super Speciality Hospital - Warangal">
                                        <TreeItem
                                            itemId="103-10"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Site 1
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="103-11"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Site 2
                                                </span>
                                            }
                                        />
                                        <TreeItem
                                            itemId="103-12"
                                            label={
                                                <span>
                                                    <img src={cctvoicon} className="cctvicon" />
                                                    Main Gate
                                                </span>
                                            }
                                        />
                                    </TreeItem>
                                </SimpleTreeView>
                            </div>
                        </div>
                        <div className="col-9">
                            {dataPck ? (
                                <div className="flex flex-col">
                                    <div className="">
                                        <h4 className="text-xl font-bold text-gray-700 py-7 px-10" style={{ background: "#eeeeee", padding: "5px 10px" }}>
                                            {dataPck.parentKey} - {dataPck.sourceName}
                                        </h4>
                                    </div>
                                    <VideoStream rtspUrl={dataPck.payload["RTSP_SRC"]} apiUrl={dataPck.sourceURL} appID={AppId} sID={dataPck.sid} />
                                </div>
                            ) : (
                                <>
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "17px",
                                            padding: "5px 15px",
                                            height: "450px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            background: "#f2f2f2"
                                        }}
                                    >
                                        Please Select a Project from left pane
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
