import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import adstyles from "./adminHome.module.css";
import loading from "../../assets/loading.gif";

// import "bootstrap/dist/css/bootstrap.css";
import Table from "react-bootstrap/Table";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from "react-google-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUserGroup, faUserCheck, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontWeights } from "@fluentui/theme";
import { RESOURCE_NAME } from "../../constants";
const AdminPage = () => {
    let currentDate: any = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - 6);
    const [data, setData] = useState<any>({});
    const [budata, setBuData] = useState<any>({});
    const [userdata, setUserData] = useState<any>({});
    const [userdatakm, setUserDataKm] = useState<any>({});

    const [dataChart, setDataChart] = useState<any>({});

    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);
    const [selectedStartDateTime, setStartSelectedDateTime] = useState<any>(startDate);
    const [selectedEndDateTime, setEndSelectedDateTime] = useState<any>(currentDate);
    const [isDropdown, setIsDropdown] = useState<boolean>(true);
    const countsMap = new Map();

    useEffect(() => {
        fetchBuData();
        fetchuserData();
        fetchuserDataKM();
        if (isDropdown) {
            fetchData();

            setIsDropdown(false);
        }
    }, [selectedStartDateTime, selectedEndDateTime]);

    const handleStartDateChange = (date: Date | null): void => {
        setStartSelectedDateTime(date);
    };

    const handleEndDateChange = (date: Date | null): void => {
        setEndSelectedDateTime(date);
    };

    const handleRangeChange = (event: { target: { value: any } }) => {
        setIsDropdown(true);
        const range = event.target.value;
        let endDate: any = new Date(); // Current date
        let startDate;
        switch (range) {
            case "Last 7 Days":
                startDate = new Date(endDate);
                startDate.setDate(startDate.getDate() - 6); // Set start date to 7 days ago
                break;
            case "Last 30 days":
                startDate = new Date(endDate);
                startDate.setDate(startDate.getDate() - 29); // Set start date to 30 days ago
                break;
            case "This Month":
                startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1); // Set start date to first day of the current month
                break;
            case "Last Month":
                let lastMonth = endDate.getMonth() - 1;
                let lastYear = endDate.getFullYear();
                if (lastMonth === -1) {
                    // If current month is January
                    lastMonth = 11; // December
                    lastYear--; // Previous year
                }
                startDate = new Date(lastYear, lastMonth, 1);
                const endOfMonth = new Date(lastYear, lastMonth + 1, 0);
                endDate = endOfMonth; // End date is the last day of the last month
                break; // Get the last day of the last month
            case "Last 24 hours":
                break;

            case "All time":
                startDate = new Date("2024-01-01");
                break;

            case "Today":
                startDate = new Date();
                break;

            default:
                startDate = null;
                break;
        }
        setStartSelectedDateTime(startDate);
        setEndSelectedDateTime(endDate);
    };

    const formatStartDate = (date: Date | null): string => {
        if (!date) return ""; // Return empty string if date is null
        date.setHours(0, 0, 0, 0);
        const formattedDate = new Date(date).toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true
        });

        return formattedDate;
    };

    const formatEndDate = (date: Date | null): string => {
        if (!date) return ""; // Return empty string if date is null
        date.setHours(23, 59, 59, 59);
        const formattedDate = new Date(date).toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true
        });

        return formattedDate;
    };

    // const formatDate = (date:any) => {
    //     if (!date) return ""; // Return empty string if date is null

    //     // Adjust the date to Indian Standard Time (IST)
    //     const istDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));

    //     // Set the hour, minute, and second to 00:00:00
    //     istDate.setHours(0, 0, 0, 0);

    //     const formattedDate = istDate.toLocaleString("en-IN", {
    //         month: "numeric",
    //         day: "numeric",
    //         year: "numeric",
    //         hour: "2-digit",
    //         minute: "2-digit",
    //         second: "2-digit",
    //         hour12: true
    //     });

    //     return formattedDate;
    // };
    // const fetchData = async () => {
    //         fetchDataForChat()
    //     try {
    //         setIsCustomloading(true);
    //         const formattedStartDateTime = formatStartDate(selectedStartDateTime);
    //         const formattedEndDateTime = formatEndDate(selectedEndDateTime);
    //         const response = await fetch(`https://localhost:7023/count?start=${formattedStartDateTime}&end=${formattedEndDateTime}`);
    //         const jsonData = await response.json();
    //         setData(jsonData);
    //         // setData({
    //         //     login: 236,
    //         //     uploads: 400,
    //         //     chats: 60,
    //         // });
    //         setIsCustomloading(false);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         setIsCustomloading(false);
    //     }
    // };

    const fetchData = async () => {
        try {
            setIsCustomloading(true);
            const formattedStartDateTime = formatStartDate(selectedStartDateTime);
            const formattedEndDateTime = formatEndDate(selectedEndDateTime);
            const response = await fetch(`${RESOURCE_NAME}/count?start=${formattedStartDateTime}&end=${formattedEndDateTime}`);
            const jsonData = await response.json();
            setData(jsonData);
            // setData({
            //     login: 236,
            //     uploads: 400,
            //     chats: 60,
            // });
            setIsCustomloading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsCustomloading(false);
        }
    };

    const fetchBuData = async () => {
        try {
            setIsCustomloading(true);
            const formattedStartDateTime = formatStartDate(selectedStartDateTime);
            const formattedEndDateTime = formatEndDate(selectedEndDateTime);
            const response = await fetch(`${RESOURCE_NAME}/maxBlobCount?start=${formattedStartDateTime}&end=${formattedEndDateTime}`);
            const jsonData = await response.json();
            setBuData(jsonData);
            // setData({
            //     login: 236,
            //     uploads: 400,
            //     chats: 60,
            // });
            setIsCustomloading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsCustomloading(false);
        }
    };

    const fetchuserData = async () => {
        try {
            setIsCustomloading(true);
            const formattedStartDateTime = formatStartDate(selectedStartDateTime);
            const formattedEndDateTime = formatEndDate(selectedEndDateTime);
            const response = await fetch(`${RESOURCE_NAME}/maxUsercount?start=${formattedStartDateTime}&end=${formattedEndDateTime}`);
            const jsonData = await response.json();
            setUserData(jsonData);
            // setData({
            //     login: 236,
            //     uploads: 400,
            //     chats: 60,
            // });
            setIsCustomloading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsCustomloading(false);
        }
    };

    const fetchuserDataKM = async () => {
        try {
            setIsCustomloading(true);
            const formattedStartDateTime = formatStartDate(selectedStartDateTime);
            const formattedEndDateTime = formatEndDate(selectedEndDateTime);
            const response = await fetch(`${RESOURCE_NAME}/maxUsercountKM?start=${formattedStartDateTime}&end=${formattedEndDateTime}`);
            const jsonData = await response.json();
            setUserDataKm(jsonData);
            // setData({
            //     login: 236,
            //     uploads: 400,
            //     chats: 60,
            // });
            setIsCustomloading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsCustomloading(false);
        }
    };

    // // Step 1: Determine the complete list of dates within the range
    // const formattedStartDateTime = formatStartDate(selectedStartDateTime);
    // const formattedEndDateTime = formatEndDate(selectedEndDateTime);

    // if (data && data.chatcounts && data.fileuploadcounts && data.logincounts) {
    // Step 1: Determine the complete list of dates within the range
    // Step 1: Determine the complete list of dates within the range
    // const datesInRange = [];
    // for (let date = new Date(formattedStartDateTime); date <= new Date(formattedEndDateTime); date.setDate(date.getDate() + 1)) {
    //     datesInRange.push(date.toISOString().split('T')[0]);
    // }

    //     // Step 2: Initialize object to store counts for each date
    //     const countsByDate:any = {};

    //     // Step 3 & 4: Populate counts for each dataset
    //     [data.chatcounts, data.fileuploadcounts, data.logincounts].forEach(dataset => {
    //         if (dataset) {
    //             dataset.forEach((entry: { date: any; count: any; }) => {
    //                 const dateKey = entry.date;
    //                 if (!countsByDate[dateKey]) {
    //                     countsByDate[dateKey] = { date: dateKey, chats: 0, uploads: 0, logins: 0 };
    //                 }
    //                 countsByDate[dateKey][dataset === data.chatcounts ? 'chats' : dataset === data.fileuploadcounts ? 'uploads' : 'logins'] = entry.count;
    //             });
    //         }
    //     });

    //     // Step 5: Generate chart data
    //     const chartData = datesInRange.map(date => {
    //         const counts = countsByDate[date];
    //         return counts ? [date, counts.chats, counts.uploads, counts.logins] : [date, 0, 0, 0]; // Ensure all dates are included
    //     });

    //     const formattedStartDateTime = new Date(formatStartDate(selectedStartDateTime)); // Convert to Date object
    // const formattedEndDateTime = new Date(formatEndDate(selectedEndDateTime)); // Convert to Date object

    let chartData: any; // Declare chartData variable outside the if block

    // Convert selectedStartDateTime and selectedEndDateTime to Date objects
    // Convert selectedStartDateTime and selectedEndDateTime to Date objects
    const formattedStartDateTime = new Date(formatStartDate(selectedStartDateTime));
    const formattedEndDateTime = new Date(formatEndDate(selectedEndDateTime));

    if (data && data.chatcounts && data.fileuploadcounts && data.logincounts) {
        // Step 1: Determine the complete list of dates within the range
        const datesInRange = [];
        let currentDate = new Date(formattedStartDateTime); // Start from the formatted start date

        // Loop until currentDate is less than or equal to the formatted end date
        while (currentDate <= formattedEndDateTime) {
            datesInRange.push(new Date(currentDate)); // Push a new Date object to avoid referencing the same object
            currentDate.setDate(currentDate.getDate() + 1); /// Increment the current date
        }

        // Step 2: Initialize object to store counts for each date
        const countsByDate: any = {};

        // Step 3 & 4: Populate counts for each dataset
        [data.chatcounts, data.fileuploadcounts, data.logincounts].forEach(dataset => {
            if (dataset) {
                dataset.forEach((entry: { date: any; count: any }) => {
                    const dateKey = entry.date;
                    if (!countsByDate[dateKey]) {
                        countsByDate[dateKey] = { date: dateKey, chats: 0, uploads: 0, logins: 0 };
                    }
                    countsByDate[dateKey][dataset === data.chatcounts ? "chats" : dataset === data.fileuploadcounts ? "uploads" : "logins"] = entry.count;
                });
            }
        });
        console.log(countsByDate);

        // Step 5: Generate chart data
        chartData = datesInRange.map(date => {
            // Extract year, month, and day components
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
            const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed

            // Construct the date string in the desired format (YYYY-MM-DD)
            const dateString = `${year}-${month}-${day}`;

            // Use the extracted date string to access countsByDate
            const counts = countsByDate[dateString];

            // Use the extracted date string as the first element of the resulting array
            return counts ? [dateString, counts.chats, counts.uploads, counts.logins] : [dateString, 0, 0, 0];
        });

        // Rest of your code...
    }

    // Rest of your code here, including the return statement

    const options = {
        title: "Login/ File/ Chat Count by date",
        chartArea: { width: "80%" },
        isStacked: true,

        hAxis: {
            title: "Count"
        },
        vAxis: {
            title: "Date",
            minValue: 0
        },
        backgroundColor: "#fff",
        orientation: "horizontal",
        colors: ["#008585", "#ffa0c5", "#43b0f1"]
    };

    //  const options = {
    //     chart: {
    //       title: "Company Performance",
    //       subtitle: "Sales, Expenses, and Profit: 2014-2017",
    //     },
    //   };
    const buoptions = {
        title: "Max file uploads",
        backgroundColor: "transparent",
        is3D: true,
        legend: false,
        // palette:"Soft"
        colors: ["#a0c4ff", "#ffadad", "#caffbf", "#ffd6a5", "#bdb2ff", "#ffc6ff", "#fdffb6", "#9bf6ff"]
    };
    const useroptions = {
        title: "Top User-Contracts",
        backgroundColor: "transparent",
        is3D: true,
        legend: false,
        colors: ["#a0c4ff", "#ffadad", "#caffbf", "#ffd6a5", "#bdb2ff", "#ffc6ff", "#fdffb6", "#9bf6ff"]
    };
    const userkmoptions = {
        title: "Top User-KM ",
        backgroundColor: "transparent",
        is3D: true,
        legend: false,
        colors: ["#a0c4ff", "#ffadad", "#caffbf", "#ffd6a5", "#bdb2ff", "#ffc6ff", "#fdffb6", "#9bf6ff"]
    };
    const startformattedDate = selectedStartDateTime.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric"
    });
    const endformattedDate = selectedEndDateTime.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric"
    });

    return (
        <div>
            <Layout />

            <section className="main" style={{ padding: "50px", background: "RGB(235,235,240)" }}>
                <img src={loading} className={adstyles.customloading} style={{ display: isCustomloading ? "block" : "none" }} />
                <div className={adstyles.calenderContainer}>
                    <div className={adstyles.dateRangeContainer}>
                        <div style={{ display: "inline-block", textAlign: "left" }}>
                            <h6 className={adstyles.selectRange}>Select range:</h6>
                            <select onChange={handleRangeChange} className="form-select">
                                <option>Last 7 Days</option> <option>This Month</option> <option>Last Month</option> <option>Last 30 days</option>
                                <option>All time</option>
                                <option>Today</option>
                            </select>
                        </div>
                    </div>
                    <span style={{ padding: "0px 20px", fontWeight: "bold" }}>(or)</span>
                    <div  className={adstyles.dateContainer}>
                        <div style={{ display: "inline-block", textAlign: "left" }}>
                            <h6 className={adstyles.selectStartDate}>Select start Date:</h6>
                            <Datepicker
                                selected={selectedStartDateTime}
                                onChange={handleStartDateChange}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select Date and Time"
                            />
                        </div>
                        <div style={{ display: "inline-block"}}>
                            <h6 className={adstyles.selectEndDate}>Select end Date:</h6>
                            <Datepicker
                                selected={selectedEndDateTime}
                                onChange={handleEndDateChange}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select Date and Time"
                            />
                        </div>

                        <div style={{ display: "inline-block"}}>
                            <button onClick={fetchData} className="btn btn-success" style={{ height: "35px", lineHeight: "10px", marginTop: "-5px" }}>
                                submit
                            </button>
                        </div>
                    </div>
                    {/* <div style={{ display: "inline-block", marginLeft: "20px", textAlign: "left" }}>(or)</div> */}
                </div>
                <div className={adstyles.mainskills + " row g-0"}>
                    <div className={adstyles.card + " col-md-3"}>
                        <div style={{ padding: "10px 20px 10px 20px", textAlign: "left" }}>
                            <FontAwesomeIcon icon={faFile} style={{ fontSize: "24px" }} />
                        </div>
                        <div style={{ paddingLeft: "20px", textAlign: "left" }}>Files uploaded</div>
                        <h4>{data.totalUpload ? data.totalUpload : 0}</h4>
                    </div>
                    <div className={adstyles.card + " col-md-3"}>
                        <div style={{ padding: "10px 20px 10px 20px", textAlign: "left" }}>
                            <FontAwesomeIcon icon={faMessage} style={{ fontSize: "24px" }} />
                        </div>
                        <div style={{ paddingLeft: "20px", textAlign: "left" }}>Questions asked</div>
                        <h4>{data.totalChat ? data.totalChat : 0}</h4>
                    </div>
                    <div className={adstyles.card + " col-md-3"}>
                        <div style={{ padding: "10px 20px 10px 20px", textAlign: "left" }}>
                            <FontAwesomeIcon icon={faUserCheck} style={{ fontSize: "24px" }} />
                        </div>
                        <div style={{ paddingLeft: "20px", textAlign: "left" }}>Login Count</div>
                        <h4>{data.totalLogin ? data.totalLogin : 0}</h4>
                    </div>
                </div>

                {data.chatcounts && data.fileuploadcounts && data.logincounts && (
                    <div style={{marginTop:"10px"}}>
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            height="300px"
                            data={[["Date", "Chats", "Uploads", "Login Count"], ...chartData]}
                            options={options}
                        />
                        {/* <Chart
                            chartType="LineChart"
                            width="100%"
                            height="250px"
                            data={[
                                ["Date", "Chats"],
                                ...data.chatcounts.map((entry: any) => [entry.date, entry.count])
                            ]}
                            options={options}
                        />
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="250px"
                            data={[
                                ["Date", "Uploads"],
                                ...data.fileuploadcounts.map((entry: any) => [entry.date, entry.count])
                            ]}
                            options={options}
                        /> */}
                        {/* <Chart
                            chartType="LineChart"
                            width="100%"
                            height="250px"
                            data={[
                                ["Date", "Chats"],
                                ...data.chatcounts.map((entry: any) => [entry.date, entry.count])
                            ]}
                            options={options}
                        /> */}
                    </div>
                )}

                {/* {budata.maxfileCounts && (
    <div className={adstyles.tablech}>
        <h3>Max File Counts - Contracts</h3>
        <table className={`table table-bordered table-dark table-hover  ${adstyles.tablech}`}>
            <thead>
                <tr>
                   
                    <th scope="col">BU</th>
                    <th scope="col">File Upload Count</th>
                </tr>
            </thead>
            <tbody>
                {budata.maxfileCounts.map((item: any, index: any) => (
                    <tr key={index} >
                       
                        <td >{item.bu}</td>
                        <td >{item.fileuploadcount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div style={{marginTop:'-400px',marginLeft:'500px' }} >
        <Chart
                            chartType="PieChart"
                            width={"100%"}
                            height={"400px"}
                            
                            data={[
                                ["BU", "File Upload Count	"],
                                ...budata.maxfileCounts.map((entry: any) => [entry.bu, entry.fileuploadcount])
                            ]}
                            options={buoptions}
                        />
                        </div>
    </div>

    
)} */}
                {budata.maxfileCounts && userdata.maxUserCounts && userdatakm.maxUserCountsKM && (
                    <div className="container-fluid" style={{marginTop:"20px"}}>
                        <div className="row" >
                            <div className={`col-md-4 ${adstyles.chartpane}`}>
                                <h5>File Uploads by BU</h5>
                                <div style={{ fontSize: "12px" }}>
                                    ({startformattedDate.toString()}-{endformattedDate.toString()})
                                </div>
                                <table className={`table table-striped ${adstyles.tablech1}`}>
                                    <thead>
                                        <tr>
                                            <th scope="col">BU</th>
                                            <th scope="col">File Upload Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {budata.maxfileCounts.map((item: any, index: any) => (
                                            <tr key={index}>
                                                <td>{item.bu}</td>
                                                <td>{item.fileuploadcount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div className={adstyles.chartcontainer}>
                                    <Chart
                                        chartType="PieChart"
                                        width={"100%"}
                                        height={"400px"}
                                        data={[["BU", "Upload Count"], ...budata.maxfileCounts.map((entry: any) => [entry.bu, entry.fileuploadcount])]}
                                        options={buoptions}
                                    />
                                </div>
                            </div>

                            <div className={`col-md-4 ${adstyles.chartpane}`}>
                                <h5>Chat/ file count by User - Contracts</h5>
                                <div style={{ fontSize: "12px" }}>
                                    ({startformattedDate.toString()}-{endformattedDate.toString()})
                                </div>
                                <table className={`table table-striped ${adstyles.tablech1}`}>
                                    {" "}
                                    <thead>
                                        <tr>
                                            <th scope="col">User name</th>
                                            <th scope="col">Chat Count</th>
                                            <th scope="col">Upload Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userdata.maxUserCounts.map((item: any, index: any) => (
                                            <tr key={index}>
                                                <td>{item.username}</td>
                                                <td>{item.chatCount}</td>
                                                <td>{item.uploadcount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className={adstyles.chartcontainer}>
                                    <Chart
                                        chartType="PieChart"
                                        width={"100%"}
                                        height={"400px"}
                                        data={[
                                            ["Username", "chatCount", "uploadcount"],
                                            ...userdata.maxUserCounts.map((entry: any) => [entry.username, entry.chatCount, entry.uploadcount])
                                        ]}
                                        options={useroptions}
                                    />
                                </div>
                            </div>

                            <div className={`col-md-4 ${adstyles.chartpane}`}>
                                <h5>Chat count by User - KM</h5>
                                <div style={{ fontSize: "12px" }}>
                                    ({startformattedDate.toString()}-{endformattedDate.toString()})
                                </div>
                                <table className={`table table-striped ${adstyles.tablech1}`}>
                                    <thead>
                                        <tr>
                                            <th scope="col">User name</th>
                                            <th scope="col">Chat Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userdatakm.maxUserCountsKM.map((item: any, index: any) => (
                                            <tr key={index}>
                                                <td>{item.username}</td>
                                                <td>{item.chatCount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className={adstyles.chartcontainer}>
                                    <Chart
                                        chartType="PieChart"
                                        width={"100%"}
                                        height={"400px"}
                                        data={[["Username", "chatCount"], ...userdatakm.maxUserCountsKM.map((entry: any) => [entry.username, entry.chatCount])]}
                                        options={userkmoptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default AdminPage;
