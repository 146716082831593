import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { ICameras, IListOptions, Project, TAction } from "../type";
import style from "./camera.module.css"
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ViewCamera from "./viewCamera";
import DropdownWithCheckboxes from "../../../design system/DropdownCheckbox";
import { CAMERA_API_URL, CAMERA_LIST_API_URL, CAMERA_ALGO_MAPPING_API_URL, ACCESS_API_URL, PERMISSIONS_API_URL, ALL_PROJECTS_API_URL, getActiveUser, getUserRole, PROJECT_TO_CAMERA_API_URL } from "../vision.constant";


export const Camera = () => {
    const [cameras, setCameras] = useState<ICameras[]>([]);
    const [editingCamera, setEditingCamera] = useState<ICameras | null>(null);
    const [deleteCId, setDeleteUserCId] = useState<any>(null);
    const [camera, setCamera] = useState<ICameras>({ camera_name: '', camera_rtsp_url: "", projects: [] });
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [nameError, setNameError] = useState<string>('');
    const [crUrlError, setCrUrlError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState("");
    const [showCameraModal, setShowCameraModal] = useState(false)
    const [cameraModalTitle, setCameraModalTitle] = useState("");
    const [selectedAlgo, setSelectedAlgo] = useState([]);
    const [siteOptions, setSiteOptions] = useState<IListOptions[]>([]);
    const [siteError, setSiteError] = useState<string>('');
    const [selectedCameraId, setSelectedCameraId] = useState<any>(null);
    const [viewCameraUrl, setViewCameraUrl] = useState<any>(null);
    const [defaultSelected, setDefaultSelected] = useState<any>([]);

    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        { id: 'camera_id', label: 'CId' },
        { id: 'camera_name', label: 'Name' },
        { id: 'camera_rtsp_url', label: 'CCTV RSTP Url' },
        { id: 'projects', label: 'Site', group: true, type: 'object' },
        { id: 'view_action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                console.log('Yes:', { deleteCId, editingCamera, camera });
                if (deleteCId) {
                    deleteCamera()
                } else if (editingCamera || camera) {
                    saveCamera()
                }
            }, variant: 'contained', color: 'success'
        },
    ];
    const cameraModalAction: TAction[] = [
        {
            label: 'Close', onClick: () => {
                setShowCameraModal(false);
                setSelectedAlgo([]);
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Save', onClick: () => {
                setShowCameraModal(false);
                saveCameraAlgo()
            }, variant: 'contained', color: 'success'
        }
    ];
    useEffect(() => {
        fetchCamera();
        fetchProjectList();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);

    const fetchCamera = async () => {
        try {
            setLoader(true)
            const data: any = await api.get<any>(CAMERA_LIST_API_URL);
            if (getUserRole() === "super_admin") {
                setCameras(data?.response);
            } else if (getUserRole() === "org_admin") {
                const project_ids = await fetchAccess(getUserRole());
                const updatedList = getCamerasByProjectIds(data?.response, project_ids);
                setCameras(updatedList);
            } else if (getUserRole() === "site_admin") {
                const project_ids = await fetchAccess(getUserRole());
                const updatedList = getCamerasByProjectIds(data?.response, project_ids);
                console.log({ project_ids, updatedList }, 'check');
                setCameras(updatedList);
            }

        } catch (error) {
            showToast("Error fetching camera list", "error");
            console.error('Error fetching users:', error);
        } finally {
            setLoader(false);
        }
    };
    const fetchAccess = async (role: string) => {
        try {
            const data: any = await api.post<any>(`${ACCESS_API_URL}`, { email: getActiveUser() });
            const resp = data?.response;
            if (role === "org_admin") {
                const org_ids = getOrgIds(resp);
                const getUnique_org_Ids = Array.from(new Set(org_ids));
                console.log(getUnique_org_Ids, 'getUnique_org_Ids');

                const get_details = await fetchPermissionList();
                console.log(get_details, 'get_details');

                const get_project_ids = getProjectsByOrgIds(get_details, getUnique_org_Ids)


                console.log(get_project_ids, 'get_project_ids');

                return get_project_ids
            } else {
                return resp?.project_admin?.map((project: any) => project?.project_id) || []
            }


        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users:', error);
            return []
        } 
    };
    function getOrgIds(data: any) {
        if (data && Array.isArray(data.org_admin)) {
            return data.org_admin.map((admin: any) => admin.org_id);
        }
        return [];
    }
    const getProjectsByOrgIds = (data: any, orgIds: any) => {
        let matchedProjects: any = [];
        if (data && Array.isArray(data) && data.length > 0) {
            const orgData = data[0];
            orgData.forEach((org: any) => {
                if (orgIds.includes(org.org_id)) {
                    matchedProjects = matchedProjects.concat(org.projects);
                }
            });
        }

        return matchedProjects;
    }
    const getCamerasByProjectIds = (data: any, projectIds: any) => {
        let matchedCameras: any = [];
        if (data && Array.isArray(data)) {
            data.forEach((camera: any) => {
                const hasMatchingProject = camera.projects.some((project: any) => projectIds.includes(project.id));
                if (hasMatchingProject) {
                    matchedCameras.push(camera);
                }
            });
        }

        return matchedCameras;
    }

    const fetchPermissionList = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any>(PERMISSIONS_API_URL);
            return data?.response

        } catch (error) {
            showToast("Error fetching org list", "error");
            return []
        } finally {
            setLoader(false);
        }
    };
    const fetchProjectList = async () => {
        setLoader(true);
        try {
            const data: any = await api.get<any>(ALL_PROJECTS_API_URL);
            if (getUserRole() === "super_admin") {
                const convertedOption = data?.response.map((project: any) => ({
                    id: project?.project_id,
                    label: project?.project_name
                }))
                setSiteOptions(convertedOption)
            } else if (getUserRole() === "org_admin") {
                const project_ids = await fetchAccess(getUserRole());
                const filteredProjects = filterProjectsByIds(data?.response, project_ids);
                const convertedOption = filteredProjects?.map((project: any) => ({
                    id: project?.project_id,
                    label: project?.project_name
                }))
                setSiteOptions(convertedOption)
            } else if (getUserRole() === "site_admin") {
                const project_ids = await fetchAccess(getUserRole());
                const filteredProjects = filterProjectsByIds(data?.response, project_ids);
                const convertedOption = filteredProjects?.map((project: any) => ({
                    id: project?.project_id,
                    label: project?.project_name
                }))
                setSiteOptions(convertedOption)
            }


        } catch (error) {
            showToast("Error fetching org list", "error");
        } finally {
            setLoader(false);
        }
    };
    const filterProjectsByIds = (data: any, projectIds: any) => {
        return data.filter((project: any) => projectIds.includes(project.project_id));
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCamera({
            ...camera,
            [name]: value
        });
        if (name === 'camera_name') {
            setNameError('');
        } else if (name === 'camera_rtsp_url') {
            setCrUrlError('');
        }


    };


    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true)
        setModalTitle(`Are you wanted to ${editingCamera ? 'update' : 'add'} this camera?`)
    };
    const validateInputs = () => {
        let isValid = true;
        if (!camera?.camera_name?.trim() || !camera?.camera_name) {
            setNameError('Name is required');
            isValid = false;
        }
        if (!camera?.camera_rtsp_url.trim() || !camera?.camera_rtsp_url) {
            setCrUrlError('Url is required');
            isValid = false;
        }
        if (camera?.projects?.length === 0 || !camera?.projects) {
            setSiteError("Please Select Site")
            isValid = false;
        }
        return isValid;
    };
    const saveCamera = async () => {
        console.log(camera, "save Camera**********");

        try {
            setLoader(true);
            if (editingCamera) {
                const payload = {
                    ...camera,
                    updated_by: getActiveUser()
                }
                await api.put<ICameras>(`${CAMERA_API_URL}/${editingCamera?.camera_id}`, payload);
                const project_id = camera.projects[0].id;

                const p_c_payload = {
                    project_id: project_id,
                    camera_list: [camera?.camera_id],
                    updated_by: getActiveUser()
                }

                const pcResp: any = await api.put<Project>(`${PROJECT_TO_CAMERA_API_URL}/${project_id}`, p_c_payload);
            } else {
                const payload = {
                    ...camera,
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                }
                const resp: any = await api.post<ICameras>(CAMERA_API_URL, payload);
                if (resp?.response) {
                    const p_c_payload = {
                        project_id: camera?.projects[0].id,
                        camera_list: [resp?.response[0].camera_id],
                        updated_by: getActiveUser(),
                        created_by: getActiveUser(),
                    }
                    const pcResp: any = await api.post<Project>(PROJECT_TO_CAMERA_API_URL, p_c_payload);
                }
            }
            setEditingCamera(null);
            setDeleteUserCId(null)
            updateBreadcrumbList(true, "")
            await fetchCamera();
            clearInputs();
            setSearchQuery("");
            showToast(`Camera ${editingCamera ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`Camera ${editingCamera ? 'updating' : 'adding'} failed`, 'error');
            console.error('Error adding user:', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const handleCameraAlgo = (selectedAlgo: any) => {
        console.log(selectedAlgo, 'selectedAlgo');
        setSelectedAlgo(selectedAlgo)
    }
    const saveCameraAlgo = async () => {
        try {
            setLoader(true);
            const payload = {
                camera_id: selectedCameraId,
                algorithm_id: selectedAlgo,
                created_by: getActiveUser(),
                updated_by: getActiveUser()
            };
            console.log(payload, 'payload*************');
            console.log(selectedAlgo, 'handleCameraAlgo');
            await api.post<ICameras>(CAMERA_ALGO_MAPPING_API_URL, payload);
            console.log();
            setSelectedAlgo([]);
            showToast(`Algorithm updated  successfully`, 'success');
        } catch (error) {
            showToast(`Algorithm updated failed`, 'error');
            console.error('Error adding user:', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = (camera: ICameras) => {
        console.log(camera, "camera************");
        clearInputs();
        setEditingCamera(camera);
        setCamera(camera);
        scrollToTop();
        setShowAddIcon(false);
        setDeleteUserCId(null);
        updateBreadcrumbList(false, "Edit Camera")
    };
    const handleDelete = (camera: ICameras) => {
        console.log('delete camera', camera);
        setShowModal(true)
        setEditingCamera(null);
        setDeleteUserCId(camera?.camera_id);
        setModalTitle("Are you wanted to delete this Camera?")
    }
    const deleteCamera = async () => {
        try {
            setLoader(true);
            await api.remove<ICameras>(`${CAMERA_API_URL}/${deleteCId}`);
            fetchCamera();
            clearInputs();
            showToast('Camera deleted successfully', 'success');
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast('Error deleting Camera', 'error');
            console.error('Error deleting user:', error);
        } finally {
            setLoader(false);
        }
    };
    const updateBreadcrumbList = (rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    }
    const clearInputs = () => {
        setCamera({ camera_name: '', camera_rtsp_url: '', projects: [] });
        setNameError('');
        setCrUrlError('');
        setSiteError('');
    };
    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditingCamera(null);
        setSearchQuery("");
        updateBreadcrumbList(true, "")
    };
    const handleAdd = () => {
        setDeleteUserCId(null)
        setShowAddIcon(false);
        setEditingCamera(null);
        setSearchQuery("");
        updateBreadcrumbList(false, "Add Camera")
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };
    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    }
    const handleClose = () => {
        setShowModal(false);
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const filteredCameraList = useMemo(() => {
        return filterByTableSearch(cameras, searchQuery);
    }, [cameras, searchQuery]);

    const handleView = async (camera: any) => {
        console.log("handle view called", camera);
        setSelectedCameraId(camera?.camera_id);
        setViewCameraUrl(camera?.camera_rtsp_url)
        setCameraModalTitle("View Camera")
        try {
            setLoader(true);
            const getAlogoList: any = await api.get<ICameras>(`${CAMERA_ALGO_MAPPING_API_URL}/${camera?.camera_id}`);
            setDefaultSelected(getAlogoList?.response?.algorithm_ids)
            setShowCameraModal(true)
            console.log(getAlogoList, 'getAlogoList****');
        } catch (error) {
            console.log(error, 'errror');
            setShowCameraModal(true)
        } finally {
            setLoader(false);
        }

    }
    const cameraModalClose = () => {
        console.log("handle view called");
        setShowCameraModal(false)
        setSelectedAlgo([])
    }

    const handleDropdownChange = (value: any[], name: string) => {
        console.log(value, "value");
        setCamera((prev: any) => ({
            ...prev,
            [name]: value
        }));
        setSiteError("")
    };

    return (
        <>
            <div className={style.camera__container}>
                <strong>Camera</strong>
                <div className={style.camera__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editingCamera ? 'Update' : "Save"}>
                                <img width={24} height={20} className={`${style.camera__curserPointer} ${style.camera__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                            { }
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.camera__inputWrapper}>
                    <Grid item xs={4} >
                        <Input
                            id="username-input"
                            heading="Camera Name"
                            placeholder="Enter Name"
                            variant="standard"
                            name="camera_name"
                            value={camera?.camera_name}
                            helperText={nameError}
                            required
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            id="crUrl-input"
                            name="camera_rtsp_url"
                            heading="CCTV RSTP Url"
                            required
                            placeholder="Enter Url"
                            variant="standard"
                            value={camera?.camera_rtsp_url}
                            helperText={crUrlError}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DropdownWithCheckboxes
                            multiple={false}
                            id="projects"
                            name="projects"
                            label="Site"
                            options={siteOptions as any}
                            helperText={siteError}
                            value={camera?.projects as any}
                            placeholder="Select Sites"
                            onChange={(value: any) => handleDropdownChange(value, "projects")}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredCameraList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredCameraList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                handleView={handleView}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
            <CustomDialog
                open={showCameraModal}
                onClose={cameraModalClose}
                title={cameraModalTitle}
                className="camera-view"
                customAction={cameraModalAction}
                content={
                    <ViewCamera
                        rtspUrl={viewCameraUrl}
                        apiUrl="wss://coe-computervision.azure-api.net/cv2?subscription-key=1eb96e1508c146f0b1f8b2135c148fd7&CID=1723463339030"
                        appID={"101"} sID={"10"}
                        handleSave={handleCameraAlgo}
                        defaultSelected={defaultSelected}
                    />}
            />
        </>
    );
};
export default Camera; 