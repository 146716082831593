import { useParams } from "react-router-dom";

import Layout from "../../pages/layout/Layout";
import React, { useEffect, useState, useContext, useRef, ChangeEvent } from "react";
import styles from "../FIN/fin.module.css";
import loading from "../../assets/loading.gif";
import imageicon from "../HR/download.jpg";
import axios from "axios";
import { AnyARecord } from "dns";
import { BACKEND_URI } from "../../constants";
import finimg1 from "../../assets/copper_graphs/ActualvsPredictedcopper.png";
import  finimg2 from "../../assets/copper_graphs/dailycopperpricesfrom2010.png";
import finimg3 from "../../assets/copper_graphs/nextdaycopperpred.png";
import finimg4 from "../../assets/copper_graphs/nextweekcopperpred.png";

export default function FINPage() {
    const [jsonParams, setJsonParams] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [base64Image, setBase64Image] = useState<string>("");
    const [isCustomloading, setIsCustomloading] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setJsonParams(value);
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsCustomloading(true);
        try {
            const apiEndpoint = `${BACKEND_URI}/hrimage`;
            const requestBody = {
                input: jsonParams
            };
            const response = await fetch(apiEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                    // Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(requestBody)
            });
            setIsCustomloading(false);
            if (!response.ok) {
                setIsCustomloading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const imageData = await response.text();
            const imageUrl = `data:image/png;base64,${imageData}`;

            console.log(imageUrl);

            setIsCustomloading(false);
            setImageUrl(imageUrl);
        } catch (error) {
            setIsCustomloading(false);
            console.error("Error fetching image:", error);
        }
    };

    const imgContent: any = [
        {
            imgSrc: finimg1,
            imgid:1,
            altext:"",
            imgText:"Actual vs Predicted Copper"
           
        },
        {
            imgSrc: finimg2,
            imgid:1,
            altext:"",
            imgText:"Dailycopper Prices from 2010"
           
        },
        {
            imgSrc: finimg3,
            imgid:1,
            altext:"",
            imgText:"Nextday Copper Prediction"
           
        },
        {
            imgSrc: finimg4,
            imgid:1,
            altext:"",
            imgText:"Nextweek Copper Prediction"
           
        },
       
    
    ]
    return (
        <>
            <div className="container-fluid">
                <Layout />
                <img src={loading} className={styles.customloading} style={{ display: isCustomloading ? "block" : "none" }} />

<div className="row" style={{background:"#f9f5f5"}}>
    <div className={styles.hrTitle}><center>Commodity Forecasting</center></div>
{imgContent.map((image:any,index:any) => (
                      <div  key={index}className={`col-lg-12 col-xl-12 col-md-12 col-sm- col-xs-12`}>
                    <div className={`p-3 mb-5 bg-white rounded ${styles.imgContainer}`}>
                    <h2 className={styles.imgtxt}>{image.imgText}</h2>
                    <div className={styles.generatedimage}>
                            {/* <h2>Generated Graphs:</h2> */}
                            <img src={image.imgSrc} alt="Generated Image" style={{ maxWidth: "100%" }} onError={e => console.log("Error loading image:", e)} />
                        </div>
                     
                        </div>
                        

                  </div>
                  
                )
                
                )
                
                }
                  <div className="row mt-4">
      <div className="col text-right">
        <p>
          Source: <a style={{color:"blue",textDecorationLine:"underline"}}href="https://finance.yahoo.com/quote/HG%3DF/history/">https://finance.yahoo.com/quote/HG%3DF/history/</a>
        </p>
      </div>
    </div>
    </div>
  
            </div>
        </>
    );
}
