// @flow 
import * as React from 'react';
import loading from "../../../assets/loading.gif";
import "./loader.css"
type Props = {
    isLoading: boolean
};
export const LoaderComponent = ({ isLoading }: Props) => {
    return (
        <>
            <img src={loading} className="customloading" alt='loader-img' style={{ display: isLoading ? "block" : "none" }} />
        </>
    );
};