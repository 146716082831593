import React, { createContext, useState, useCallback, useContext } from 'react';
import CustomToast from '../design system/Toast';
import { UpdateLogout } from '../api/api';
import { useMsal } from '@azure/msal-react';
import { BACKEND_URI } from '../constants';

export interface ErrorHandlingContextType {
  showToastMessage: (message: string, severity: 'error' | 'warning' | 'info' | 'success') => void;
  handleLogout: () => void;
  openErrorToast?: boolean;
  toastErrorMessage?: string;
  toastErrorSeverity?: 'error' | 'warning' | 'info' | 'success';
}

export const ErrorHandlingContext = createContext<ErrorHandlingContextType | null>(null)

export const ErrorHandlingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState('');
  const [toastErrorSeverity, setToastErrorSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('info');
  let activeAccount: any;
  const { instance } = useMsal();
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const showToastMessage = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setToastErrorMessage(message);
    setToastErrorSeverity(severity);
    setOpenErrorToast(true);
  };

  const reDirectToLogin = async () => {
    let accessToken: any = sessionStorage.getItem("accessToken");
    setTimeout(async () => {
      try {
        await UpdateLogout(accessToken);
        await instance.logoutRedirect({
          account: activeAccount,
          postLogoutRedirectUri: BACKEND_URI
        });
      } catch (error) {
        console.log(error);
      }
    }, 2000);
  }
  const handleLogout = () => {
    reDirectToLogin();
  };

  const handleCloseToast = () => {
    setOpenErrorToast(false);
  };
  return (
    <ErrorHandlingContext.Provider value={{ showToastMessage, handleLogout, openErrorToast, toastErrorMessage, toastErrorSeverity }}>
      {children}
      <CustomToast open={openErrorToast} message={toastErrorMessage} severity={toastErrorSeverity} onClose={handleCloseToast} />
    </ErrorHandlingContext.Provider>
  );
};

export const useErrorHandling = () => {
  const context = useContext(ErrorHandlingContext);
  if (!context) {
    throw new Error('useErrorHandling must be used within an ErrorHandlingProvider');
  }
  return context;
};
